// Libraries
import React from 'react';
import { motion } from 'motion/react';

interface BottomBarProps {
  buttons: React.ReactNode[];
  fixedDivWidth?: string;
  additionalStyles?: string;
}

export const BottomBar: React.FC<BottomBarProps> = ({
  buttons,
  fixedDivWidth,
  additionalStyles = '',
}) => {
  return (
    <motion.div
      layoutRoot
      initial={{ bottom: '-100vh' }}
      animate={{ bottom: 0 }}
      exit={{ bottom: '-100vh' }}
      transition={{ duration: 0.3, ease: 'linear' }}
      style={{ width: fixedDivWidth ? fixedDivWidth : '100%' }}
      className={`fixed bottom-0 flex gap-3 justify-end p-2 bg-grey-99 border border-grey-90 ${additionalStyles}`}
    >
      {buttons.map((button, index) => {
        return <div key={`button-${index}`}>{button}</div>;
      })}
    </motion.div>
  );
};

export default BottomBar;
