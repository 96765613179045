import React, { useState } from 'react';
import { AlertContext } from './alert-context';
import { AlertProps } from 'design-system/alert.component';

interface AlertProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const AlertContextProvider: React.FC<AlertProviderProps> = ({
  children,
}) => {
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const showAlert = (alert: AlertProps) => setAlert(alert);
  const hideAlert = () => setAlert(null);

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
