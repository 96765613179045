// Utils
import { IApiData, WorksheetAttributes } from 'api';
import { FeedbackType } from 'features/home/customer-portal/types';

export const extractApprovals = (worksheet: IApiData<WorksheetAttributes>) => {
  return {
    purchasingApproval:
      worksheet.parsedIncluded?.feedbacks?.find(
        (feedback: any) =>
          feedback.attributes.feedbackType === FeedbackType.purchasing
      ) || null,
    operationsApproval:
      worksheet.parsedIncluded?.feedbacks?.find(
        (feedback: any) =>
          feedback.attributes.feedbackType === FeedbackType.operations
      ) || null,
    managementApproval:
      worksheet.parsedIncluded?.feedbacks?.find(
        (feedback: any) =>
          feedback.attributes.feedbackType === FeedbackType.management
      ) || null,
  };
};
