// Utils
import { WorksheetFormValues } from './worksheet.types';
import { WORKSHEET_FORM_KEYS } from './constants';
import { QuoteCalculator } from '../quote-calculator/quote-calculator.component';
// Constants
import { UNITS } from 'features/types';

const {
  ADDITIONAL_COMPONENT_ONE_A,
  ADDITIONAL_COMPONENT_ONE_B,
  ADDITIONAL_COMPONENT_ONE_C,
  ADDITIONAL_COMPONENT_THREE_A,
  ADDITIONAL_COMPONENT_THREE_B,
  ADDITIONAL_COMPONENT_THREE_C,
  ADDITIONAL_COMPONENT_TWO_A,
  ADDITIONAL_COMPONENT_TWO_B,
  ADDITIONAL_COMPONENT_TWO_C,
  BATCHING_HOURS_ONE,
  BATCHING_HOURS_THREE,
  BATCHING_HOURS_TWO,
  COMPOUNDING_HEAD_COUNT_ONE,
  COMPOUNDING_HEAD_COUNT_THREE,
  COMPOUNDING_HEAD_COUNT_TWO,
  COMPOUNDING_PAY_RATE_ONE,
  COMPOUNDING_PAY_RATE_THREE,
  COMPOUNDING_PAY_RATE_TWO,
  FILLING_HEAD_COUNT_ONE,
  FILLING_HEAD_COUNT_THREE,
  FILLING_HEAD_COUNT_TWO,
  FILLING_PAY_RATE_ONE,
  FILLING_PAY_RATE_THREE,
  FILLING_PAY_RATE_TWO,
  INGREDIENTS,
  MASTER_BOX_ONE,
  MASTER_BOX_THREE,
  MASTER_BOX_TWO,
  NUMBER_OF_BATCHES_ONE,
  NUMBER_OF_BATCHES_THREE,
  NUMBER_OF_BATCHES_TWO,
  ORDER_QUANTITY_ONE,
  ORDER_QUANTITY_THREE,
  ORDER_QUANTITY_TWO,
  PROFIT_ONE,
  PROFIT_THREE,
  PROFIT_TWO,
  RUN_RATE_ONE,
  RUN_RATE_THREE,
  RUN_RATE_TWO,
  SIZE_ONE_CUSTOMER_CPU_ONE,
  SIZE_ONE_CUSTOMER_CPU_THREE,
  SIZE_ONE_CUSTOMER_CPU_TWO,
  SIZE_ONE_QUANTITY,
  SIZE_THREE_CUSTOMER_CPU_ONE,
  SIZE_THREE_CUSTOMER_CPU_THREE,
  SIZE_THREE_CUSTOMER_CPU_TWO,
  SIZE_THREE_QUANTITY,
  SIZE_TWO_CUSTOMER_CPU_ONE,
  SIZE_TWO_CUSTOMER_CPU_THREE,
  SIZE_TWO_CUSTOMER_CPU_TWO,
  SIZE_TWO_QUANTITY,
  TANK_SIZE,
} = WORKSHEET_FORM_KEYS;

export const calculateAndSetCustomerCPUsAcrossAllSizes = (
  values: WorksheetFormValues
) => {
  return [
    {
      sizeKey: SIZE_ONE_QUANTITY,
      customerCpuOneKey: SIZE_ONE_CUSTOMER_CPU_ONE,
      customerCpuTwoKey: SIZE_ONE_CUSTOMER_CPU_TWO,
      customerCpuThreeKey: SIZE_ONE_CUSTOMER_CPU_THREE,
    },
    {
      sizeKey: SIZE_TWO_QUANTITY,
      customerCpuOneKey: SIZE_TWO_CUSTOMER_CPU_ONE,
      customerCpuTwoKey: SIZE_TWO_CUSTOMER_CPU_TWO,
      customerCpuThreeKey: SIZE_TWO_CUSTOMER_CPU_THREE,
    },
    {
      sizeKey: SIZE_THREE_QUANTITY,
      customerCpuOneKey: SIZE_THREE_CUSTOMER_CPU_ONE,
      customerCpuTwoKey: SIZE_THREE_CUSTOMER_CPU_TWO,
      customerCpuThreeKey: SIZE_THREE_CUSTOMER_CPU_THREE,
    },
  ].map(
    ({
      sizeKey,
      customerCpuOneKey,
      customerCpuTwoKey,
      customerCpuThreeKey,
    }) => {
      const size = values[sizeKey];
      // If there is no size, set all customer CPUs to null
      if (!size) {
        return {
          [customerCpuOneKey]: null,
          [customerCpuTwoKey]: null,
          [customerCpuThreeKey]: null,
        };
      }

      const customerCPUs = [
        {
          quantityKey: ORDER_QUANTITY_ONE,
          profitKey: PROFIT_ONE,
          batchingHoursKey: BATCHING_HOURS_ONE,
          fillingHeadCountKey: FILLING_HEAD_COUNT_ONE,
          compoundingHeadCountKey: COMPOUNDING_HEAD_COUNT_ONE,
          runRateKey: RUN_RATE_ONE,
          fillingPayRateKey: FILLING_PAY_RATE_ONE,
          numberOfBatchesKey: NUMBER_OF_BATCHES_ONE,
          compoundingPayRateKey: COMPOUNDING_PAY_RATE_ONE,
          masterBoxKey: MASTER_BOX_ONE,
          additionalComponentAKey: ADDITIONAL_COMPONENT_ONE_A,
          additionalComponentBKey: ADDITIONAL_COMPONENT_ONE_B,
          additionalComponentCKey: ADDITIONAL_COMPONENT_ONE_C,
        },
        {
          quantityKey: ORDER_QUANTITY_TWO,
          profitKey: PROFIT_TWO,
          batchingHoursKey: BATCHING_HOURS_TWO,
          fillingHeadCountKey: FILLING_HEAD_COUNT_TWO,
          compoundingHeadCountKey: COMPOUNDING_HEAD_COUNT_TWO,
          runRateKey: RUN_RATE_TWO,
          fillingPayRateKey: FILLING_PAY_RATE_TWO,
          numberOfBatchesKey: NUMBER_OF_BATCHES_TWO,
          compoundingPayRateKey: COMPOUNDING_PAY_RATE_TWO,
          masterBoxKey: MASTER_BOX_TWO,
          additionalComponentAKey: ADDITIONAL_COMPONENT_TWO_A,
          additionalComponentBKey: ADDITIONAL_COMPONENT_TWO_B,
          additionalComponentCKey: ADDITIONAL_COMPONENT_TWO_C,
        },
        {
          quantityKey: ORDER_QUANTITY_THREE,
          profitKey: PROFIT_THREE,
          batchingHoursKey: BATCHING_HOURS_THREE,
          fillingHeadCountKey: FILLING_HEAD_COUNT_THREE,
          compoundingHeadCountKey: COMPOUNDING_HEAD_COUNT_THREE,
          runRateKey: RUN_RATE_THREE,
          fillingPayRateKey: FILLING_PAY_RATE_THREE,
          numberOfBatchesKey: NUMBER_OF_BATCHES_THREE,
          compoundingPayRateKey: COMPOUNDING_PAY_RATE_THREE,
          masterBoxKey: MASTER_BOX_THREE,
          additionalComponentAKey: ADDITIONAL_COMPONENT_THREE_A,
          additionalComponentBKey: ADDITIONAL_COMPONENT_THREE_B,
          additionalComponentCKey: ADDITIONAL_COMPONENT_THREE_C,
        },
      ].map(
        ({
          quantityKey,
          profitKey,
          batchingHoursKey,
          fillingHeadCountKey,
          compoundingHeadCountKey,
          runRateKey,
          fillingPayRateKey,
          numberOfBatchesKey,
          compoundingPayRateKey,
          masterBoxKey,
          additionalComponentAKey,
          additionalComponentBKey,
          additionalComponentCKey,
        }) => {
          const orderQuantity = values[quantityKey];

          if (!orderQuantity) return undefined;

          const quoteCalculator = new QuoteCalculator({
            orderQuantity,
            profit: values[profitKey],
            size: size,
            unit: UNITS.oz,
            ingredients: values[INGREDIENTS],
          });

          quoteCalculator.calculateFormulaCost();

          quoteCalculator.calculateLaborCost({
            batchingHours: values[batchingHoursKey],
            fillingHeadCount: values[fillingHeadCountKey],
            compoundingHeadCount: values[compoundingHeadCountKey],
            runRatePerMinute: values[runRateKey],
            fillingPayRate: values[fillingPayRateKey],
            numberOfBatches: values[numberOfBatchesKey],
            compoundingPayRate: values[compoundingPayRateKey],
            tankSize: values[TANK_SIZE],
          });

          quoteCalculator.calculateComponentsCost({
            masterBox: values[masterBoxKey],
            additionalComponentOne: values[additionalComponentAKey],
            additionalComponentTwo: values[additionalComponentBKey],
            additionalComponentThree: values[additionalComponentCKey],
          });

          const { totalCost } = quoteCalculator.calculateMargin();

          const cpu = totalCost + values[profitKey];

          return cpu;
        }
      );

      const [cpuOne, cpuTwo, cpuThree] = customerCPUs;

      return {
        // Round to 2 decimal places
        [customerCpuOneKey]: cpuOne ? parseFloat(cpuOne.toFixed(2)) : null,
        [customerCpuTwoKey]: cpuTwo ? parseFloat(cpuTwo.toFixed(2)) : null,
        [customerCpuThreeKey]: cpuThree
          ? parseFloat(cpuThree.toFixed(2))
          : null,
      };
    }
  );
};

export const highlightInput = (e: React.FocusEvent<HTMLInputElement>) =>
  e.target.select();
