import {
  FormulaAttributes,
  IApiData,
  WorksheetAttributes,
  WorksheetIngredientAttributes,
} from 'api';

export interface WorksheetFormValues {
  additionalComponentOneA: Maybe<number>;
  additionalComponentOneB: Maybe<number>;
  additionalComponentOneC: Maybe<number>;
  additionalComponentThreeA: Maybe<number>;
  additionalComponentThreeB: Maybe<number>;
  additionalComponentThreeC: Maybe<number>;
  additionalComponentTwoA: Maybe<number>;
  additionalComponentTwoB: Maybe<number>;
  additionalComponentTwoC: Maybe<number>;
  batchingHoursOne: Maybe<number>;
  batchingHoursThree: Maybe<number>;
  batchingHoursTwo: Maybe<number>;
  compoundingHeadCountOne: Maybe<number>;
  compoundingHeadCountThree: Maybe<number>;
  compoundingHeadCountTwo: Maybe<number>;
  compoundingPayRateOne: Maybe<number>;
  compoundingPayRateThree: Maybe<number>;
  compoundingPayRateTwo: Maybe<number>;
  efficiencyPercentageOne: Maybe<number>;
  efficiencyPercentageThree: Maybe<number>;
  efficiencyPercentageTwo: Maybe<number>;
  fillingHeadCountOne: Maybe<number>;
  fillingHeadCountThree: Maybe<number>;
  fillingHeadCountTwo: Maybe<number>;
  fillingPayRateOne: Maybe<number>;
  fillingPayRateThree: Maybe<number>;
  fillingPayRateTwo: Maybe<number>;
  fillSizeOne: Maybe<number>;
  fillSizeThree: Maybe<number>;
  fillSizeTwo: Maybe<number>;
  masterBoxOne: Maybe<number>;
  masterBoxThree: Maybe<number>;
  masterBoxTwo: Maybe<number>;
  numberOfBatchesOne: Maybe<number>;
  numberOfBatchesThree: Maybe<number>;
  numberOfBatchesTwo: Maybe<number>;
  orderQuantityOne: Maybe<number>;
  orderQuantityThree: Maybe<number>;
  orderQuantityTwo: Maybe<number>;
  profitOne: Maybe<number>;
  profitThree: Maybe<number>;
  profitTwo: Maybe<number>;
  runRateOne: Maybe<number>;
  runRateThree: Maybe<number>;
  runRateTwo: Maybe<number>;
  sizeOneQuantity: Maybe<number>;
  sizeOneUnit: Maybe<string>;
  sizeThreeQuantity: Maybe<number>;
  sizeThreeUnit: Maybe<string>;
  sizeTwoQuantity: Maybe<number>;
  sizeTwoUnit: Maybe<string>;
  tankSize: Maybe<number>;
  notes: Maybe<string>;
  ingredients: Array<IApiData<WorksheetIngredientAttributes>>;
  [key: string]: any;
}

export type WorksheetUpdateResponse = IApiData<WorksheetAttributes> & {
  formula: IApiData<FormulaAttributes>;
  worksheetIngredients: IApiData<WorksheetIngredientAttributes>[];
};

export enum SIZES {
  ONE = 0,
  TWO = 1,
  THREE = 2,
}
