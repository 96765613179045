// Libraries
import React from 'react';
import { FullscreenOverlay } from 'design-system';
// Components
import { WorksheetForm } from './worksheet-form.component';
import { Worksheet } from './worksheet.component';
// Utils
import {
  FeedbackAttributes,
  FormulaAttributes,
  IApiData,
  ProjectAttributes,
  WorksheetApiResponse,
} from 'api';
import { WorksheetUpdateResponse } from './worksheet.types';

interface WorksheetModalProps {
  worksheet: Maybe<WorksheetApiResponse>;
  updateWorksheet: (worksheet: WorksheetUpdateResponse) => void;
  closeWorksheet: () => void;
  handleApprovalClick: (approval: IApiData<FeedbackAttributes>) => void;
  isOpen: boolean;
  isPriceQuoteEditable: boolean;
  project: Maybe<IApiData<ProjectAttributes>>;
  formula: IApiData<FormulaAttributes>;
  setShowApprovalAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showApprovalAlert: boolean;
}

const WorksheetModal: React.FC<WorksheetModalProps> = ({
  worksheet,
  updateWorksheet,
  closeWorksheet,
  handleApprovalClick,
  isOpen,
  isPriceQuoteEditable,
  project,
  formula,
  setShowApprovalAlert,
  showApprovalAlert,
}) => {
  return (
    <FullscreenOverlay isOpen={isOpen} onClose={closeWorksheet}>
      {worksheet ? (
        <WorksheetForm worksheet={worksheet} updateWorksheet={updateWorksheet}>
          {(initialValues) => (
            <Worksheet
              worksheet={worksheet}
              handleApprovalClick={handleApprovalClick}
              initialValues={initialValues}
              isPriceQuoteEditable={isPriceQuoteEditable}
              project={project}
              formula={formula}
              closeWorksheet={closeWorksheet}
              setShowApprovalAlert={setShowApprovalAlert}
              showApprovalAlert={showApprovalAlert}
            />
          )}
        </WorksheetForm>
      ) : null}
    </FullscreenOverlay>
  );
};

export default WorksheetModal;
