// Libraries
import React from 'react';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/16/solid';
import { FieldArray, useFormikContext } from 'formik';
// Components
import { Button, TextField, Select, Typography } from 'design-system';
// Utils
import { InternalBriefFormValues } from './form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const SampleRecipients = () => {
  const {
    getFieldProps,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormikContext<InternalBriefFormValues>();
  const sampleRecipients = values.sampleRecipients;

  const renderSampleRecipientFields = () => {
    return sampleRecipients
      .filter((recipient) => !recipient._destroy)
      .map((recipient, index) => {
        const indexOfSampleRecipient = sampleRecipients.findIndex((r) => {
          if (!r.uuid || !recipient.uuid) return false;

          return r.uuid === recipient.uuid;
        });
        const formikIndex =
          indexOfSampleRecipient !== -1 ? indexOfSampleRecipient : index;

        const sampleRecipientErrors = errors.sampleRecipients
          ? (errors.sampleRecipients[formikIndex] as {
              [x: string]: string | Option;
            }) || undefined
          : {};

        return (
          <div
            key={index}
            className="flex flex-col gap-3 w-full p-6 rounded-[4px] border border-grey-90"
          >
            <div className="flex items-center justify-between mb-6">
              <Typography variant="h5" font="inter" size="sm" weight="semibold">
                Recipient {index + 1}
              </Typography>
              <FieldArray
                name={BRIEF_FORM_KEYS.SAMPLE_RECIPIENTS}
                render={(arrayHelpers) => {
                  if (sampleRecipients.length > 1) {
                    return (
                      <TrashIcon
                        className="h-4 w-4 text-royal-50 cursor-pointer"
                        onClick={() => {
                          if (recipient.uuid) {
                            arrayHelpers.replace(formikIndex, {
                              ...recipient,
                              _destroy: true,
                            });
                          } else {
                            arrayHelpers.remove(formikIndex);
                          }
                        }}
                      />
                    );
                  } else {
                    return null;
                  }
                }}
              />
            </div>
            <div className="flex gap-9 justify-between">
              <TextField
                {...getFieldProps(`sampleRecipients[${formikIndex}].fullName`)}
                label="Full Name"
                error={Boolean(
                  (touched.sampleRecipients || [])[formikIndex]?.fullName &&
                    sampleRecipientErrors?.fullName
                )}
                helperText={
                  (touched.sampleRecipients || [])[formikIndex]?.fullName
                    ? sampleRecipientErrors?.fullName
                    : ''
                }
                required
              />
            </div>
            <div>
              <TextField
                {...getFieldProps(
                  `sampleRecipients[${formikIndex}].addressLine1`
                )}
                label="Address Line 1"
                error={Boolean(
                  (touched.sampleRecipients || [])[formikIndex]?.addressLine1 &&
                    sampleRecipientErrors?.addressLine1
                )}
                helperText={
                  (touched.sampleRecipients || [])[formikIndex]?.addressLine1
                    ? sampleRecipientErrors?.addressLine1
                    : ''
                }
                required
              />
            </div>
            <div>
              <TextField
                {...getFieldProps(
                  `sampleRecipients[${formikIndex}].addressLine2`
                )}
                label="Address Line 2"
              />
            </div>
            <div className="flex gap-9 justify-between items-end">
              <div>
                <TextField
                  {...getFieldProps(`sampleRecipients[${formikIndex}].city`)}
                  label="City"
                  error={Boolean(
                    (touched.sampleRecipients || [])[formikIndex]?.city &&
                      sampleRecipientErrors?.city
                  )}
                  helperText={
                    (touched.sampleRecipients || [])[formikIndex]?.city
                      ? sampleRecipientErrors?.city
                      : ''
                  }
                  required
                />
              </div>
              <div className="flex-grow">
                <Select
                  {...getFieldProps(`sampleRecipients[${formikIndex}].state`)}
                  onChange={(option: Option) =>
                    setFieldValue(
                      `sampleRecipients[${formikIndex}].state`,
                      option
                    )
                  }
                  width="w-full"
                  placeholder="Select"
                  label="State"
                  options={STATES}
                  error={Boolean(
                    (touched.sampleRecipients || [])[formikIndex]?.state &&
                      (sampleRecipientErrors?.state as Maybe<Option>)?.value
                  )}
                  helperText={
                    (touched.sampleRecipients || [])[formikIndex]?.state
                      ? (sampleRecipientErrors?.state as Maybe<Option>)?.value
                      : ''
                  }
                  required
                />
              </div>
              <div>
                <TextField
                  {...getFieldProps(`sampleRecipients[${formikIndex}].zip`)}
                  additionalClasses="flex-shrink"
                  label="Zip"
                  error={Boolean(
                    (touched.sampleRecipients || [])[formikIndex]?.zip &&
                      sampleRecipientErrors?.zip
                  )}
                  helperText={
                    (touched.sampleRecipients || [])[formikIndex]?.zip
                      ? sampleRecipientErrors?.zip
                      : ''
                  }
                  required
                />
              </div>
            </div>
            <div className="flex gap-9 justify-between">
              <TextField
                {...getFieldProps(`sampleRecipients[${formikIndex}].email`)}
                label="Email"
                error={Boolean(
                  (touched.sampleRecipients || [])[formikIndex]?.email &&
                    sampleRecipientErrors?.email
                )}
                helperText={
                  (touched.sampleRecipients || [])[formikIndex]?.email
                    ? sampleRecipientErrors?.email
                    : ''
                }
                required
              />
              <TextField
                {...getFieldProps(`sampleRecipients[${formikIndex}].phone`)}
                label="Phone"
                error={Boolean(
                  (touched.sampleRecipients || [])[formikIndex]?.phone &&
                    sampleRecipientErrors?.phone
                )}
                helperText={
                  (touched.sampleRecipients || [])[formikIndex]?.phone
                    ? sampleRecipientErrors?.phone
                    : ''
                }
                required
              />
            </div>
          </div>
        );
      });
  };

  return (
    <div className="flex flex-col gap-6">
      {renderSampleRecipientFields()}
      <FieldArray
        name={BRIEF_FORM_KEYS.SAMPLE_RECIPIENTS}
        render={(arrayHelpers) => (
          <Button
            onClick={() => {
              arrayHelpers.push({
                firstName: '',
                lastName: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
                email: '',
                phone: '',
              });
            }}
            leadingIcon={<PlusCircleIcon />}
            text="Add Recipient"
            type="secondary"
          />
        )}
      />
    </div>
  );
};
