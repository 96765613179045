import { createContext } from 'react';

export interface RtlShoppingCartItem {
  id: string;
  imageId: string;
  quantity: number;
  formulaUuid: string;
  price: number;
  displayName: string;
  structure: Maybe<string>;
  primaryContainer: Maybe<string>;
}

interface RtlShoppingCartContextType {
  rtlCart: RtlShoppingCartItem[];
  addItem: (item: RtlShoppingCartItem) => void;
  removeItem: (id: string) => void;
  clearCart: () => void;
  isCartOpen: boolean;
  numberOfItemsInCart: number;
  totalPriceOfItemsInCart: string;
  setIsCartOpen: (isOpen: boolean) => void;
}

export const RtlShoppingCartContext = createContext<RtlShoppingCartContextType>(
  {
    rtlCart: [],
    addItem: () => {},
    removeItem: () => {},
    clearCart: () => {},
    isCartOpen: false,
    numberOfItemsInCart: 0,
    setIsCartOpen: () => {},
    totalPriceOfItemsInCart: '',
  }
);
