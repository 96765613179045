// Libraries
import _ from 'lodash';
// Utils
import { UNITS } from 'features/types';
import { IApiData, WorksheetAttributes } from 'api';

export class QuoteCalculator {
  /**
   * ====== CONSTANTS ======
   *
   *
   *
   *
   *
   *
   *
   *
   *  */
  static BUY_FEE_PERCENTAGE = 0.2;
  static GRAMS_PER_POUND = 453.6;
  static MILLILITERS_PER_OUNCE = 29.57;
  static MINUTES_PER_HOUR = 60;
  static OUNCES_PER_GRAM = 0.035274;
  static OUNCES_PER_MILLILITER = 0.03381402;
  static OUNCES_PER_POUND = 16;

  /**
   *  ====== INITIALIZATION ======
   *
   *
   *
   *
   *
   *
   *
   *
   *  */
  formulaCost: Maybe<number>;
  laborCost: Maybe<number>;
  componentCost: Maybe<number>;
  orderQuantity: number;
  profit: number;
  size: Maybe<number>;
  unit: UNITS;
  ingredients: Array<IApiData<WorksheetAttributes>>;

  constructor({
    orderQuantity,
    profit,
    size,
    unit,
    ingredients,
  }: {
    orderQuantity: number;
    profit: number;
    size: number;
    unit: UNITS;
    ingredients: Array<IApiData<WorksheetAttributes>>;
  }) {
    this.formulaCost = undefined;
    this.laborCost = undefined;
    this.componentCost = undefined;
    this.orderQuantity = orderQuantity;
    this.profit = profit;
    this.size = size;
    this.unit = unit;
    this.ingredients = ingredients;
  }

  /**
   *  ====== STATIC METHODS ======
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */

  // NOTE: The most accurate calculation would involve converting the unit to fluid ounces.
  // but we cannot do this because we do not know the density of the product. So we are using
  // the standard ounce.
  static convertContainerSizeToOunce({
    unit,
    size,
  }: {
    unit: UNITS;
    size: Maybe<number>;
  }) {
    if (!size) return 0;

    const { OUNCES_PER_MILLILITER, OUNCES_PER_GRAM } = QuoteCalculator;

    switch (unit) {
      case UNITS.oz:
        return size;
      case UNITS.ml:
        return size * OUNCES_PER_MILLILITER; // ounces per ml
      case UNITS.g:
        return size * OUNCES_PER_GRAM; // ounces per gram
      default:
        throw new Error(`Unit type not supported: ${unit}`);
    }
  }

  /**
   *  ====== PUBLIC METHODS ======
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */

  public calculateLaborCost({
    batchingHours,
    compoundingHeadCount,
    numberOfBatches,
    fillingHeadCount,
    compoundingPayRate,
    fillingPayRate,
    runRatePerMinute,
    tankSize,
  }: {
    batchingHours: number;
    compoundingHeadCount: number;
    numberOfBatches: number;
    fillingHeadCount: number;
    compoundingPayRate: number;
    fillingPayRate: number;
    runRatePerMinute: number;
    tankSize: number;
  }) {
    // Calculate compounding cost per unit
    const totalCostCompounding =
      batchingHours *
      compoundingHeadCount *
      compoundingPayRate *
      numberOfBatches;

    const compoundingCostPerUnit = totalCostCompounding / this.orderQuantity;

    // Calculate filling cost per unit
    const totalMinutes = this.orderQuantity / runRatePerMinute;
    const totalHours = totalMinutes / QuoteCalculator.MINUTES_PER_HOUR;
    const totalCostFilling = totalHours * fillingHeadCount * fillingPayRate;
    const fillingCostPerUnit = totalCostFilling / this.orderQuantity;

    this.laborCost = compoundingCostPerUnit + fillingCostPerUnit;

    // Tank size is in pounds, so we need to convert it to ounces
    const tankSizeInOunces = tankSize * QuoteCalculator.OUNCES_PER_POUND;

    // Unit output is the number of units that can be produced from a tank
    const unitOutput = tankSizeInOunces / this.size!;

    return {
      unitOutput,
      laborCost: this.laborCost,
      compoundingCostPerUnit,
      fillingCostPerUnit,
    };
  }

  public calculateFormulaCost() {
    // Get ingredients from formula
    // Calculate total ingredient cost / lb

    const totalCostPerPound = _.sum(
      this.ingredients.map((ingredient) =>
        Number(ingredient.attributes.pricePerPound)
      )
    );

    const { OUNCES_PER_POUND } = QuoteCalculator;

    // Calculate total ingredient cost / oz
    const totalCostPerOunce = totalCostPerPound / OUNCES_PER_POUND;

    // Calculate total ingredient cost / size & unit
    const totalCostPerSizeAndUnit = ((unit: UNITS) => {
      if (!this.size) return 0;

      const { GRAMS_PER_POUND, MILLILITERS_PER_OUNCE } = QuoteCalculator;

      switch (unit) {
        case UNITS.oz:
          return totalCostPerOunce * this.size;
        case UNITS.g:
          return (totalCostPerPound / GRAMS_PER_POUND) * this.size;
        case UNITS.ml:
          return (totalCostPerOunce / MILLILITERS_PER_OUNCE) * this.size;
        default:
          return 0;
      }
    })(this.unit);

    this.formulaCost = totalCostPerSizeAndUnit;

    return {
      totalCostPerPound,
      totalCostPerOunce,
      totalCostPerSizeAndUnit,
    };
  }

  public calculateComponentsCost({
    masterBox,
    additionalComponentOne,
    additionalComponentTwo,
    additionalComponentThree,
  }: {
    masterBox: number;
    additionalComponentOne: number;
    additionalComponentTwo: number;
    additionalComponentThree: number;
  }): {
    componentSubtotal: number;
    buyFee: number;
    componentTotalCost: number;
  } {
    // Sum up the components subtotal cost
    const componentSubtotal = _.sum([
      masterBox,
      additionalComponentOne,
      additionalComponentTwo,
      additionalComponentThree,
    ]);

    // Calculate buy fee
    const buyFee = componentSubtotal * QuoteCalculator.BUY_FEE_PERCENTAGE; // 20%

    // Calculate component total cost
    const componentTotalCost = componentSubtotal + buyFee;

    this.componentCost = componentTotalCost;

    return {
      componentSubtotal,
      buyFee,
      componentTotalCost,
    };
  }

  public calculateMargin(defaultMargin?: number) {
    const totalCost = this.formulaCost! + this.laborCost! + this.componentCost!;

    // If a default margin is provided, use it to calculate profit
    if (defaultMargin) {
      this.profit = (defaultMargin / 100) * totalCost;
    }

    const revenue = totalCost + this.profit;

    const margin = defaultMargin || (this.profit / revenue) * 100;

    return {
      profit: this.profit,
      totalCost,
      margin,
    };
  }

  public getContributionPerRun() {
    return this.profit * this.orderQuantity;
  }

  public getTotalExcessRawMaterialsCost() {
    return _.sum(
      this.ingredients.map((ingredient) => {
        return this.calculateExcessRawMaterial({
          percentageRawMaterialUsed: ingredient.attributes.amount,
          rmMoqInPounds: ingredient.attributes.moqInPounds || 0,
          pricePerPound: ingredient.attributes.pricePerPound || 0,
        });
      })
    );
  }

  /**
   *  ====== PRIVATE METHODS ======
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */

  private calculateExcessRawMaterial({
    percentageRawMaterialUsed,
    rmMoqInPounds,
    pricePerPound,
  }: {
    percentageRawMaterialUsed: number;
    rmMoqInPounds: number;
    pricePerPound: number;
  }) {
    const OrderSizeInOunces = this.orderQuantity * this.size!;
    const OrderSizeInPounds =
      OrderSizeInOunces / QuoteCalculator.OUNCES_PER_POUND;

    // Calculate the amount of a specific raw material used per order
    const rawMaterialUsedPerOrderInPounds =
      OrderSizeInPounds * (percentageRawMaterialUsed / 100);

    // Using max and min to ensure we are always subtracting the smaller number from the larger number
    const leftOverInPounds =
      Math.max(rmMoqInPounds, rawMaterialUsedPerOrderInPounds) -
      Math.min(rmMoqInPounds, rawMaterialUsedPerOrderInPounds);

    const costOfExcessRawMaterial = leftOverInPounds * pricePerPound;

    return costOfExcessRawMaterial;
  }
}
