// Libraries
import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
// Components
import { Typography } from './typography.component';
import { AnimatePresence, motion } from 'motion/react';

interface FullScreenModalProps {
  children: JSX.Element | JSX.Element[];
  handleClose: () => void;
  header: string;
  subHeader?: string;
  isOpen: boolean;
}

export const FullScreenModal: React.FC<FullScreenModalProps> = ({
  children,
  handleClose,
  header,
  subHeader,
  isOpen,
}) => {
  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <motion.div
          layout
          initial={{ top: '100vh' }}
          animate={{ top: 0 }}
          exit={{ top: '100vh' }}
          transition={{ duration: 0.3, ease: 'linear' }}
          className={`overscroll-none overflow-auto flex flex-col fixed z-[1300] w-full h-full top-0 left-0 bg-grey-95 pt-15`}
        >
          <div>
            <XMarkIcon
              className="h-6 w-6 cursor-pointer absolute top-6 right-6"
              onClick={handleClose}
            />
          </div>
          <div className="max-w-[1110px] mx-auto">
            <div className="mb-12">
              <Typography
                variant="h2"
                font="inter"
                color="primary"
                additionalStyles="mb-3"
              >
                {header}
              </Typography>

              {subHeader && (
                <Typography variant="p" font="inter" color="primary">
                  {subHeader}
                </Typography>
              )}
            </div>

            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
