// Libraries
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
// Components
import { InviteTeamMemberForm } from './invite-team-member-form.component';
import { TeamMemberRow } from './team-member-row.component';
// Utils
import { CustomerAttributes, IApiData, useApi } from 'api';
import { useSnackbar } from 'hooks';
import { Action, ActionType } from './types';

interface TeamManagementDialogProps {
  companyUuid: string;
  customers: IApiData<CustomerAttributes, any>[];
  handleClose: () => void;
  isOpen: boolean;
  setCustomers: React.Dispatch<
    React.SetStateAction<IApiData<CustomerAttributes, any>[]>
  >;
}

export const TeamManagementDialog: React.FC<TeamManagementDialogProps> = ({
  companyUuid,
  customers,
  handleClose,
  isOpen,
  setCustomers,
}) => {
  const { postEmail, deleteCustomer } = useApi();
  const { showSnackbar } = useSnackbar();
  const [action, setAction] = React.useState<Action>({
    currentResourceId: undefined,
    isLoading: false,
    type: undefined,
  });

  const handleRemoveTeamMember = (id: string) => {
    if (!window.confirm('Remove?')) return;

    setAction({
      currentResourceId: id,
      isLoading: true,
      type: ActionType.remove,
    });

    deleteCustomer({
      urlParams: id,
      handleSuccess: () => {
        showSnackbar('Team member removed!', 'success');
        setCustomers((prev) => prev.filter((customer) => customer.id !== id));
      },
      handleFinally: () =>
        setAction({
          currentResourceId: undefined,
          isLoading: false,
          type: undefined,
        }),
    });
  };

  const handleSendCustomerReinvite = (id: string) => {
    setAction({
      currentResourceId: id,
      isLoading: true,
      type: ActionType.reinvite,
    });

    postEmail({
      data: {
        email: {
          customerUuid: id,
          method: 'create-notification',
          itemType: 'customer_reinvitation',
        },
      },
      handleSuccess: (data: IApiData<CustomerAttributes>) => {
        showSnackbar('Re-invite sent!', 'success');
        setCustomers((prev) =>
          prev.map((customer) => (customer.id === data.id ? data : customer))
        );
      },
      handleError: (e) => {
        showSnackbar('Failed to send invitation.', 'error');
        Rollbar.error(e);
      },
      handleFinally: () =>
        setAction({
          currentResourceId: undefined,
          isLoading: false,
          type: undefined,
        }),
    });
  };

  return (
    <Dialog open={isOpen} maxWidth="md" onClose={handleClose}>
      <DialogTitle>Team Members</DialogTitle>
      <IconButton
        aria-label="close"
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent style={{ padding: '8px 32px' }}>
        <Grid container spacing={2} alignItems="center">
          <InviteTeamMemberForm
            companyUuid={companyUuid}
            setTeamMembers={setCustomers}
          />
          <Grid container item xs={12} spacing={2} style={{ marginTop: 32 }}>
            {customers.map((customer: IApiData<CustomerAttributes>) => (
              <React.Fragment key={customer.id}>
                <TeamMemberRow
                  action={action}
                  setAction={setAction}
                  customer={customer}
                  setCustomers={setCustomers}
                  handleRemoveTeamMember={handleRemoveTeamMember}
                  handleSendCustomerReinvite={handleSendCustomerReinvite}
                />
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
