// Libraries
import React, { useState } from 'react';

interface TooltipProps {
  title: string;
  toolTipDirection?: 'top' | 'bottom' | 'left' | 'right';
  isInlineText?: boolean;
  children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({
  title,
  toolTipDirection = 'top',
  children,
  isInlineText,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const handleInlineTextClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  let baseTooltipStyles = `pointer-events-none bg-grey-20 rounded absolute z-20 py-2 px-2 text-grey-90 text-xs font-inter 
    ${isInlineText && isTooltipOpen ? 'opacity-100' : 'opacity-0'} 
    ${isInlineText ? '' : 'transition-opacity group-hover:opacity-100'} 
    w-[200px] whitespace-normal`;

  let baseTooltipArrowStyles = 'bg-grey-20 absolute -z-10 h-2 w-2 rotate-45';

  let directionalTooltipStyles = '';
  let directionTooltipArrowStyles = '';
  switch (toolTipDirection) {
    case 'bottom':
      directionalTooltipStyles = 'top-full left-1/2 mt-3 -translate-x-1/2';
      directionTooltipArrowStyles = 'top-[-3px] left-1/2 -translate-x-1/2';
      break;
    case 'left':
      directionalTooltipStyles = 'right-full top-1/2 mr-3 -translate-y-1/2';
      directionTooltipArrowStyles = 'right-[-3px] top-1/2 -translate-y-1/2';
      break;
    case 'right':
      directionalTooltipStyles = 'left-full top-1/2 ml-3 -translate-y-1/2';
      directionTooltipArrowStyles = 'left-[-3px] top-1/2 -translate-y-1/2';
      break;
    default:
      directionalTooltipStyles = 'bottom-full left-1/2 mb-3 -translate-x-1/2';
      directionTooltipArrowStyles = 'bottom-[-3px] left-1/2 -translate-x-1/2';
      break;
  }

  return (
    <div className="group relative">
      <span
        className={
          isInlineText ? 'underline decoration-dotted cursor-pointer' : ''
        }
        onClick={isInlineText ? handleInlineTextClick : undefined}
      >
        {children}
      </span>
      <div className={`${baseTooltipStyles} ${directionalTooltipStyles}`}>
        <span
          className={`${baseTooltipArrowStyles} ${directionTooltipArrowStyles} `}
        />
        {title}
      </div>
    </div>
  );
};
