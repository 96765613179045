// Libraries
import React from 'react';
import { Alert, FullScreenModal } from 'design-system';
// Utils
import {
  IApiData,
  CompanyAttributes,
  PriceQuoteType,
  PriceQuoteAttributes,
  TaskAttributes,
} from 'api';
// Components
import { PriceQuoteForm } from './price-quote-form.component';

interface PriceQuoteModalProps {
  isOpen: boolean;
  isPriceQuoteEditable: boolean;
  quoteType: PriceQuoteType;
  company: Maybe<IApiData<CompanyAttributes>>;
  projectId: Maybe<string>;
  closeQuoteModal: () => void;
  priceQuote?: Maybe<IApiData<PriceQuoteAttributes>>;
  setWorksheet: (priceQuote: any) => void;
  tasks?: Maybe<Array<IApiData<TaskAttributes>>>;
}

export const PriceQuoteModal: React.FC<PriceQuoteModalProps> = ({
  isOpen,
  isPriceQuoteEditable,
  quoteType,
  company,
  projectId,
  closeQuoteModal,
  priceQuote,
  setWorksheet,
  tasks,
}) => {
  if (!priceQuote) return null;

  const quoteTypeHeader =
    quoteType?.charAt(0).toUpperCase() + quoteType.slice(1);

  return (
    <FullScreenModal
      isOpen={isOpen}
      handleClose={closeQuoteModal}
      header={`${quoteTypeHeader} Quote Generator`}
      subHeader={`Use the below form to create a ${quoteType} pricing quote.`}
    >
      <>
        {!isPriceQuoteEditable && (
          <div className="mb-3">
            <Alert
              content={
                priceQuote.attributes.status === 'approved'
                  ? `Customer approved this quote on ${new Date(
                      priceQuote.attributes.updatedAt
                    ).toLocaleDateString(
                      'en-US'
                    )}. It can therefore no longer be edited.`
                  : 'This form is currently under review by the customer and therefore cannot be edited.'
              }
              severity={
                priceQuote.attributes.status === 'approved'
                  ? 'success'
                  : 'warning'
              }
              isBanner
            />
          </div>
        )}
      </>
      <PriceQuoteForm
        quoteType={quoteType}
        company={company}
        projectId={projectId}
        handleClose={closeQuoteModal}
        priceQuote={priceQuote}
        setWorksheet={setWorksheet}
        isPriceQuoteEditable={isPriceQuoteEditable}
      />
    </FullScreenModal>
  );
};
