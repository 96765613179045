// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Checkbox, TextArea } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

type Claim = {
  label: string;
  formikKey: string;
  column?: string;
};

const clinicalClaims = [
  { label: 'Anti-redness', formikKey: BRIEF_FORM_KEYS.ANTI_REDNESS },
  { label: 'Acne (OTC)', formikKey: BRIEF_FORM_KEYS.ACNE_OTC },
  { label: 'Anti-fungal (OTC)', formikKey: BRIEF_FORM_KEYS.ANTI_FUNGAL_OTC },
  {
    label: 'Anti-perspirant (OTC)',
    formikKey: BRIEF_FORM_KEYS.ANTI_PERSPIRANT_OTC,
  },
  {
    label: 'Blue Light Protection',
    formikKey: BRIEF_FORM_KEYS.BLUE_LIGHT_PROTECTION,
  },
  { label: 'Gluten free', formikKey: BRIEF_FORM_KEYS.GLUTEN_FREE },
  {
    label: 'Hydration (immediate and/or long term)',
    formikKey: BRIEF_FORM_KEYS.HYDRATION_LONG_TERM,
  },
  { label: 'Hypoallergenic', formikKey: BRIEF_FORM_KEYS.HYPOALLERGENIC },
  {
    label: 'No animal derived ingredients',
    formikKey: BRIEF_FORM_KEYS.NO_ANIMAL_DERIVED_INGREDIENTS,
  },
  { label: 'No animal testing', formikKey: BRIEF_FORM_KEYS.NO_ANIMAL_TESTING },
  { label: 'Pain Relief (OTC)', formikKey: BRIEF_FORM_KEYS.PAIN_RELIEF_OTC },
  { label: 'Soothing', formikKey: BRIEF_FORM_KEYS.SOOTHING },
  {
    label: 'Sun protection (OTC)',
    formikKey: BRIEF_FORM_KEYS.SUN_PROTECTION_OTC,
  },
  { label: 'Vegan', formikKey: BRIEF_FORM_KEYS.VEGAN },
];

const CLAIM_TYPE = {
  CLINICAL: {
    label: 'clinical',
    claims: clinicalClaims,
  },
};

export const ClinicalDataClaims: React.FC = () => {
  const { getFieldProps, values } = useFormikContext<InternalBriefFormValues>();

  const renderClaims = (type: string) => {
    let claims =
      Object.values(CLAIM_TYPE).find((claim) => claim.label === type)?.claims ||
      [];

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 justify-between">
        {claims?.map((claim: Claim) => (
          <div
            className={`${
              claim.column ? `md:col-start-${claim.column} md:col-span-1` : ''
            }`}
            key={claim.formikKey}
          >
            <Checkbox
              {...getFieldProps(claim.formikKey)}
              primaryLabel={claim.label}
              checked={values[claim.formikKey as keyof InternalBriefFormValues]}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-6">
      <p id="clinical-data-claims" className="font-inter text-lg text-grey-20">
        Clinical/Data Claims
      </p>

      <p className="text-grey-50 font-inter text-base font-normal">
        Must be supported by clinical substantiation studies, analytical tests
        and/or meets the FDA monograph as an OTC
      </p>

      {renderClaims(CLAIM_TYPE.CLINICAL.label)}

      <div>
        <p className="font-inter text-base font-bold mb-3">
          Non-OTC Claim Comments
        </p>
        <TextArea
          {...getFieldProps(BRIEF_FORM_KEYS.NON_OTC_CLAIM_COMMENTS)}
          placeholder="Please note we may not be able to meet all requirements"
          value={values.nonOtcClaimComments}
        />
      </div>
    </div>
  );
};
