// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { TextArea } from 'design-system';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const ProductVision: React.FC = () => {
  const { getFieldProps, setFieldValue } = useFormikContext();

  return (
    <div className="flex flex-col gap-6">
      <p id="product-vision" className="font-inter text-lg text-grey-20">
        Product Vision
      </p>
      <div>
        <TextArea
          label="Share a brief overview about your product’s function, purpose, directions for use, objective, target demographic, etc."
          placeholder="Product vision"
          {...getFieldProps(BRIEF_FORM_KEYS.PRODUCT_DESCRIPTION)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(BRIEF_FORM_KEYS.PRODUCT_DESCRIPTION, e.target.value)
          }
        />
      </div>
    </div>
  );
};
