// Libraries
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
// Components
import { Button, Modal, RadioButton, Select, Typography } from 'design-system';
// Utils
import {
  useApi,
  IApiData,
  FormulaAttributes,
  WorksheetAttributes,
  WorksheetApiResponse,
  PriceQuoteType,
} from 'api';

interface NewQuoteModalProps {
  handleClose: () => void;
  projectId: string;
  setWorksheets: React.Dispatch<React.SetStateAction<WorksheetApiResponse[]>>;
  isOpen: boolean;
}

const FORM_VALUES = {
  FORMULA: 'formula',
  QUOTE_TYPE: 'quoteType',
};

const validationSchema = Yup.object().shape({
  [FORM_VALUES.FORMULA]: Yup.string().required('Formula is required'),
  [FORM_VALUES.QUOTE_TYPE]: Yup.string().required('Quote type is required'),
});

const initialValues = {
  [FORM_VALUES.FORMULA]: '',
  [FORM_VALUES.QUOTE_TYPE]: '',
};

type QuoteFormValues = typeof initialValues;

export const NewQuoteModal: React.FC<NewQuoteModalProps> = ({
  handleClose,
  projectId,
  setWorksheets,
  isOpen,
}) => {
  const { getProjectFormulas, postProjectWorksheet } = useApi();
  const [formulas, setFormulas] = useState<any>([]);

  useEffect(() => {
    getProjectFormulas({
      urlParams: `${projectId}/formulas`,
      handleSuccess: ({ data }: { data: IApiData<FormulaAttributes>[] }) => {
        setFormulas(data);
      },
    });
  }, [getProjectFormulas, projectId]);

  if (!isOpen) {
    return null;
  }

  const renderOptions = formulas.map(
    (formula: IApiData<FormulaAttributes>) => ({
      value: formula.id,
      label: formula.attributes.name,
    })
  );

  const handleCreateQuote = (
    values: QuoteFormValues,
    { setSubmitting }: FormikHelpers<QuoteFormValues>
  ) => {
    postProjectWorksheet({
      urlParams: `${projectId}/worksheets`,
      data: {
        worksheet: {
          formulaUuid: values.formula,
          quoteType: values.quoteType,
        },
      },
      handleSuccess: (data: IApiData<WorksheetAttributes>) => {
        setWorksheets((prevWorksheets: any) => {
          // We have to do this because the API response is not in the format we expect
          // because of how creates are transformed by axios' transformers.
          const newQuote = {
            ...data,
            parsedIncluded: {
              formula: (data as any).formula,
              worksheetIngredients: (data as any).worksheetIngredients,
            },
          };

          return [...prevWorksheets, newQuote];
        });
        handleClose();
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  return (
    <Modal handleClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleCreateQuote}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          isSubmitting,
          submitForm,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col font-inter">
              <div className="flex flex-col gap-3 p-6 bg-white ">
                <span className="font-bold">New Quote</span>
                <div className="flex gap-6">
                  <RadioButton
                    value={PriceQuoteType.preliminary}
                    name={FORM_VALUES.QUOTE_TYPE}
                    primaryLabel="Preliminary Quote"
                    checked={values.quoteType === PriceQuoteType.preliminary}
                    onChange={() =>
                      setFieldValue(
                        FORM_VALUES.QUOTE_TYPE,
                        PriceQuoteType.preliminary
                      )
                    }
                  />
                  <RadioButton
                    value={PriceQuoteType.final}
                    name={FORM_VALUES.QUOTE_TYPE}
                    primaryLabel="Final Quote"
                    checked={values.quoteType === PriceQuoteType.final}
                    onChange={() =>
                      setFieldValue(
                        FORM_VALUES.QUOTE_TYPE,
                        PriceQuoteType.final
                      )
                    }
                  />
                </div>
                {errors.quoteType && (
                  <Typography variant="h6" font="inter" color="error">
                    {errors.quoteType}
                  </Typography>
                )}

                <Select
                  error={errors.formula}
                  label="Formula"
                  options={renderOptions}
                  onChange={(option: any) =>
                    setFieldValue(FORM_VALUES.FORMULA, option.value)
                  }
                  value={values.formula}
                />
              </div>
              <div className="px-6 py-3 border-t border-grey-90">
                <div className="flex gap-2 justify-end">
                  <Button
                    disabled={isSubmitting}
                    type="secondary"
                    onClick={handleClose}
                    text="Cancel"
                  />
                  <Button
                    disabled={isSubmitting}
                    onClick={submitForm}
                    type="primary"
                    text="Create"
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
