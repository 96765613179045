// Libraries
import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/16/solid';
// Components
import { Button } from 'design-system';
import { motion, AnimatePresence } from 'motion/react';

interface SectionSummaryRowProps {
  data: any;
  innerContent?: React.ReactNode | React.ReactNode[];
  isActive: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  setActiveSection: () => void;
  isLastSection?: boolean;
}

export const SectionSummaryRow: React.FC<SectionSummaryRowProps> = ({
  data,
  innerContent,
  isActive,
  onCancel,
  onSubmit,
  setActiveSection,
  isLastSection,
}) => {
  return (
    <>
      <AnimatePresence mode="wait">
        {isActive && (
          <motion.div
            layout
            initial={{ height: 0 }}
            animate={{ height: 'auto', overflow: 'hidden' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: 'linear' }}
          >
            {innerContent}
          </motion.div>
        )}
      </AnimatePresence>
      <div
        onClick={() => {
          // This is to prevent the section from trying to open
          // when the user clicks on cancel or save
          if (isActive) return;

          setActiveSection();
        }}
        className={`grid grid-cols-10 items-center px-3 py-4 border-t border-grey-90 bg-white
          ${data.isTopSection ? 'border-b-black' : ''}
          ${isLastSection ? 'rounded-bl rounded-br' : 'border-b-0'}
          ${isActive && 'mb-3 rounded-bl rounded-br'} cursor-pointer`}
      >
        <span
          className={`${
            isActive ? 'text-black' : 'text-grey-50'
          } col-span-2 font-medium`}
        >
          {data.title}
        </span>
        {data.values.map((value: string, index: number) => {
          return (
            <span
              key={value + index}
              className={`${data.isTopSection} col-span-2 font-medium`}
            >
              {value}
            </span>
          );
        })}
        <div className="col-start-10 flex items-center justify-end cursor-pointer">
          {isActive ? (
            <div className="flex gap-[5px]">
              <Button onClick={onCancel} type="secondary" text="Cancel" />
              <Button
                type="primary"
                text="Save"
                onClick={onSubmit}
                additionalStyles="border-0"
              />
            </div>
          ) : (
            <PencilSquareIcon
              onClick={setActiveSection}
              className=" h-4 w-4 text-royal-50"
            />
          )}
        </div>
      </div>
    </>
  );
};
