// Libraries
import React from 'react';
import { Formik, FormikHelpers } from 'formik';
// Components
import { BottomBar, Button, Divider, Typography } from 'design-system';
import {
  BasicInfo,
  MaterialResponsibility,
  Overview,
  Price,
  Terms,
} from './form-modules';
// Utils
import { PriceQuoteFormValues } from './form.config';
import { useSnackbar } from 'hooks';
import { draftPriceQuoteValidationSchema } from '../validations';
// Assets
import { ReactComponent as PmLogo } from '../../../img/pm-logo-black.svg';
import {
  CompanyAttributes,
  IApiData,
  PriceQuoteAttributes,
  PriceQuoteType,
  useApi,
} from 'api';
import { DocumentIcon } from '@heroicons/react/24/outline';
// Constants
import {
  INITIAL_PRICE_QUOTE_FORM_VALUES,
  PRICE_QUOTE_FORM_KEYS,
  PRICE_QUOTE_TYPE,
  PRICE_SIZE_UNITS,
} from '../price-quote.constants';

interface PriceQuoteFormProps {
  quoteType: PriceQuoteType;
  company?: Maybe<IApiData<CompanyAttributes>>;
  projectId?: string;
  handleClose: () => void;
  priceQuote?: Maybe<IApiData<PriceQuoteAttributes>>;
  setWorksheet: (priceQuote: any) => void;
  isPriceQuoteEditable?: boolean;
}

export const PriceQuoteForm: React.FC<PriceQuoteFormProps> = ({
  quoteType,
  company,
  projectId,
  handleClose,
  priceQuote,
  setWorksheet,
  isPriceQuoteEditable,
}) => {
  const { patchProjectWorksheetPriceQuote } = useApi();
  // imports
  const { showSnackbar } = useSnackbar();

  if (!priceQuote) return null;

  // functions
  const parsedNum = (num: Maybe<number | string>) => {
    if (!num) return num;
    return typeof num === 'number' ? num : parseInt(num.replace(/,/g, ''));
  };

  const handleSubmit = (
    values: PriceQuoteFormValues,
    { setSubmitting }: FormikHelpers<PriceQuoteFormValues>
  ) => {
    const {
      personalNote,
      quoteVersionNumber,
      quoteDate,
      validFor,
      paymentTerms,
      incoterms,
      deliveryTerms,
      formulaDescription,
      moqOne,
      moqTwo,
      moqThree,
      sizeOneQuantity,
      sizeOneUnit,
      sizeOneDescription,
      sizeOneMoqOneTotalCostPerUnit,
      sizeOneMoqTwoTotalCostPerUnit,
      sizeOneMoqThreeTotalCostPerUnit,
      sizeTwoQuantity,
      sizeTwoUnit,
      sizeTwoDescription,
      sizeTwoMoqOneTotalCostPerUnit,
      sizeTwoMoqTwoTotalCostPerUnit,
      sizeTwoMoqThreeTotalCostPerUnit,
      sizeThreeQuantity,
      sizeThreeUnit,
      sizeThreeDescription,
      sizeThreeMoqOneTotalCostPerUnit,
      sizeThreeMoqTwoTotalCostPerUnit,
      sizeThreeMoqThreeTotalCostPerUnit,
      pricingNotes,
      materialsNote,
      rawMaterialResponsibility,
      containersResponsibility,
      decorationResponsibility,
      closureResponsibility,
      masterResponsibility,
      formulaUuid,
    } = values;
    setSubmitting(true);

    // Update price quote
    patchProjectWorksheetPriceQuote({
      urlParams: `${projectId}/worksheets/${priceQuote?.relationships.worksheet.data.id}/price_quotes/${priceQuote?.id}`,
      data: {
        priceQuote: {
          quoteType: quoteType,
          personalNote,
          quoteVersionNumber,
          quoteDate: new Date(quoteDate),
          validFor: parsedNum(validFor),
          paymentTerms,
          incoterms,
          deliveryTerms,
          formulaDescription,
          moqOne: parsedNum(moqOne),
          moqTwo: parsedNum(moqTwo),
          moqThree: parsedNum(moqThree),
          sizeOneQuantity: parsedNum(sizeOneQuantity),
          sizeOneUnit: sizeOneUnit.value,
          sizeOneDescription,
          sizeOneMoqOneTotalCostPerUnit: parsedNum(
            sizeOneMoqOneTotalCostPerUnit
          ),
          sizeOneMoqTwoTotalCostPerUnit: parsedNum(
            sizeOneMoqTwoTotalCostPerUnit
          ),
          sizeOneMoqThreeTotalCostPerUnit: parsedNum(
            sizeOneMoqThreeTotalCostPerUnit
          ),
          sizeTwoQuantity: parsedNum(sizeTwoQuantity),
          sizeTwoUnit: sizeTwoUnit.value,
          sizeTwoDescription,
          sizeTwoMoqOneTotalCostPerUnit: parsedNum(
            sizeTwoMoqOneTotalCostPerUnit
          ),
          sizeTwoMoqTwoTotalCostPerUnit: parsedNum(
            sizeTwoMoqTwoTotalCostPerUnit
          ),
          sizeTwoMoqThreeTotalCostPerUnit: parsedNum(
            sizeTwoMoqThreeTotalCostPerUnit
          ),
          sizeThreeQuantity: parsedNum(sizeThreeQuantity),
          sizeThreeUnit: sizeThreeUnit.value,
          sizeThreeDescription,
          sizeThreeMoqOneTotalCostPerUnit: parsedNum(
            sizeThreeMoqOneTotalCostPerUnit
          ),
          sizeThreeMoqTwoTotalCostPerUnit: parsedNum(
            sizeThreeMoqTwoTotalCostPerUnit
          ),
          sizeThreeMoqThreeTotalCostPerUnit: parsedNum(
            sizeThreeMoqThreeTotalCostPerUnit
          ),
          pricingNotes,
          materialsNote,
          rawMaterialResponsibility,
          containersResponsibility,
          decorationResponsibility,
          closureResponsibility,
          masterResponsibility,
          formulaUuid,
        },
      },
      handleSuccess: () => {
        handleClose();
        showSnackbar('Draft updated', 'success');
        // Update price quote in worksheet
        setWorksheet({
          ...priceQuote,
          attributes: values,
        });
      },
      handleFinally: () => {
        setSubmitting(false);
      },
      handleError: () => {
        setSubmitting(false);
        showSnackbar('Error updating draft', 'error');
      },
    });
  };

  const initialValues: PriceQuoteFormValues = {
    ...(priceQuote.attributes as any),
    [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT]:
      PRICE_SIZE_UNITS.find(
        (unit) => unit.value === priceQuote.attributes.sizeOneUnit
      ) || INITIAL_PRICE_QUOTE_FORM_VALUES[PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT],
    [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT]:
      PRICE_SIZE_UNITS.find(
        (unit) => unit.value === priceQuote.attributes.sizeTwoUnit
      ) || INITIAL_PRICE_QUOTE_FORM_VALUES[PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT],
    [PRICE_QUOTE_FORM_KEYS.SIZE_THREE_UNIT]:
      PRICE_SIZE_UNITS.find(
        (unit) => unit.value === priceQuote.attributes.sizeThreeUnit
      ) ||
      INITIAL_PRICE_QUOTE_FORM_VALUES[PRICE_QUOTE_FORM_KEYS.SIZE_THREE_UNIT],
    [PRICE_QUOTE_FORM_KEYS.FORMULA_UUID]:
      priceQuote.relationships.formula.data.id,
    [PRICE_QUOTE_FORM_KEYS.QUOTE_DATE]: priceQuote.attributes.quoteDate
      ? new Date(priceQuote.attributes.quoteDate!).toLocaleDateString('en-US')
      : '',
  };

  return (
    <div
      className={`relative h-auto w-[1110px] flex flex-col rounded shadow-md bg-white mb-24 font-inter`}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={draftPriceQuoteValidationSchema}
        enableReinitialize
      >
        {({ submitForm, isSubmitting, dirty, setSubmitting }) => {
          return (
            <>
              <div className="p-6 flex flex-col gap-6 overflow-visible overflow-y-scroll">
                <div className="flex flex-row">
                  <div className="mr-6">
                    <PmLogo />
                  </div>
                  <div>
                    <Typography variant="p" font="agipo" size="xl">
                      {PRICE_QUOTE_TYPE[quoteType]?.title}
                    </Typography>
                  </div>
                </div>

                <BasicInfo isPriceQuoteEditable={isPriceQuoteEditable} />

                <div className="flex flex-col border rounded border-grey-90 p-6">
                  <div className="flex flex-row justify-between">
                    <Overview
                      companyName={company?.attributes.name}
                      isPriceQuoteEditable={isPriceQuoteEditable}
                    />
                    <Terms isPriceQuoteEditable={isPriceQuoteEditable} />
                  </div>

                  <Divider className="my-12" />

                  <Price
                    projectId={projectId}
                    isPriceQuoteEditable={isPriceQuoteEditable}
                  />

                  <Divider className="my-12" />

                  <MaterialResponsibility
                    isPriceQuoteEditable={isPriceQuoteEditable}
                  />
                </div>
              </div>
              <BottomBar
                buttons={[
                  <Button
                    size="large"
                    text={isPriceQuoteEditable ? 'Cancel' : 'Close'}
                    type="secondary"
                    disabled={isSubmitting}
                    action={() => {
                      if (dirty) {
                        const confirm = window.confirm(
                          'Are you sure? Your changes will not be saved.'
                        );

                        if (confirm) {
                          handleClose();
                          return;
                        }
                      } else {
                        handleClose();
                      }
                    }}
                  />,
                  isPriceQuoteEditable ? (
                    <Button
                      size="large"
                      text="Save Draft"
                      type="primary"
                      leadingIcon={<DocumentIcon />}
                      action={submitForm}
                      disabled={isSubmitting}
                    />
                  ) : undefined,
                ]}
                additionalStyles="left-0"
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};
