// Libraries
import React, { useEffect } from 'react';
import { Button, Divider, TextField } from 'design-system';
import { useFormikContext } from 'formik';
// Utils
import { WorksheetFormValues } from './worksheet.types';
// Constants
import { FILL_SIZE_KEYS } from './constants';
import { UNITS } from 'features/types';

interface FillSizeModalProps {
  closeFillSizeModal: () => void;
  fillSizeValue?: Maybe<number | null>;
  fillSizeIndex?: Maybe<number | null>;
  setSelectedSizeIndex: (index: number) => void;
}

export const FillSizeModal: React.FC<FillSizeModalProps> = ({
  closeFillSizeModal,
  fillSizeValue,
  fillSizeIndex,
  setSelectedSizeIndex,
}) => {
  const { values, setFieldValue } = useFormikContext<WorksheetFormValues>();
  const {
    sizeOneUnit,
    sizeOneQuantity,
    sizeTwoUnit,
    sizeTwoQuantity,
    sizeThreeUnit,
    sizeThreeQuantity,
  } = values;

  const [sizeQuantity, setSizeQuantity] = React.useState<number | null>(null);

  useEffect(() => {
    // if an index exists then we are editing an existing fill size so update local state with the prop value because it's a controlled input
    fillSizeValue && setSizeQuantity(fillSizeValue);
  }, [fillSizeValue]);

  const handleSave = () => {
    let fillSize = null;
    // if we have an index then this is an edit to an existing fill size so we just need to find the correct key and update it
    if (fillSizeIndex !== null && fillSizeIndex !== undefined) {
      fillSize = FILL_SIZE_KEYS[fillSizeIndex];
      setFieldValue(fillSize.size!, sizeQuantity);
      closeFillSizeModal();
      return;
    }
    // if we don't have an index then we need to find the first empty fill size and update it
    if (!sizeOneQuantity && !sizeOneUnit) {
      fillSize = FILL_SIZE_KEYS[0];
      setFieldValue(fillSize.unit!, UNITS.oz);
      setFieldValue(fillSize.size!, sizeQuantity);
      setSelectedSizeIndex(0);
      closeFillSizeModal();
      return;
    } else if (!sizeTwoQuantity && !sizeTwoUnit) {
      fillSize = FILL_SIZE_KEYS[1];
      setFieldValue(fillSize.unit!, UNITS.oz);
      setFieldValue(fillSize.size!, sizeQuantity);
      setSelectedSizeIndex(1);
      closeFillSizeModal();
      return;
    } else if (!sizeThreeQuantity && !sizeThreeUnit) {
      fillSize = FILL_SIZE_KEYS[2];
      setFieldValue(fillSize.unit!, UNITS.oz);
      setFieldValue(fillSize.size!, sizeQuantity);
      setSelectedSizeIndex(2);
      closeFillSizeModal();
      return;
    }
  };

  return (
    <div className="flex flex-col">
      <div className="p-6 font-inter">
        <TextField
          className="ml-2"
          label="Fill Size"
          trailingText="oz"
          trailingTextColor="text-black"
          type="number"
          placeHolder="00"
          height={null}
          onChange={(e: any) => setSizeQuantity(e.target.value)}
          value={sizeQuantity}
        />
      </div>
      <Divider />
      <div className="flex flex-row gap-x-3 justify-end p-4">
        <Button
          additionalStyles="font-inter"
          text="Cancel"
          type="secondary"
          action={closeFillSizeModal}
        />
        <Button
          additionalStyles="font-inter"
          text="Save"
          type="primary"
          action={handleSave}
        />
      </div>
    </div>
  );
};
