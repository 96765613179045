// Libraries
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
// Components
import {
  Autocomplete,
  Button,
  Modal,
  TextField,
  Typography,
} from 'design-system';
// Utils
import { IApiData, useApi, WorksheetIngredientAttributes } from 'api';
import { IngredientFormValues } from './formula-section.types';

interface IngredientSwapModalProps {
  handleClose: () => void;
  worksheetIngredient: Maybe<IApiData<WorksheetIngredientAttributes>>;
  handleSwap: (newIngredient: IngredientFormValues) => void;
}

export const IngredientSwapModal: React.FC<IngredientSwapModalProps> = ({
  handleClose,
  worksheetIngredient,
  handleSwap,
}) => {
  const { searchRawMaterials } = useApi();
  const isOpen = !!worksheetIngredient;

  const [initialFormValues, setInitialFormValues] = useState<
    IngredientFormValues
  >({
    rawMaterial: {
      value: '',
      label: '',
    } as Option,
    amount: '',
  });

  useEffect(() => {
    setInitialFormValues({
      rawMaterial: {
        value: worksheetIngredient?.attributes.rmUuid,
        label: worksheetIngredient?.attributes.name,
      } as Option,
      amount: `${worksheetIngredient?.attributes.amount}`,
    });
  }, [worksheetIngredient]);

  if (!isOpen) return null;

  const clearField = () => {
    setInitialFormValues({
      ...initialFormValues,
      rawMaterial: { value: '', label: '' },
    });
  };

  return (
    <Modal handleClose={handleClose}>
      <Formik
        initialValues={initialFormValues}
        onSubmit={(values) => {
          handleSwap(values);
          handleClose();
        }}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          getFieldProps,
          isSubmitting,
        }) => (
          <div className="p-6 pb-4">
            <Typography variant="h3" font="inter" additionalStyles="mb-4">
              Change ingredient
            </Typography>
            <section>
              <div className="w-full">
                <Autocomplete
                  label="Search raw materials"
                  value={values.rawMaterial}
                  clearField={clearField}
                  onChange={(selectedOption: Option) =>
                    setFieldValue('rawMaterial', selectedOption)
                  }
                  required
                  search={searchRawMaterials}
                  responseParser={(response) => {
                    return response.data.map((rm: any) => ({
                      label: rm.attributes.name,
                      value: rm.id,
                    }));
                  }}
                />
              </div>
              <div className="w-full">
                <TextField {...getFieldProps('amount')} label="Weight %" />
              </div>
            </section>
            <section className="flex justify-end">
              <Button
                action={handleClose}
                additionalStyles="mr-2"
                disabled={isSubmitting}
                size="large"
                text="Cancel"
                type="secondary"
              />
              <Button
                action={handleSubmit}
                disabled={isSubmitting}
                size="large"
                text="Submit"
              />
            </section>
          </div>
        )}
      </Formik>
    </Modal>
  );
};
