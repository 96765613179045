// Libraries
import React, { useState } from 'react';
import { Button, TextArea, Typography } from 'design-system';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { useFormikContext } from 'formik';
// Constants
import { WORKSHEET_FORM_KEYS } from '../constants';

interface NotesSectionProps {
  onSubmit: () => void;
}

export const NotesSection: React.FC<NotesSectionProps> = ({ onSubmit }) => {
  const { getFieldProps } = useFormikContext();

  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="flex flex-col mt-14">
      <div className="flex flex-row items-center gap-x-3 mb-2 ">
        <Typography variant="h4" weight="medium" color="secondary">
          Notes
        </Typography>
        {!isEditing && (
          <PencilSquareIcon
            className="text-royal-50 h-5 w-5 cursor-pointer"
            onClick={() => setIsEditing(!isEditing)}
          />
        )}
      </div>

      <TextArea
        isDisabled={!isEditing}
        additionalStyles={isEditing ? 'rounded-bl-none rounded-br-none ' : ''}
        {...getFieldProps(WORKSHEET_FORM_KEYS.NOTES)}
      />

      {isEditing && (
        <div className="flex flex-row justify-end bg-white p-3 -mt-1">
          <Button
            text="Save"
            size="medium"
            onClick={() => {
              setIsEditing(false);
              onSubmit();
            }}
          />
        </div>
      )}
    </div>
  );
};
