// Libraries
import React from 'react';
// Components
import { TextArea, Typography } from 'design-system';
import { Formula } from './formula.component';
import { SizeTable } from './size-table.component';
// Utils
import { useFormikContext } from 'formik';
import { PriceQuoteFormValues } from '../form.config';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from 'features/price-quote/price-quote.constants';

interface PriceProps {
  projectId?: string;
  isPriceQuoteEditable: Maybe<boolean>;
}

export const Price: React.FC<PriceProps> = ({
  projectId,
  isPriceQuoteEditable,
}) => {
  const { getFieldProps } = useFormikContext<PriceQuoteFormValues>();

  return (
    <div className="flex flex-col gap-12">
      <Typography font="inter" variant="h3" color="primary" weight="semibold">
        Price
      </Typography>

      <Formula
        projectId={projectId}
        isPriceQuoteEditable={isPriceQuoteEditable}
      />

      <SizeTable isPriceQuoteEditable={isPriceQuoteEditable} />

      <div className="w-1/2">
        <TextArea
          label="Pricing Notes (Optional)"
          placeholder="Enter any notes on this pricing."
          rows={6}
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.PRICING_NOTES)}
          isDisabled={!isPriceQuoteEditable}
        />
      </div>
    </div>
  );
};
