// Libraries
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DocumentPlusIcon } from '@heroicons/react/16/solid';
// Components
import {
  Autocomplete,
  BottomBar,
  Button,
  CollapsibleSection,
  Modal,
  Page,
  Select,
  Switch,
  TextArea,
  TextField,
} from 'design-system';
// Utils
import { useApi } from 'api';
import { useAlert } from 'context/alert-context/alert-context';
// Constants
import { RTL_CATALOGUE_IMAGES, TYPE_OF_SYSTEM } from './constants';
import { PRODUCT_OPTIONS } from 'features/brief/form-modules/form-module.constants';
import {
  primaryComponentOptions,
  PRODUCT_CATEGORIES,
  OPACITIES,
  STRUCTURES,
} from 'features/brief/brief.constants';
import {
  CLOSURE_MENU_ITEMS,
  MATERIAL_MENU_ITEMS,
} from 'features/pricing-quote/constants';
import { MASTER_BOX_OPTIONS } from 'features/brief/form-modules/master-box.component';
import { UNITS } from 'features/types';
import { ROUTES } from 'features/navigation';

interface FormItem {
  title: string;
  items: React.ReactNode | React.ReactNode[];
}

interface FormItems {
  [key: string]: FormItem;
}

export const CreateNewCatalogueEntry = () => {
  const { state } = useLocation();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { productCategoryLabel, productCategoryValue } = state || {};
  const { postRtlCatalogueEntry, searchFormulas } = useApi();

  const [showSaveAsDraftModal, setShowSaveAsDraftModal] = useState(false);

  type RtlCatalogueFormValues = {
    clinicalClaimAntiRedness: boolean;
    clinicalClaimBlueLightProtection: boolean;
    clinicalClaimGlutenFree: boolean;
    clinicalClaimHydration: boolean;
    clinicalClaimHypoallergenic: boolean;
    clinicalClaimNoAnimalDerivedIngredients: boolean;
    clinicalClaimNoAnimalTesting: boolean;
    clinicalClaimNonOtcComment: boolean;
    clinicalClaimSoothing: boolean;
    clinicalClaimVegan: boolean;
    closure: Option;
    colorNote: string;
    countryComplianceCanada: boolean;
    countryComplianceEu: boolean;
    countryComplianceNote: string;
    countryComplianceOther: boolean;
    countryComplianceUs: boolean;
    displayName: string;
    formulaNumber: { label: string; value: string };
    fragranceNote: string;
    imageId: string;
    internalNotes: string;
    status: 'draft' | 'published';
    keyBenefitBrightening: boolean;
    keyBenefitEyesReduceAppearanceOfDarkCircles: boolean;
    keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet: boolean;
    keyBenefitEyesReduceAppearanceOfPuffiness: boolean;
    keyBenefitFirming: boolean;
    keyBenefitHydration: boolean;
    keyBenefitMattifying: boolean;
    keyBenefitMoisturization: boolean;
    keyBenefitRadianceGlow: boolean;
    keyBenefitSelfTanner: boolean;
    keyBenefitSmoothing: boolean;
    keyBenefitTightening: boolean;
    masterBox: Option;
    material: Option;
    minimumOrderQuantity: string;
    opacity: Option;
    otc: boolean;
    price: string;
    primaryComponent: Option;
    productDescription: string;
    productType: Option;
    productionLocation: Option;
    regulatoryGlutenFree: boolean;
    regulatoryIfraCompliant: boolean;
    regulatoryNoAnimalDerivedIngredients: boolean;
    regulatoryNoAnimalTesting: boolean;
    regulatoryVegan: boolean;
    retailerClaimCyclicSiliconeFree: boolean;
    retailerClaimSephoraClean: boolean;
    retailerClaimTargetClean: boolean;
    retailerClaimUltaClean: boolean;
    rinseOffLeaveOn: Option;
    fillSize: string;
    spf: string;
    structure: Option;
    testingFinalComponent: boolean;
    testingHript: boolean;
    testingHriptWithDermatologistSignature: boolean;
    testingHriptWithHypoallergenicClaim: boolean;
    testingInVitro: boolean;
    testingOcularIrritation: boolean;
    testingPet: boolean;
    testingSpfDeterminationFda: boolean;
    testingSpfDeterminationIso: boolean;
    testingStability: boolean;
    testingWaterActivity: boolean;
    tinted: Option;
    typeOfSystem: Option;
    unit: Option;
  };

  const formik = useFormik<RtlCatalogueFormValues>({
    initialValues: {
      clinicalClaimAntiRedness: false,
      clinicalClaimBlueLightProtection: false,
      clinicalClaimGlutenFree: false,
      clinicalClaimHydration: false,
      clinicalClaimHypoallergenic: false,
      clinicalClaimNoAnimalDerivedIngredients: false,
      clinicalClaimNoAnimalTesting: false,
      clinicalClaimNonOtcComment: false,
      clinicalClaimSoothing: false,
      clinicalClaimVegan: false,
      closure: { label: '', value: '' },
      colorNote: '',
      countryComplianceCanada: false,
      countryComplianceEu: false,
      countryComplianceNote: '',
      countryComplianceOther: false,
      countryComplianceUs: false,
      displayName: '',
      formulaNumber: { label: '', value: '' },
      fragranceNote: '',
      imageId: '01',
      internalNotes: '',
      status: 'draft',
      keyBenefitBrightening: false,
      keyBenefitEyesReduceAppearanceOfDarkCircles: false,
      keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet: false,
      keyBenefitEyesReduceAppearanceOfPuffiness: false,
      keyBenefitFirming: false,
      keyBenefitHydration: false,
      keyBenefitMattifying: false,
      keyBenefitMoisturization: false,
      keyBenefitRadianceGlow: false,
      keyBenefitSelfTanner: false,
      keyBenefitSmoothing: false,
      keyBenefitTightening: false,
      masterBox: { label: '', value: '' },
      material: { label: '', value: '' },
      minimumOrderQuantity: '',
      opacity: { label: '', value: '' },
      otc: false,
      price: '',
      primaryComponent: { label: '', value: '' },
      productDescription: '',
      productType: { label: '', value: '' },
      productionLocation: { label: '', value: '' },
      regulatoryGlutenFree: false,
      regulatoryIfraCompliant: false,
      regulatoryNoAnimalDerivedIngredients: false,
      regulatoryNoAnimalTesting: false,
      regulatoryVegan: false,
      retailerClaimCyclicSiliconeFree: false,
      retailerClaimSephoraClean: false,
      retailerClaimTargetClean: false,
      retailerClaimUltaClean: false,
      rinseOffLeaveOn: { label: '', value: '' },
      fillSize: '',
      spf: '',
      structure: { label: '', value: '' },
      testingFinalComponent: false,
      testingHript: false,
      testingHriptWithDermatologistSignature: false,
      testingHriptWithHypoallergenicClaim: false,
      testingInVitro: false,
      testingOcularIrritation: false,
      testingPet: false,
      testingSpfDeterminationFda: false,
      testingSpfDeterminationIso: false,
      testingStability: false,
      testingWaterActivity: false,
      tinted: { label: '', value: '' },
      typeOfSystem: { label: '', value: '' },
      unit: { label: '', value: '' },
    },
    validationSchema: Yup.object({
      displayName: Yup.string().required('Please enter a formula name'),
    }).shape({
      formulaNumber: Yup.object().when('status', {
        is: 'published',
        then: Yup.object().shape({
          label: Yup.string().required('Please select a formula'),
          value: Yup.string().required('Please select a formula'),
        }),
      }),
      price: Yup.string().when('status', {
        is: 'published',
        then: Yup.string().required('Please enter a price'),
      }),
    }),
    onSubmit: (values) => {
      const {
        clinicalClaimAntiRedness,
        clinicalClaimBlueLightProtection,
        clinicalClaimGlutenFree,
        clinicalClaimHydration,
        clinicalClaimHypoallergenic,
        clinicalClaimNoAnimalDerivedIngredients,
        clinicalClaimNoAnimalTesting,
        clinicalClaimNonOtcComment,
        clinicalClaimSoothing,
        clinicalClaimVegan,
        closure,
        colorNote,
        countryComplianceCanada,
        countryComplianceEu,
        countryComplianceNote,
        countryComplianceOther,
        countryComplianceUs,
        formulaNumber,
        fragranceNote,
        internalNotes,
        keyBenefitBrightening,
        keyBenefitEyesReduceAppearanceOfDarkCircles,
        keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet,
        keyBenefitEyesReduceAppearanceOfPuffiness,
        keyBenefitFirming,
        keyBenefitHydration,
        keyBenefitMattifying,
        keyBenefitMoisturization,
        keyBenefitRadianceGlow,
        keyBenefitSelfTanner,
        keyBenefitSmoothing,
        keyBenefitTightening,
        masterBox,
        material,
        minimumOrderQuantity,
        opacity,
        otc,
        price,
        primaryComponent,
        productDescription,
        productType,
        productionLocation,
        regulatoryGlutenFree,
        regulatoryIfraCompliant,
        regulatoryNoAnimalDerivedIngredients,
        regulatoryNoAnimalTesting,
        regulatoryVegan,
        retailerClaimCyclicSiliconeFree,
        retailerClaimSephoraClean,
        retailerClaimTargetClean,
        retailerClaimUltaClean,
        rinseOffLeaveOn,
        fillSize,
        spf,
        status,
        structure,
        testingFinalComponent,
        testingHript,
        testingHriptWithDermatologistSignature,
        testingHriptWithHypoallergenicClaim,
        testingInVitro,
        testingOcularIrritation,
        testingPet,
        testingSpfDeterminationFda,
        testingSpfDeterminationIso,
        testingStability,
        testingWaterActivity,
        tinted,
        typeOfSystem,
        unit,
      } = values;
      postRtlCatalogueEntry({
        data: {
          rtlCatalogueEntry: {
            imageId: values.imageId,
            displayName: values.displayName,
            formulaUuid: formulaNumber.value,
            minimumOrderQuantity,
            price,
            productDescription,
            status,
            productAttributes: {
              productCategory: productCategoryValue,
              productType: productType.value,
              clinicalClaimAntiRedness,
              clinicalClaimBlueLightProtection,
              clinicalClaimGlutenFree,
              clinicalClaimHydration,
              clinicalClaimHypoallergenic,
              clinicalClaimNoAnimalDerivedIngredients,
              clinicalClaimNoAnimalTesting,
              clinicalClaimNonOtcComment,
              clinicalClaimSoothing,
              clinicalClaimVegan,
              closure: closure.value,
              colorNote,
              countryComplianceCanada,
              countryComplianceEu,
              countryComplianceNote,
              countryComplianceOther,
              countryComplianceUs,
              fragranceNote,
              internalNotes,
              keyBenefitBrightening,
              keyBenefitEyesReduceAppearanceOfDarkCircles,
              keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet,
              keyBenefitEyesReduceAppearanceOfPuffiness,
              keyBenefitFirming,
              keyBenefitHydration,
              keyBenefitMattifying,
              keyBenefitMoisturization,
              keyBenefitRadianceGlow,
              keyBenefitSelfTanner,
              keyBenefitSmoothing,
              keyBenefitTightening,
              masterBox: masterBox.value,
              material: material.value,
              opacity: opacity.value,
              otc,
              primaryComponent: primaryComponent.value,
              productionLocation: productionLocation.value,
              regulatoryGlutenFree,
              regulatoryIfraCompliant,
              regulatoryNoAnimalDerivedIngredients,
              regulatoryNoAnimalTesting,
              regulatoryVegan,
              retailerClaimCyclicSiliconeFree,
              retailerClaimSephoraClean,
              retailerClaimTargetClean,
              retailerClaimUltaClean,
              rinseOffLeaveOn: rinseOffLeaveOn.value,
              fillSize,
              spf,
              structure: structure.value,
              testingFinalComponent,
              testingHript,
              testingHriptWithDermatologistSignature,
              testingHriptWithHypoallergenicClaim,
              testingInVitro,
              testingOcularIrritation,
              testingPet,
              testingSpfDeterminationFda,
              testingSpfDeterminationIso,
              testingStability,
              testingWaterActivity,
              tinted: tinted.value,
              typeOfSystem: typeOfSystem.value,
              unit: unit.value,
            },
          },
        },
        handleSuccess: (response) => {
          navigate(ROUTES.RTL_FORMULA_CATALOGUE.route);
          showAlert({
            content: 'RTL catalogue entry created successfully',
            severity: 'success',
          });
        },
      });
    },
  });

  const { values, getFieldProps, setFieldValue } = formik;

  const setTypedFieldValue = <T extends keyof RtlCatalogueFormValues>(
    field: T,
    value: RtlCatalogueFormValues[T]
  ) => {
    formik.setFieldValue(field, value);
  };

  const renderFormulaImages = () => {
    return (
      <div className="flex flex-wrap gap-[18px] mt-4 max-w-[406px]">
        {RTL_CATALOGUE_IMAGES.map((image, index) => (
          <img
            alt={`formula ${index}`}
            className={`h-[83px] w-[83px] box-border rounded cursor-pointer border-4  ${
              values.imageId === image.id ? 'border-royal-50' : 'border-white'
            }`}
            key={index}
            onClick={() => setFieldValue('imageId', image.id)}
            src={image.src}
          />
        ))}
      </div>
    );
  };

  const renderFormSection = (section: any) => {
    return (
      <CollapsibleSection key={section.title} title={section.title}>
        <div className="flex flex-col px-4 border border-grey-90 rounded">
          {section.items.map((item: any, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </CollapsibleSection>
    );
  };

  const renderInfoRow = (label: string, info: any) => {
    return (
      <div className="flex justify-between py-4 border-b border-grey-90">
        <span className="text-grey-50">{label}</span>
        {info}
      </div>
    );
  };

  const renderInputRow = (label: string, input: any, isLast?: boolean) => {
    return (
      <div
        key={label}
        className={`flex gap-6 items-center py-[6px] ${
          isLast ? '' : 'border-b border-grey-90'
        }`}
      >
        <span className="w-1/2 text-grey-50">{label}</span>
        <div className="w-1/2">{input}</div>
      </div>
    );
  };

  const renderSwitchRow = (label: string, input: any, isLast?: boolean) => {
    return (
      <div
        className={`flex justify-between items-center py-3 ${
          isLast ? '' : 'border-b border-grey-90'
        }`}
      >
        <span className="w-1/2 text-grey-50">{label}</span>
        {input}
      </div>
    );
  };

  const formItems: FormItems = {
    formulaDetails: {
      title: 'Formula Details',
      items: [
        renderInputRow(
          'Formula Number',
          <Autocomplete
            error={formik.errors.formulaNumber?.value}
            onChange={(option: Option) => {
              setTypedFieldValue('formulaNumber', option);
            }}
            placeholder="Select a formula"
            responseParser={(response) =>
              response?.results.map((result: any) => ({
                label: result._source.external_id,
                value: result._source.uuid,
              }))
            }
            search={searchFormulas}
            urlParams={'&filters=external_id'}
          />
        ),
        renderInputRow(
          'MOQ',
          <TextField {...getFieldProps('minimumOrderQuantity')} />
        ),
        renderInfoRow(
          'Product Category',
          <span>{productCategoryLabel || ''}</span>
        ),
        renderInputRow(
          'Product Type',
          <Select
            placeholder="Types"
            options={
              PRODUCT_OPTIONS.find(
                (option) => option.value === productCategoryLabel
              )?.productTypes.map((type) => ({
                label: type.label,
                value: type.value,
              })) || []
            }
            onChange={(option: Option) => {
              setTypedFieldValue('productType', option);
            }}
            value={values.productType}
          />
        ),
        renderInputRow(
          'Form/Structure',
          <Select
            placeholder="Structures"
            options={
              Object.values(STRUCTURES).map((structure) => {
                return { label: structure, value: structure };
              }) || []
            }
            onChange={(option: Option) =>
              setTypedFieldValue('structure', option)
            }
            value={values.structure}
          />
        ),
        renderInputRow(
          'Opacity',
          <Select
            options={Object.values(OPACITIES).map((opacity) => {
              return { label: opacity, value: opacity };
            })}
            onChange={(option: Option) => setTypedFieldValue('opacity', option)}
            placeholder="Select a format"
            value={values.opacity}
          />
        ),
        renderInputRow(
          'Color Notes',
          <TextField {...getFieldProps('colorNote')} />
        ),
        renderInputRow(
          'Fragrance Notes',
          <TextField {...getFieldProps('fragranceNote')} />,
          true
        ),
      ],
    },
    sunCareSpecifics: {
      title: 'Sun Care Specifics',
      items: [
        renderInputRow('SPF Type', <TextField {...getFieldProps('spf')} />),
        renderInputRow(
          'Type of System',
          <Select
            onChange={(option: Option) =>
              setTypedFieldValue('typeOfSystem', option)
            }
            options={TYPE_OF_SYSTEM.map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            value={values.typeOfSystem}
          />
        ),
        renderInputRow(
          'Tinted',
          <Select
            onChange={(option: Option) => setTypedFieldValue('tinted', option)}
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={values.tinted}
          />,
          true
        ),
      ],
    },
    sunCareKeyBenefits: {
      title: 'Sun Care Key Benefits',
      items: [],
    },
    skinCareSpecifics: {
      title: 'Skin Care Specifics',
      items: [
        renderInputRow(
          'Rinse Off / Leave On',
          <Select
            onChange={(option: Option) =>
              setTypedFieldValue('rinseOffLeaveOn', option)
            }
            options={[
              { label: 'Rinse Off', value: 'rinseOff' },
              { label: 'Leave On', value: 'leaveOn' },
            ]}
            value={values.rinseOffLeaveOn}
          />
        ),
        renderInputRow(
          'Type of System',
          <Select
            onChange={(option: Option) =>
              setTypedFieldValue('typeOfSystem', option)
            }
            options={TYPE_OF_SYSTEM.map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            value={values.typeOfSystem}
          />,
          true
        ),
      ],
    },
    skinCareKeyBenefits: {
      title: 'Skin Care Key Benefits',
      items: [
        renderSwitchRow(
          'Brightening',
          <Switch
            isChecked={values.keyBenefitBrightening}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitBrightening',
                !values.keyBenefitBrightening
              )
            }
          />
        ),
        renderSwitchRow(
          'Firming',
          <Switch
            isChecked={values.keyBenefitFirming}
            handleChange={() =>
              setTypedFieldValue('keyBenefitFirming', !values.keyBenefitFirming)
            }
          />
        ),
        renderSwitchRow(
          'Hydration',
          <Switch
            isChecked={values.keyBenefitHydration}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitHydration',
                !values.keyBenefitHydration
              )
            }
          />
        ),
        renderSwitchRow(
          'Mattifying',
          <Switch
            isChecked={values.keyBenefitMattifying}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitMattifying',
                !values.keyBenefitMattifying
              )
            }
          />
        ),
        renderSwitchRow(
          'Moisturization',
          <Switch
            isChecked={values.keyBenefitMoisturization}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitMoisturization',
                !values.keyBenefitMoisturization
              )
            }
          />
        ),
        renderSwitchRow(
          'Radiance/glow',
          <Switch
            isChecked={values.keyBenefitRadianceGlow}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitRadianceGlow',
                !values.keyBenefitRadianceGlow
              )
            }
          />
        ),
        renderSwitchRow(
          'Self Tanner',
          <Switch
            isChecked={values.keyBenefitSelfTanner}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitSelfTanner',
                !values.keyBenefitSelfTanner
              )
            }
          />
        ),
        renderSwitchRow(
          'Smoothing',
          <Switch
            isChecked={values.keyBenefitSmoothing}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitSmoothing',
                !values.keyBenefitSmoothing
              )
            }
          />
        ),
        renderSwitchRow(
          'Tightening',
          <Switch
            isChecked={values.keyBenefitTightening}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitTightening',
                !values.keyBenefitTightening
              )
            }
          />
        ),
        renderSwitchRow(
          'Eyes: Reduce appearance of dark circles',
          <Switch
            isChecked={values.keyBenefitEyesReduceAppearanceOfDarkCircles}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitEyesReduceAppearanceOfDarkCircles',
                !values.keyBenefitEyesReduceAppearanceOfDarkCircles
              )
            }
          />
        ),
        renderSwitchRow(
          'Eyes: Reduce appearance of Fine line/crows feet',
          <Switch
            isChecked={
              values.keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet
            }
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet',
                !values.keyBenefitEyesReduceAppearanceOfFineLinesCrowsFeet
              )
            }
          />
        ),
        renderSwitchRow(
          'Eyes: Reduce appearance of puffiness',
          <Switch
            isChecked={values.keyBenefitEyesReduceAppearanceOfPuffiness}
            handleChange={() =>
              setTypedFieldValue(
                'keyBenefitEyesReduceAppearanceOfPuffiness',
                !values.keyBenefitEyesReduceAppearanceOfPuffiness
              )
            }
          />,
          true
        ),
      ],
    },
    packaging: {
      title: 'Packaging',
      items: [
        <div
          className={`flex gap-6 items-center py-[6px] ${'border-b border-grey-90'}`}
        >
          <span className="w-1/2 text-grey-50">Minimum Fill Size</span>
          <div className="flex w-1/2 gap-6 justify-between">
            <TextField placeholder="Quantity" {...getFieldProps('fillSize')} />
            <Select
              placeholder="Units"
              onChange={(option: Option) => setTypedFieldValue('unit', option)}
              options={Object.values(UNITS).map((unit) => ({
                label: unit,
                value: unit,
              }))}
              value={values.unit}
            />
          </div>
        </div>,
        renderInputRow(
          'Primary Component',
          <Select
            onChange={(option: Option) =>
              setTypedFieldValue('primaryComponent', option)
            }
            options={primaryComponentOptions}
            placeholder="Select"
            value={values.primaryComponent}
          />
        ),
        renderInputRow(
          'Material',
          <Select
            onChange={(option: Option) =>
              setTypedFieldValue('material', option)
            }
            options={Object.values(MATERIAL_MENU_ITEMS).map(
              (material) =>
                ({
                  label: material.label,
                  value: material.value,
                } as Option)
            )}
            placeholder="Select"
            value={values.material}
          />
        ),
        renderInputRow(
          'Closure',
          <Select
            onChange={(option: Option) => setTypedFieldValue('closure', option)}
            options={Object.values(CLOSURE_MENU_ITEMS).map(
              (closure) =>
                ({
                  label: closure.label,
                  value: closure.value,
                } as Option)
            )}
            placeholder="Select"
            value={values.closure}
          />
        ),
        renderInputRow(
          'Master Box',
          <Select
            onChange={(option: Option) => setFieldValue('masterBox', option)}
            options={MASTER_BOX_OPTIONS}
            placeholder="Select"
            value={values.masterBox}
          />,
          true
        ),
      ],
    },
    otc: {
      title: 'OTC',
      items: [
        renderSwitchRow(
          'Product qualifies as OTC',
          <Switch
            isChecked={values.otc}
            handleChange={() => setFieldValue('otc', !values.otc)}
          />,
          true
        ),
      ],
    },
    retailerClaims: {
      title: 'Retailer Claims',
      items: [
        renderSwitchRow(
          'Ulta Clean',
          <Switch
            isChecked={values.retailerClaimUltaClean}
            handleChange={() =>
              setFieldValue(
                'retailerClaimUltaClean',
                !values.retailerClaimUltaClean
              )
            }
          />
        ),
        renderSwitchRow(
          'Sephora',
          <Switch
            isChecked={values.retailerClaimSephoraClean}
            handleChange={() =>
              setFieldValue(
                'retailerClaimSephoraClean',
                !values.retailerClaimSephoraClean
              )
            }
          />
        ),
        renderSwitchRow(
          'Target Clean',
          <Switch
            isChecked={values.retailerClaimTargetClean}
            handleChange={() =>
              setFieldValue(
                'retailerClaimTargetClean',
                !values.retailerClaimTargetClean
              )
            }
          />
        ),
        renderSwitchRow(
          'Cyclic Silicone Free',
          <Switch
            isChecked={values.retailerClaimCyclicSiliconeFree}
            handleChange={() =>
              setFieldValue(
                'retailerClaimCyclicSiliconeFree',
                !values.retailerClaimCyclicSiliconeFree
              )
            }
          />,
          true
        ),
      ],
    },
    clinicalDataClaims: {
      title: 'Clinical/Data Claims',
      items: [
        renderSwitchRow(
          'Anti-Redness',
          <Switch
            isChecked={values.clinicalClaimAntiRedness}
            handleChange={() =>
              setFieldValue(
                'clinicalClaimAntiRedness',
                !values.clinicalClaimAntiRedness
              )
            }
          />
        ),
        renderSwitchRow(
          'Blue Light Protection',
          <Switch
            isChecked={values.clinicalClaimBlueLightProtection}
            handleChange={() =>
              setFieldValue(
                'clinicalClaimBlueLightProtection',
                !values.clinicalClaimBlueLightProtection
              )
            }
          />
        ),
        renderSwitchRow(
          'Gluten Free',
          <Switch
            isChecked={values.clinicalClaimGlutenFree}
            handleChange={() =>
              setFieldValue(
                'clinicalClaimGlutenFree',
                !values.clinicalClaimGlutenFree
              )
            }
          />
        ),
        renderSwitchRow(
          'Hydration (immediate and/or longterm',
          <Switch
            isChecked={values.clinicalClaimHydration}
            handleChange={() =>
              setFieldValue(
                'clinicalClaimHydration',
                !values.clinicalClaimHydration
              )
            }
          />
        ),
        renderSwitchRow(
          'Hypoallergenic',
          <Switch
            isChecked={values.clinicalClaimHypoallergenic}
            handleChange={() =>
              setFieldValue(
                'clinicalClaimHypoallergenic',
                !values.clinicalClaimHypoallergenic
              )
            }
          />
        ),
        renderSwitchRow(
          'No Animal Derived Ingredients',
          <Switch
            isChecked={values.clinicalClaimNoAnimalDerivedIngredients}
            handleChange={() =>
              setTypedFieldValue(
                'clinicalClaimNoAnimalDerivedIngredients',
                !values.clinicalClaimNoAnimalDerivedIngredients
              )
            }
          />
        ),
        renderSwitchRow(
          'No Animal Testing',
          <Switch
            isChecked={values.clinicalClaimNoAnimalTesting}
            handleChange={() =>
              setTypedFieldValue(
                'clinicalClaimNoAnimalTesting',
                !values.clinicalClaimNoAnimalTesting
              )
            }
          />
        ),
        renderSwitchRow(
          'Soothing',
          <Switch
            isChecked={values.clinicalClaimSoothing}
            handleChange={() =>
              setTypedFieldValue(
                'clinicalClaimSoothing',
                !values.clinicalClaimSoothing
              )
            }
          />
        ),
        renderSwitchRow(
          'Vegan',
          <Switch
            isChecked={values.clinicalClaimVegan}
            handleChange={() =>
              setTypedFieldValue(
                'clinicalClaimVegan',
                !values.clinicalClaimVegan
              )
            }
          />
        ),
        renderSwitchRow(
          'Non-OTC Claim Comments',
          <Switch
            isChecked={values.clinicalClaimNonOtcComment}
            handleChange={() =>
              setTypedFieldValue(
                'clinicalClaimNonOtcComment',
                !values.clinicalClaimNonOtcComment
              )
            }
          />,
          true
        ),
      ],
    },
    countryCompliance: {
      title: 'Country Compliance',
      items: [
        renderSwitchRow(
          'US',
          <Switch
            isChecked={values.countryComplianceUs}
            handleChange={() =>
              setTypedFieldValue(
                'countryComplianceUs',
                !values.countryComplianceUs
              )
            }
          />
        ),
        renderSwitchRow(
          'Canada',
          <Switch
            isChecked={values.countryComplianceCanada}
            handleChange={() =>
              setTypedFieldValue(
                'countryComplianceCanada',
                !values.countryComplianceCanada
              )
            }
          />
        ),
        renderSwitchRow(
          'EU',
          <Switch
            isChecked={values.countryComplianceEu}
            handleChange={() =>
              setTypedFieldValue(
                'countryComplianceEu',
                !values.countryComplianceEu
              )
            }
          />
        ),
        renderSwitchRow(
          'Other',
          <Switch
            isChecked={values.countryComplianceOther}
            handleChange={() =>
              setTypedFieldValue(
                'countryComplianceOther',
                !values.countryComplianceOther
              )
            }
          />
        ),
        renderInputRow(
          'Notes on other',
          <TextField {...getFieldProps('countryComplianceNote')} />,
          true
        ),
      ],
    },
    testing: {
      title: 'Testing',
      items: [
        renderSwitchRow(
          'In Vitro FDA Critical Wavelength (Broad Spectrum)',
          <Switch
            isChecked={values.testingInVitro}
            handleChange={() =>
              setTypedFieldValue('testingInVitro', !values.testingInVitro)
            }
          />
        ),
        renderSwitchRow(
          'Compantibility (Final Component)',
          <Switch
            isChecked={values.testingFinalComponent}
            handleChange={() =>
              setTypedFieldValue(
                'testingFinalComponent',
                !values.testingFinalComponent
              )
            }
          />
        ),
        renderSwitchRow(
          'Human Repeat Insult Patch Test (HRIPT)',
          <Switch
            isChecked={values.testingHript}
            handleChange={() =>
              setTypedFieldValue('testingHript', !values.testingHript)
            }
          />
        ),
        renderSwitchRow(
          'Human Repeat Insult Patch Test with Dermatologist Signature (HRIPT)',
          <Switch
            isChecked={values.testingHriptWithDermatologistSignature}
            handleChange={() =>
              setTypedFieldValue(
                'testingHriptWithDermatologistSignature',
                !values.testingHriptWithDermatologistSignature
              )
            }
          />
        ),
        renderSwitchRow(
          'Human Repeat Insult Patch Test with Hypoallergenic Claim (HRIPT)',
          <Switch
            isChecked={values.testingHriptWithHypoallergenicClaim}
            handleChange={() =>
              setTypedFieldValue(
                'testingHriptWithHypoallergenicClaim',
                !values.testingHriptWithHypoallergenicClaim
              )
            }
          />
        ),
        renderSwitchRow(
          'Ocular Irritation (In Vitro)',
          <Switch
            isChecked={values.testingOcularIrritation}
            handleChange={() =>
              setTypedFieldValue(
                'testingOcularIrritation',
                !values.testingOcularIrritation
              )
            }
          />
        ),
        renderSwitchRow(
          'Preservative Efficacy Test (PET)',
          <Switch
            isChecked={values.testingPet}
            handleChange={() =>
              setTypedFieldValue('testingPet', !values.testingPet)
            }
          />
        ),
        renderSwitchRow(
          'SPF Determination: FDA',
          <Switch
            isChecked={values.testingSpfDeterminationFda}
            handleChange={() =>
              setTypedFieldValue(
                'testingSpfDeterminationFda',
                !values.testingSpfDeterminationFda
              )
            }
          />
        ),
        renderSwitchRow(
          'SPF Determination: ISO',
          <Switch
            isChecked={values.testingSpfDeterminationIso}
            handleChange={() =>
              setTypedFieldValue(
                'testingSpfDeterminationIso',
                !values.testingSpfDeterminationIso
              )
            }
          />
        ),
        renderSwitchRow(
          'Stability (FT, 4C, 40C)',
          <Switch
            isChecked={values.testingStability}
            handleChange={() =>
              setTypedFieldValue('testingStability', !values.testingStability)
            }
          />
        ),
        renderSwitchRow(
          'Water Activity (Anhydrous)',
          <Switch
            isChecked={values.testingWaterActivity}
            handleChange={() =>
              setTypedFieldValue(
                'testingWaterActivity',
                !values.testingWaterActivity
              )
            }
          />,
          true
        ),
      ],
    },
    regulatory: {
      title: 'Regulatory',
      items: [
        renderSwitchRow(
          'Vegan Ingredients',
          <Switch
            isChecked={values.regulatoryVegan}
            handleChange={() =>
              setTypedFieldValue('regulatoryVegan', !values.regulatoryVegan)
            }
          />
        ),
        renderSwitchRow(
          'Gluten Free Ingredients',
          <Switch
            isChecked={values.regulatoryGlutenFree}
            handleChange={() =>
              setTypedFieldValue(
                'regulatoryGlutenFree',
                !values.regulatoryGlutenFree
              )
            }
          />
        ),
        renderSwitchRow(
          'No Animal Derived Ingredients',
          <Switch
            isChecked={values.regulatoryNoAnimalDerivedIngredients}
            handleChange={() =>
              setTypedFieldValue(
                'regulatoryNoAnimalDerivedIngredients',
                !values.regulatoryNoAnimalDerivedIngredients
              )
            }
          />
        ),
        renderSwitchRow(
          'No Animal Tested Ingredients',
          <Switch
            isChecked={values.regulatoryNoAnimalTesting}
            handleChange={() =>
              setTypedFieldValue(
                'regulatoryNoAnimalTesting',
                !values.regulatoryNoAnimalTesting
              )
            }
          />
        ),
        renderSwitchRow(
          'IFRA Compliance (International Fragrance Association)',
          <Switch
            isChecked={values.regulatoryIfraCompliant}
            handleChange={() =>
              setTypedFieldValue(
                'regulatoryIfraCompliant',
                !values.regulatoryIfraCompliant
              )
            }
          />,
          true
        ),
      ],
    },
    internal: {
      title: 'Internal',
      items: [
        renderInputRow(
          'Production Location',
          <Select
            placeholder="Select"
            options={[
              { label: 'Miami', value: 'Miami' },
              { label: 'Los Angeles', value: 'Los Angeles' },
            ]}
            onChange={(option: Option) =>
              setTypedFieldValue('productionLocation', option)
            }
            value={values.productionLocation}
          />,
          true
        ),
        renderInputRow(
          'PML Notes',
          <TextArea {...getFieldProps('internalNotes')} />,
          true
        ),
      ],
    },
  };

  return (
    <Page>
      <div className="col-span-12 flex gap-6 mt-16 mb-[200px] max-w-[1248px] mx-auto">
        <div className="flex flex-col">
          <img
            src={
              RTL_CATALOGUE_IMAGES.find((image) => image.id === values.imageId)
                ?.src || RTL_CATALOGUE_IMAGES[0].src
            }
            alt="selected formula"
            className="h-[406px] w-[406px] rounded"
          />
          <span>{formik.errors.imageId}</span>
          <span className="text-grey-50 mt-6">Select a featured image:</span>
          {renderFormulaImages()}
        </div>
        <div className="flex flex-col gap-9 grow px-6">
          <TextField
            required
            error={formik.errors.displayName}
            label="Formula Name"
            width={'w-[357px]'}
            placeholder="Enter a formula name"
            {...getFieldProps('displayName')}
          />
          <TextArea
            label="Formula Description"
            rows={6}
            placeholder="Describe this formula"
            {...getFieldProps('productDescription')}
          />
          <TextField
            error={formik.errors.price}
            label="Price per ounce (Required)"
            placeholder="00.00"
            leadingText="$"
            trailingText="per ounce"
            helperText="Enter the closest current estimate."
            {...getFieldProps('price')}
          />
          {renderFormSection(formItems['formulaDetails'])}
          {productCategoryValue === PRODUCT_CATEGORIES.SUN_CARE &&
            renderFormSection(formItems['sunCareSpecifics'])}
          {productCategoryValue === PRODUCT_CATEGORIES.SUN_CARE &&
            renderFormSection(formItems['sunCareKeyBenefits'])}
          {productCategoryValue === PRODUCT_CATEGORIES.SKIN_CARE &&
            renderFormSection(formItems['skinCareSpecifics'])}
          {productCategoryValue === PRODUCT_CATEGORIES.SKIN_CARE &&
            renderFormSection(formItems['skinCareKeyBenefits'])}
          {renderFormSection(formItems['packaging'])}
          {renderFormSection(formItems['otc'])}
          {renderFormSection(formItems['retailerClaims'])}
          {renderFormSection(formItems['clinicalDataClaims'])}
          {renderFormSection(formItems['countryCompliance'])}
          {renderFormSection(formItems['testing'])}
          {renderFormSection(formItems['regulatory'])}
          {renderFormSection(formItems['internal'])}
        </div>
      </div>
      {showSaveAsDraftModal && (
        <Modal handleClose={() => setShowSaveAsDraftModal(false)}>
          <div className="flex flex-col">
            <div className="flex flex-col gap-3 p-6">
              <span className="font-semibold">Save as draft?</span>
              <span>
                Drafts are visible to PML employees, but cannot be seen by
                customers
              </span>
              <span className="text-red-50 text-xs">
                {formik.errors.displayName}
              </span>
            </div>
            <div className="flex gap-4 p-4 border-t border-grey-90 justify-end">
              <Button
                size="large"
                type="secondary"
                text="Nevermind"
                onClick={() => setShowSaveAsDraftModal(false)}
              />
              <Button
                size="large"
                text="Save Draft"
                onClick={() =>
                  setFieldValue('status', 'draft', false).then(() => {
                    formik.handleSubmit();
                  })
                }
              />
            </div>
          </div>
        </Modal>
      )}
      <BottomBar
        additionalStyles={'-ml-[112px]'}
        buttons={[
          <Button
            size="large"
            text="Cancel"
            type="secondary"
            onClick={() => navigate(ROUTES.RTL_FORMULA_CATALOGUE.route)}
          />,
          <Button
            leadingIcon={<DocumentPlusIcon className="text-royal-50" />}
            onClick={() => setShowSaveAsDraftModal(true)}
            size="large"
            text="Save Draft"
            type="secondary"
          />,
          <Button
            size="large"
            text="Publish"
            onClick={() => {
              setFieldValue('status', 'published', false).then(() => {
                formik.handleSubmit();
              });
            }}
          />,
        ]}
      />
    </Page>
  );
};
