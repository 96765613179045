// Libraries
import React from 'react';
// Components
import { Button, Modal } from 'design-system';
// Utils
import { RtlShoppingCartContext } from 'context';

interface ConfirmClearCartModalProps {
  handleClose: () => void;
}

export const ConfirmClearCartModal: React.FC<ConfirmClearCartModalProps> = ({
  handleClose,
}) => {
  const { clearCart } = React.useContext(RtlShoppingCartContext);

  return (
    <Modal handleClose={() => handleClose()}>
      <div className="flex flex-col bg-white sm:min-w-[368px] rounded shadow-md">
        <div className="flex flex-col gap-3 p-6 border-b border-grey-90">
          <span className="font-semibold">Are you sure?</span>
          <span>This cannot be undone.</span>
        </div>
        <div className="flex p-4 justify-end gap-3">
          <Button
            text="Nevermind"
            type="secondary"
            onClick={() => handleClose()}
          />
          <Button
            text="Yes, clear cart"
            type="primary"
            onClick={() => {
              clearCart();
              handleClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
