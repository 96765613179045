// Libraries
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'motion/react';
import { XMarkIcon } from '@heroicons/react/16/solid';
// Components
import { Button } from 'design-system';
import { ConfirmClearCartModal } from '../confirm-clear-cart-modal.component';
// Utils
import { MobileContext, RtlShoppingCartContext } from 'context';
import { useRenderCartItems } from '../render-cart-items.hook';
// Constants
import { ROUTES } from 'features/navigation';

export const CosmoprofShoppingCart: React.FC = () => {
  const navigate = useNavigate();
  const { isCartOpen, setIsCartOpen } = useContext(RtlShoppingCartContext);
  const { numberOfItemsInCart } = useContext(RtlShoppingCartContext);
  const { isMobile } = useContext(MobileContext);
  const [isClearCartModalOpen, setIsClearCartModalOpen] = useState(false);

  const cartItems = useRenderCartItems();

  return (
    <>
      {isClearCartModalOpen && (
        <ConfirmClearCartModal
          handleClose={() => setIsClearCartModalOpen(false)}
        />
      )}
      <AnimatePresence mode="wait">
        {isCartOpen && (
          <div className="w-full h-full fixed top-0 left-0 z-[1200] bg-black bg-opacity-25">
            <motion.div
              layout
              initial={{ right: `${isMobile ? '-80%' : '-475px'}` }}
              animate={{ right: '0px' }}
              exit={{ right: `${isMobile ? '-80%' : '-475px'}` }}
              transition={{ duration: 0.3, ease: 'linear' }}
              className={`flex flex-col gap-4 fixed font-inter z-[1300] w-[80%] sm:w-[475px] h-full top-0 right-0 bg-grey-99 p-6 border border-grey-90`}
            >
              <div
                onClick={() => setIsCartOpen(false)}
                className="absolute p-1 cursor-pointer top-3 right-3"
              >
                <XMarkIcon className="h-5 w-5 text-grey-50" />
              </div>
              <span className="text-lg text-grey-20 font-semibold">
                Samples Cart
              </span>
              <span className="text-grey-50 text-sm">
                Formula samples to be shipped to a customer
              </span>
              <div className="flex flex-col gap-4 flex-1 overflow-y-auto no-scrollbar">
                {cartItems}
              </div>
              <div className="flex flex-col gap-4 py-6 border-t border-grey-20 mt-auto">
                <div className="flex justify-between items-center font-mono text-xs uppercase">
                  <span>{numberOfItemsInCart} items total</span>
                  <span
                    onClick={() => setIsClearCartModalOpen(true)}
                    className="text-grey-50 underline cursor-pointer"
                  >
                    Clear cart
                  </span>
                </div>
                <Button
                  onClick={() => {
                    numberOfItemsInCart > 0 &&
                      navigate(
                        ROUTES.RTL_FORMULA_CATALOGUE_SHIPPING_DETAILS.route
                      );
                    setIsCartOpen(false);
                  }}
                  size="large"
                  type="primary"
                  text="Proceed to shipping..."
                  width="w-full"
                />
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};
