// Libraries
import React, { useEffect, useState } from 'react';
import { Button, TextField, Tooltip, Typography } from 'design-system';
import {
  InformationCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import * as Yup from 'yup';
import { parse } from 'date-fns';
// Utils
import { useApi } from 'api';

interface FinalQuoteDueProps {
  projectId: string;
  finalQuoteDue?: Maybe<string | Date>;
  setProject: (project: any) => void;
}

export const FinalQuoteDue: React.FC<FinalQuoteDueProps> = ({
  projectId,
  finalQuoteDue,
  setProject,
}) => {
  const { patchProject } = useApi();
  const [quoteDate, setQuoteDate] = useState<Maybe<string | null>>(null);
  const [errors, setErrors] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (finalQuoteDue) {
      setQuoteDate(
        new Date(finalQuoteDue).toLocaleDateString('en-US', {
          timeZone: 'UTC',
        })
      );
    }
  }, [finalQuoteDue]);

  const handleSave = () => {
    const validationSchema = Yup.object().shape({
      quoteDate: Yup.date()
        .typeError('Must be a valid date: mm/dd/yyyy')
        .min(new Date(), 'Date is in the past')
        // validate that the date format is mm/dd/yyyy
        .test('is-valid-date', 'Must be a valid date: mm/dd/yyyy', (value) => {
          if (!value) return true;
          const date = parse(
            value.toLocaleDateString('en-US'),
            'MM/dd/yyyy',
            new Date()
          );
          return date instanceof Date && !isNaN(date.getTime());
        }),
    });

    validationSchema
      .validate({ quoteDate })
      .then(() => {
        setErrors(undefined);
        updateProject();
      })
      .catch((err) => {
        setErrors(err.errors);
      });
  };

  const updateProject = () => {
    setIsLoading(true);
    const date = new Date(quoteDate!);
    patchProject({
      urlParams: projectId,
      data: {
        project: {
          finalQuoteDue: date,
        },
      },
      handleSuccess: (data) => {
        setProject((prevProject: any) => ({
          ...prevProject,
          attributes: {
            ...prevProject.attributes,
            finalQuoteDue: data.attributes.finalQuoteDue,
          },
        }));
      },
      handleFinally: () => {
        setIsLoading(false);
        setIsEditing(false);
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <Typography variant="h6" font="mono" additionalStyles="text-grey-50">
        FINAL QUOTE DUE
      </Typography>

      {finalQuoteDue && !isEditing ? (
        <div className="flex flex-row gap-3">
          <Typography
            variant="h4"
            font="inter"
            weight="medium"
            color="primary"
            additionalStyles="text-grey-50"
          >
            {new Date(finalQuoteDue!).toLocaleDateString('en-US', {
              timeZone: 'UTC',
            })}
          </Typography>
          <PencilSquareIcon
            className="h-5 w-5 cursor-pointer text-royal-50 ml-2"
            onClick={() => setIsEditing(true)}
          />
        </div>
      ) : (
        <div className="flex flex-row items-start gap-2">
          <TextField
            placeHolder="00/00/0000"
            trailingIcon={
              <Tooltip
                title="This is the date by which approvals should be completed so that the quote can be shared with the customer."
                toolTipDirection="bottom"
              >
                <InformationCircleIcon
                  className="h-5 w-5"
                  color="text-grey-50"
                />
              </Tooltip>
            }
            value={quoteDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setQuoteDate(e.target.value)
            }
            error={errors}
          />
          <Button
            text="Save"
            type="primary"
            action={handleSave}
            additionalStyles="mt-1"
            disabled={isLoading}
          />
        </div>
      )}
    </div>
  );
};
