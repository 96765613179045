// Libraries
import React from 'react';
// Components
import { Checkbox } from 'design-system/checkbox.component';
// Utils
import { FeedbackAttributes, FeedbackStatus, IApiData } from 'api';

interface SectionHeaderProps {
  approval?: Maybe<IApiData<FeedbackAttributes>>;
  isApprover?: boolean;
  handleApprovalClick?: (approval: IApiData<FeedbackAttributes>) => void;
  title: string;
  checkboxLabel?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  approval,
  isApprover,
  handleApprovalClick,
  title,
  checkboxLabel,
}) => {
  return (
    <div
      className={`flex justify-between items-center rounded-t mt-3 px-3 py-2 bg-grey-20 text-xs text-grey-90`}
    >
      <span className="font-mono uppercase">{title}</span>
      {checkboxLabel && (
        <Checkbox
          defaultChecked={
            approval?.attributes?.status === FeedbackStatus.approved
          }
          disabled={
            !approval ||
            approval?.attributes?.status === FeedbackStatus.approved ||
            !isApprover
          }
          onClick={() =>
            handleApprovalClick && approval && handleApprovalClick(approval)
          }
          primaryLabel={checkboxLabel}
          primaryLabelProps={{ className: 'text-grey-90', size: 'sm' }}
        />
      )}
    </div>
  );
};
