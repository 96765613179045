// Libaries
import React from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/16/solid';
// Components
import { Typography } from 'design-system';

interface IncrementerDecrementerProps {
  setValue: React.Dispatch<React.SetStateAction<number>> | any;
  value: number;
}

export const IncrementerDecrementer: React.FC<IncrementerDecrementerProps> = ({
  setValue,
  value,
}) => {
  const increment = () => {
    setValue((value: number) => value + 1);
  };

  const decrement = () => {
    if (value > 1) {
      setValue((value: number) => value - 1);
    }
  };

  return (
    <div className="flex items-center gap-3 h-10 px-5 py-[2px] border border-black w-fit-content">
      <div className="cursor-pointer">
        <MinusIcon className="h-3 w-3" onClick={decrement} />
      </div>
      <Typography variant="h4">{value}</Typography>
      <div className="cursor-pointer">
        <PlusIcon className="h-3 w-3" onClick={increment} />
      </div>
    </div>
  );
};
