// Libraries
import * as Yup from 'yup';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from './price-quote.constants';

export const draftPriceQuoteValidationSchema = Yup.object().shape({
  [PRICE_QUOTE_FORM_KEYS.FORMULA_UUID]: Yup.string()
    .required('Please enter a valid formula number')
    .label('Formula Number'),
});
