// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowsRightLeftIcon,
  InformationCircleIcon,
} from '@heroicons/react/16/solid';
import { useFormikContext } from 'formik';
// Components
import { TextField, Tooltip } from 'design-system';
// Utils
import { IApiData, WorksheetIngredientAttributes } from 'api';
import { WorksheetFormValues } from '../../worksheet.types';
// Constants
import { WORKSHEET_FORM_KEYS } from '../../constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { IngredientSwapModal } from './ingredient-swap-modal.component';
import { IngredientFormValues } from './formula-section.types';
import { highlightInput } from '../../utils';

const ingredientNameCharacterLimit = 16;

export const IngredientsTable: React.FC = () => {
  const [ingredientToSwap, setIngredientToSwap] = React.useState<
    Maybe<IApiData<WorksheetIngredientAttributes>>
  >(undefined);

  const { getFieldProps, values, setFieldValue } = useFormikContext<
    WorksheetFormValues
  >();
  const { ingredients } = values;

  const getMoqDisplayUnit = ({
    attributes: { originalMoqUnit },
  }: IApiData<WorksheetIngredientAttributes>) => {
    if (originalMoqUnit === 'lb') {
      return '/lbs';
    }

    if (originalMoqUnit === 'kg') {
      return '/kgs';
    }

    if (originalMoqUnit === 'dollars') {
      return '/$';
    }

    // Default to lbs when blank
    return '/lbs';
  };

  return (
    <>
      <IngredientSwapModal
        handleClose={() => setIngredientToSwap(undefined)}
        worksheetIngredient={ingredientToSwap}
        handleSwap={(newIngredient: IngredientFormValues) => {
          setFieldValue(
            WORKSHEET_FORM_KEYS.INGREDIENTS,
            ingredients.map((ingredient) =>
              ingredient.id === ingredientToSwap?.id
                ? {
                    ...ingredientToSwap,
                    attributes: {
                      ...ingredientToSwap.attributes,
                      amount: newIngredient.amount,
                      name: newIngredient.rawMaterial.label,
                      rmUuid: newIngredient.rawMaterial.value,
                    },
                  }
                : ingredient
            )
          );
        }}
      />
      <div className="grid grid-cols-[12%_16%_11%_12%_14%_19%_16%] bg-grey-95 font-mono text-xs">
        <div className="px-3 py-[18px] border border-grey-90 flex flex-row justify-between items-center">
          RM CODE
          <Tooltip
            title="Raw Material Code. These are pulled directly from the formula. Purchasing can replace without formula being affected."
            toolTipDirection="bottom"
          >
            <InformationCircleIcon className="h-4 w-4" color="text-grey-50" />
          </Tooltip>
        </div>
        <div className="px-3 py-[18px] border border-grey-90 flex flex-row items-center">
          TRADE NAME
        </div>
        <div className="px-3 py-[18px] border border-grey-90 flex flex-row justify-between items-center">
          Weight %
          <Tooltip
            title="Percentage of RM's weight in overall formula."
            toolTipDirection="bottom"
          >
            <InformationCircleIcon className="h-4 w-4" color="text-grey-50" />
          </Tooltip>
        </div>
        <div className="px-3 py-[18px] border border-grey-90 flex flex-row justify-between items-center">
          PRICE/LB
          <Tooltip
            title="Price per pound of this specific RM code. Purchasing can replace without formula being affected."
            toolTipDirection="bottom"
          >
            <InformationCircleIcon className="h-4 w-4" color="text-grey-50" />
          </Tooltip>
        </div>
        <div className="px-3 py-[18px] border border-grey-90 flex flex-row items-center">
          MOQ
        </div>
        <div className="px-3 py-[18px] border border-grey-90 flex flex-row justify-between items-center">
          PRICE/LB OF FORMULA
          <Tooltip
            title="Price per pound of this specific RM code within this formula, based on metric in Price/LB column."
            toolTipDirection="bottom"
          >
            <InformationCircleIcon className="h-4 w-4" color="text-grey-50" />
          </Tooltip>
        </div>
        <div className="px-3 py-[18px] border border-grey-90 flex flex-row items-center">
          SUPPLIER
        </div>
      </div>
      <div className="grid grid-cols-[12%_16%_11%_12%_14%_19%_16%] white font-inter max-h-[395px] [&::-webkit-scrollbar]:hidden overflow-auto border border-t-0 border-l-0 border-r-0 border-b-grey-90">
        {ingredients.map(
          (
            ingredient: IApiData<WorksheetIngredientAttributes>,
            index: number
          ) => {
            return (
              <React.Fragment key={index}>
                <div className="flex justify-between items-center px-3 py-[18px] border-l border-b border-grey-90 bg-grey-99">
                  <Link
                    to={ROUTES.SHOW_RAW_MATERIAL.route.replace(
                      UUID_SHOW_ROUTE_STRING,
                      ingredient.attributes.rmUuid
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline cursor-pointer"
                  >
                    {ingredient.attributes.rmCode ||
                      ingredient.attributes.sampleCode}
                  </Link>
                  <ArrowsRightLeftIcon
                    className="h-4 w-4 text-royal-50 cursor-pointer"
                    onClick={() => setIngredientToSwap(ingredient)}
                  />
                </div>
                <div className="px-3 py-[18px] bg-grey-99 border-l border-b border-grey-90 ">
                  {ingredient.attributes.name.length >
                  ingredientNameCharacterLimit ? (
                    <Tooltip
                      title={ingredient.attributes.name}
                      toolTipDirection="bottom"
                    >
                      <div className="w-full text-ellipsis overflow-hidden">
                        <span className="whitespace-nowrap ">
                          {ingredient.attributes.name}
                        </span>
                      </div>
                    </Tooltip>
                  ) : (
                    <span className="whitespace-nowrap ">
                      {ingredient.attributes.name}
                    </span>
                  )}
                </div>
                <div className="px-3 py-[18px] bg-grey-99 border-l border-b border-grey-90">
                  <span>{ingredient.attributes.amount}</span>
                </div>
                <div className="px-3 py-2 bg-grey-99 border-l border-b border-grey-90">
                  <TextField
                    {...getFieldProps(
                      `${WORKSHEET_FORM_KEYS.INGREDIENTS}[${index}].attributes.defaultPrice`
                    )}
                    onFocus={highlightInput}
                    leadingText="$"
                    height="h-11"
                    type="number"
                  />
                </div>
                <div className="px-3 py-2 bg-grey-99 border-l border-b border-grey-90">
                  <TextField
                    {...getFieldProps(
                      `${WORKSHEET_FORM_KEYS.INGREDIENTS}[${index}].attributes.originalMoq`
                    )}
                    onFocus={highlightInput}
                    trailingText={getMoqDisplayUnit(ingredient)}
                    height="h-11"
                    type="number"
                  />
                </div>
                <div className="px-3 py-[18px] bg-grey-99 border-l border-b border-grey-90">
                  <span>
                    {ingredient.attributes.defaultPrice
                      ? `$${(
                          ingredient.attributes.defaultPrice *
                          (ingredient.attributes.amount / 100)
                        ).toFixed(4)}`
                      : 'N/A'}
                  </span>
                </div>
                <div className="px-3 py-2 bg-grey-99 border-l border-b border-r border-grey-90 text-ellipsis overflow-hidden">
                  <TextField
                    height="h-11"
                    onFocus={highlightInput}
                    {...getFieldProps(
                      `${WORKSHEET_FORM_KEYS.INGREDIENTS}[${index}].attributes.supplier`
                    )}
                  />
                </div>
              </React.Fragment>
            );
          }
        )}
      </div>
    </>
  );
};
