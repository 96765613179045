// Libraries
import React from 'react';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { AnimatePresence, motion } from 'motion/react';

interface FullscreenOverlayProps {
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
  isOpen: boolean;
}

export const FullscreenOverlay: React.FC<FullscreenOverlayProps> = ({
  children,
  onClose,
  isOpen,
}) => {
  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <motion.div
          layout
          initial={{ top: '100vh' }}
          animate={{ top: 0 }}
          exit={{ top: '100vh' }}
          transition={{ duration: 0.3, ease: 'linear' }}
          //  Left-nav is z-index 1250
          className={`overscroll-none overflow-auto flex flex-col fixed z-[1300] w-full h-full top-0 left-0 bg-white pb-56`}
        >
          <div className="fixed top-3 z-[1251] right-3 cursor-pointer">
            <XMarkIcon onClick={onClose} className="h-6 w-6 text-grey-50" />
          </div>
          <div className="pb-16">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
