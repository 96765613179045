// Libraries
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
// Utils
import { CustomerBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';
import { COMPLIANT_COUNTRIES } from '../form-modules/country-compliance.component';

interface RtlDetectorProps {
  filteredRtlFormulas: any[];
  setFilteredRtlFormulas: React.Dispatch<React.SetStateAction<any[]>>;
  setRtlFormulaUuid: React.Dispatch<React.SetStateAction<string | null>>;
  setProjectIsRtl: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RtlDetector: React.FC<RtlDetectorProps> = ({
  filteredRtlFormulas,
  setFilteredRtlFormulas,
  setRtlFormulaUuid,
  setProjectIsRtl,
}) => {
  const { values } = useFormikContext<CustomerBriefFormValues>();

  useEffect(() => {
    // We are RTL if the product category hasn't been selected yet.
    // We are also in RTL as long as there's a product type selected
    // and there are RTL formulas available.
    let isRtl = true;

    // For now, we will do an additional check here to confirm that the only country selected is the US.
    // Otherwise, we are not in RTL. This is preferential to adding a property to every RTL formula,
    // but we will need to adjust this when we have formulas that are RTL for multiple countries.
    const countries = values[BRIEF_FORM_KEYS.COUNTRIES];
    const rtlCountryCompliance = Boolean(
      countries.length === 0 ||
        (countries.length === 1 && countries.includes(COMPLIANT_COUNTRIES[0]))
    );

    // If we have RTL formulas and the country is not compliant, we are not in RTL.
    // so we must also set the filtered RTL formulas to an empty array,
    // and reset the selected RTL formula.
    if (!rtlCountryCompliance && filteredRtlFormulas.length) {
      setFilteredRtlFormulas([]);
      setRtlFormulaUuid(null);
    }

    if (!values[BRIEF_FORM_KEYS.PRODUCT_CATEGORY].value) {
      isRtl = true;
    } else {
      isRtl = Boolean(filteredRtlFormulas.length) && rtlCountryCompliance;
    }

    setProjectIsRtl(isRtl);
  }, [
    values,
    setProjectIsRtl,
    filteredRtlFormulas,
    setFilteredRtlFormulas,
    setRtlFormulaUuid,
  ]);

  return null;
};

export default RtlDetector;
