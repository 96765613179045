// Libraries
import React, { useEffect, useState } from 'react';
import { FormikHelpers } from 'formik';
import { Dialog, IconButton, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { LockClosedIcon, CheckCircleIcon } from '@heroicons/react/16/solid';
// Components
import { SampleFeedbackForm } from './sample-feedback-form.component';
// Utils
import {
  FeedbackAttributes,
  FormulaAttributes,
  IApiData,
  ProjectAttributes,
  SampleFeedbackAttributes,
  TaskAttributes,
  useApi,
} from 'api';
import { useSnackbar } from 'hooks';
import { SampleFeedbackFormValues } from './types';
import {
  FeedbackStatus,
  FeedbackType,
  TaskStatus,
} from 'features/home/customer-portal/types';
import { SampleFeedbackModalState } from '../types';
import { LegacySampleFeedbackForm } from './legacy-sample-feedback-form.component';
import { determineIfAllApproved } from './utils';
import { Select, Typography } from 'design-system';
// Constants
import {
  MILESTONES,
  MILESTONE_TITLES,
  TASK_TITLES,
} from 'features/home/customer-portal/constants';
import { FORMULA_STATUSES } from 'features/constants';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const rAndDMilestone = MILESTONES.find(
  (milestone) => milestone.title === MILESTONE_TITLES.R_AND_D_FORMULATION
);

const giveSampleFeedbackTask = rAndDMilestone?.tasks.find(
  (task) => task.title === TASK_TITLES.GIVE_FEEDBACK
);

const legacyOption: Option = {
  label: 'No formula number',
  value: 'legacy',
};

interface SampleFeedbackDialogProps {
  project: IApiData<ProjectAttributes> & {
    formulas: Maybe<IApiData<FormulaAttributes>[]>;
  };
  setProject: React.Dispatch<React.SetStateAction<IApiData<ProjectAttributes>>>;
  sampleFeedbackModalState: SampleFeedbackModalState;
  setSampleFeedbackModalState: React.Dispatch<
    React.SetStateAction<SampleFeedbackModalState>
  >;
}

export const SampleFeedbackDialog: React.FC<SampleFeedbackDialogProps> = ({
  project,
  setProject,
  sampleFeedbackModalState,
  setSampleFeedbackModalState,
}) => {
  const { isOpen, isViewOnly } = sampleFeedbackModalState;

  const { showSnackbar } = useSnackbar();
  const { postProjectFeedback, getProjectFeedback } = useApi();
  const [sampleFeedbacks, setSampleFeedbacks] = useState<
    IApiData<
      SampleFeedbackAttributes & { status: FeedbackAttributes['status'] }
    >[]
  >([]);
  const [legacySampleFeedbacks, setLegacySampleFeedbacks] = useState<
    IApiData<
      SampleFeedbackAttributes & { status: FeedbackAttributes['status'] }
    >[]
  >([]);

  const isTaskDone = project.parsedIncluded.tasks.find(
    (task: IApiData<TaskAttributes>) =>
      task.attributes.mondayColumnId === giveSampleFeedbackTask?.columnId &&
      task.attributes.status === TaskStatus.done
  );

  useEffect(() => {
    if (isOpen) {
      getProjectFeedback({
        urlParams: `${project.id}/feedbacks`,
        handleSuccess: ({ data }) => {
          const sampleFeedbacks = data
            .filter(
              (feedback: IApiData<FeedbackAttributes>) =>
                feedback.parsedIncluded.sampleFeedback
            )
            .map((feedback: IApiData<FeedbackAttributes>) => ({
              ...feedback.parsedIncluded.sampleFeedback,
              attributes: {
                ...feedback.parsedIncluded.sampleFeedback.attributes,
                status: feedback.attributes.status,
              },
            }));

          if (sampleFeedbacks.length) {
            setSampleFeedbacks(sampleFeedbacks);
          }

          // Legacy sample feedbacks are feedbacks that are not associated with a formula
          const legacySampleFeedbacks = data
            .filter(
              (feedback: IApiData<FeedbackAttributes>) =>
                feedback.parsedIncluded.sampleFeedback
            )
            .map((feedback: IApiData<FeedbackAttributes>) => ({
              ...feedback.parsedIncluded.sampleFeedback,
              attributes: {
                ...feedback.parsedIncluded.sampleFeedback.attributes,
                status: feedback.attributes.status,
              },
            }))
            .filter(
              (sampleFeedback: IApiData<SampleFeedbackAttributes>) =>
                !sampleFeedback.attributes.formulaUuid
            );

          if (legacySampleFeedbacks.length) {
            setLegacySampleFeedbacks(legacySampleFeedbacks);
          }
        },
      });
    }
  }, [isOpen, project.id, getProjectFeedback, isTaskDone, isViewOnly]);

  const handleFeedbackSubmit = (
    values: SampleFeedbackFormValues,
    { setSubmitting }: FormikHelpers<SampleFeedbackFormValues>
  ) => {
    const isAllApproved = determineIfAllApproved(values);

    postProjectFeedback({
      urlParams: `${project.id}/feedbacks`,
      data: {
        feedback: {
          feedbackType: FeedbackType.sample,
          status: isAllApproved
            ? FeedbackStatus.approved
            : FeedbackStatus.rejected,
          sampleFeedbackAttributes: {
            ...values,
          },
        },
      },
      handleSuccess: () => {
        setProject({
          ...project,
          parsedIncluded: {
            ...project.parsedIncluded,
            tasks: project.parsedIncluded.tasks.map(
              (task: IApiData<TaskAttributes>) =>
                task.attributes.mondayColumnId ===
                giveSampleFeedbackTask?.columnId
                  ? {
                      ...task,
                      attributes: {
                        ...task.attributes,
                        status: TaskStatus.done,
                      },
                    }
                  : task
            ),
          },
        });
        showSnackbar(
          `${isAllApproved ? 'Approval' : 'Feedback'} submitted successfully`,
          'success'
        );
        setSampleFeedbackModalState({
          isOpen: false,
          isViewOnly: false,
        });
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  const [selectedFormulaOption, setSelectedFormulaOption] = useState<
    Maybe<Option>
  >(undefined);

  const hasApprovedFeedback = sampleFeedbacks.some(
    (feedback) => feedback.attributes.status === FeedbackStatus.approved
  );

  const getIconFromStatus = (status: Maybe<FeedbackStatus>) => {
    switch (status) {
      case FeedbackStatus.approved:
        return (
          <CheckCircleIcon
            width={16}
            height={16}
            className="mr-2"
            color="#46B958"
          />
        );
      case FeedbackStatus.rejected:
        return (
          <LockClosedIcon
            width={16}
            height={16}
            className="mr-2"
            color="#6C7E93"
          />
        );
      default:
        // when one of the feedbacks is approved all formulas should have a lock icon,
        // except the one that is approved
        return hasApprovedFeedback ? (
          <LockClosedIcon
            width={16}
            height={16}
            className="mr-2"
            color="#6C7E93"
          />
        ) : null;
    }
  };

  const foundSampleFeedbacks = sampleFeedbacks.filter((feedback) => {
    return feedback.attributes.formulaUuid === selectedFormulaOption?.value;
  });

  const indexOfApprovedFeedback = foundSampleFeedbacks.findIndex(
    (feedback) => feedback.attributes.status === FeedbackStatus.approved
  );

  const foundSampleFeedback =
    foundSampleFeedbacks[indexOfApprovedFeedback] ||
    foundSampleFeedbacks[0] ||
    undefined;

  const hasLegacySampleFeedback = legacySampleFeedbacks.length > 0;
  const isDisplayingLegacySampleFeedback =
    selectedFormulaOption?.value === legacyOption.value;

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      open={isOpen}
      onClose={() =>
        setSampleFeedbackModalState({
          isOpen: false,
          isViewOnly: false,
        })
      }
    >
      <div className="flex justify-end sm:justify-center items-center h-full">
        <div className="w-11/12 sm:w-3/4 h-full mt-16 sm:mx-auto">
          <section className="flex mb-6 justify-between">
            <div>
              <Typography variant="h1">Product sample feedback</Typography>
              <h2 className="text-sm">
                If a characteristic is not relevant to your product, check N/A.
              </h2>
            </div>
            <div>
              <IconButton
                onClick={() =>
                  setSampleFeedbackModalState({
                    isOpen: false,
                    isViewOnly: false,
                  })
                }
              >
                <CloseIcon />
              </IconButton>
            </div>
          </section>

          <>
            <section>
              <div className="w-80">
                <Select
                  label="Formula #"
                  leadingIcon={
                    selectedFormulaOption &&
                    getIconFromStatus(
                      foundSampleFeedback?.attributes.status as any
                    )
                  }
                  options={
                    project.formulas
                      ?.filter(
                        (formula) =>
                          formula.attributes.status !==
                          FORMULA_STATUSES.INTERNAL_ONLY
                      )
                      ?.map((formula) => {
                        // There can be more than one feedback given for a formula
                        const formulaSampleFeedbacks = sampleFeedbacks.filter(
                          (sampleFeedback) => {
                            return (
                              sampleFeedback.attributes.formulaUuid ===
                              formula.id
                            );
                          }
                        );

                        // Find the index of the approved feedback
                        const indexOfApprovedFeedback = formulaSampleFeedbacks.findIndex(
                          (feedback) =>
                            feedback.attributes.status ===
                            FeedbackStatus.approved
                        );

                        // We want to display the approved feedback if there is one
                        const formulaSampleFeedback =
                          formulaSampleFeedbacks[indexOfApprovedFeedback] ||
                          formulaSampleFeedbacks[0];

                        return {
                          leadingIcon: getIconFromStatus(
                            formulaSampleFeedback?.attributes.status as any
                          ),
                          label: `${formula.attributes.externalId}`,
                          value: formula.id,
                        };
                      })
                      .concat(
                        // Optionally add the legacy option if there are legacy sample feedbacks
                        (hasLegacySampleFeedback ? [legacyOption] : []) as any
                      ) || []
                  }
                  value={selectedFormulaOption}
                  onChange={setSelectedFormulaOption}
                />
              </div>
            </section>
            {isDisplayingLegacySampleFeedback && (
              <LegacySampleFeedbackForm
                sampleFeedbacks={legacySampleFeedbacks}
              />
            )}
            {selectedFormulaOption && !isDisplayingLegacySampleFeedback && (
              <section className="font-mono pb-16 overflow-auto">
                <div className="min-w-[1000px]">
                  <div className="bg-darkGray text-white text-xs py-4 font-bold">
                    <div className="flex items-center ">
                      <h6 className="px-5 w-2/12 text-left">CHARACTERISTIC</h6>
                      <h6 className="px-5 w-1/12 text-center">APPROVED</h6>
                      <div className="px-3 w-1/12 text-center">
                        <h6 className="m-auto">NOT</h6>
                        <h6>APPROVED</h6>
                      </div>
                      <h6 className="px-5 w-1/12 text-center">N/A</h6>
                      <h6 className="px-5 w-7/12 text-left">NOTES</h6>
                    </div>
                  </div>
                  <SampleFeedbackForm
                    formulaId={selectedFormulaOption.value}
                    sampleFeedback={
                      sampleFeedbacks.find(
                        (feedback) =>
                          feedback.attributes.formulaUuid ===
                          selectedFormulaOption.value
                      ) || undefined
                    }
                    setSampleFeedbackModalState={setSampleFeedbackModalState}
                    handleFeedbackSubmit={handleFeedbackSubmit}
                    isReadOnly={
                      Boolean(foundSampleFeedback) || hasApprovedFeedback
                    }
                    hasApprovedFeedback={hasApprovedFeedback}
                  />
                </div>
              </section>
            )}
          </>
        </div>
      </div>
    </Dialog>
  );
};
