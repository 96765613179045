import { PriceQuoteFormValues } from './price-quote-modal/form.config';

export const PRICE_QUOTE_TYPE = {
  preliminary: {
    value: 'preliminary',
    title: 'Preliminary Price Quote',
  },
  final: {
    value: 'final',
    title: 'Final Price Quote',
  },
};

export const PRICE_SIZE_UNITS = [
  { label: 'g', value: 'g' },
  { label: 'oz', value: 'oz' },
  { label: 'ml', value: 'ml' },
];

export const MATERIAL_RESPONSIBILITIES = [
  { label: 'Raw Materials', value: 'RawMaterials' },
  { label: 'Containers', value: 'Containers' },
  { label: 'Decorations', value: 'Decorations' },
  { label: 'Closure', value: 'Closure' },
  { label: 'Master', value: 'Master' },
];

export const MATERIAL_RESPONSIBILITY_OPTIONS = {
  customer: 'customer',
  primeMatterLabs: 'pml',
};

export const PRICE_QUOTE_FORM_KEYS = {
  QUOTE_TYPE: 'quoteType',
  PERSONAL_NOTE: 'personalNote',
  QUOTE_VERSION_NUMBER: 'quoteVersionNumber',
  QUOTE_DATE: 'quoteDate',
  VALID_FOR: 'validFor',
  PAYMENT_TERMS: 'paymentTerms',
  INCOTERMS: 'incoterms',
  DELIVERY_TERMS: 'deliveryTerms',
  FORMULA_DESCRIPTION: 'formulaDescription',
  MOQ_ONE: 'moqOne',
  MOQ_TWO: 'moqTwo',
  MOQ_THREE: 'moqThree',
  SIZE_ONE_QUANTITY: 'sizeOneQuantity',
  SIZE_ONE_UNIT: 'sizeOneUnit',
  SIZE_ONE_DESCRIPTION: 'sizeOneDescription',
  SIZE_ONE_MOQ_ONE_TOTAL_COST_PER_UNIT: 'sizeOneMoqOneTotalCostPerUnit',
  SIZE_ONE_MOQ_TWO_TOTAL_COST_PER_UNIT: 'sizeOneMoqTwoTotalCostPerUnit',
  SIZE_ONE_MOQ_THREE_TOTAL_COST_PER_UNIT: 'sizeOneMoqThreeTotalCostPerUnit',
  SIZE_TWO_QUANTITY: 'sizeTwoQuantity',
  SIZE_TWO_UNIT: 'sizeTwoUnit',
  SIZE_TWO_DESCRIPTION: 'sizeTwoDescription',
  SIZE_TWO_MOQ_ONE_TOTAL_COST_PER_UNIT: 'sizeTwoMoqOneTotalCostPerUnit',
  SIZE_TWO_MOQ_TWO_TOTAL_COST_PER_UNIT: 'sizeTwoMoqTwoTotalCostPerUnit',
  SIZE_TWO_MOQ_THREE_TOTAL_COST_PER_UNIT: 'sizeTwoMoqThreeTotalCostPerUnit',
  SIZE_THREE_QUANTITY: 'sizeThreeQuantity',
  SIZE_THREE_UNIT: 'sizeThreeUnit',
  SIZE_THREE_DESCRIPTION: 'sizeThreeDescription',
  SIZE_THREE_MOQ_ONE_TOTAL_COST_PER_UNIT: 'sizeThreeMoqOneTotalCostPerUnit',
  SIZE_THREE_MOQ_TWO_TOTAL_COST_PER_UNIT: 'sizeThreeMoqTwoTotalCostPerUnit',
  SIZE_THREE_MOQ_THREE_TOTAL_COST_PER_UNIT: 'sizeThreeMoqThreeTotalCostPerUnit',
  PRICING_NOTES: 'pricingNotes',
  MATERIALS_NOTE: 'materialsNote',
  RAW_MATERIAL_RESPONSIBILITY: 'rawMaterialResponsibility',
  CONTAINERS_RESPONSIBILITY: 'containersResponsibility',
  DECORATION_RESPONSIBILITY: 'decorationResponsibility',
  CLOSURE_RESPONSIBILITY: 'closureResponsibility',
  MASTER_RESPONSIBILITY: 'masterResponsibility',
  FORMULA_UUID: 'formulaUuid',
} as const;

export const INITIAL_PRICE_QUOTE_FORM_VALUES: PriceQuoteFormValues = {
  [PRICE_QUOTE_FORM_KEYS.QUOTE_TYPE]: '',
  [PRICE_QUOTE_FORM_KEYS.PERSONAL_NOTE]: '',
  [PRICE_QUOTE_FORM_KEYS.QUOTE_VERSION_NUMBER]: '',
  [PRICE_QUOTE_FORM_KEYS.QUOTE_DATE]: new Date().toLocaleDateString('en-US'),
  [PRICE_QUOTE_FORM_KEYS.VALID_FOR]: undefined,
  [PRICE_QUOTE_FORM_KEYS.PAYMENT_TERMS]: '',
  [PRICE_QUOTE_FORM_KEYS.INCOTERMS]: '',
  [PRICE_QUOTE_FORM_KEYS.DELIVERY_TERMS]: '',
  [PRICE_QUOTE_FORM_KEYS.FORMULA_DESCRIPTION]: '',
  [PRICE_QUOTE_FORM_KEYS.MOQ_ONE]: undefined,
  [PRICE_QUOTE_FORM_KEYS.MOQ_TWO]: undefined,
  [PRICE_QUOTE_FORM_KEYS.MOQ_THREE]: undefined,
  [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_QUANTITY]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT]: {
    label: PRICE_SIZE_UNITS[1].label,
    value: PRICE_SIZE_UNITS[1].value,
  },
  [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_DESCRIPTION]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_ONE_TOTAL_COST_PER_UNIT]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_TWO_TOTAL_COST_PER_UNIT]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_THREE_TOTAL_COST_PER_UNIT]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_QUANTITY]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT]: {
    label: PRICE_SIZE_UNITS[1].label,
    value: PRICE_SIZE_UNITS[1].value,
  },
  [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_DESCRIPTION]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_MOQ_ONE_TOTAL_COST_PER_UNIT]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_MOQ_TWO_TOTAL_COST_PER_UNIT]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_MOQ_THREE_TOTAL_COST_PER_UNIT]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_THREE_QUANTITY]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_THREE_UNIT]: {
    label: PRICE_SIZE_UNITS[1].label,
    value: PRICE_SIZE_UNITS[1].value,
  },
  [PRICE_QUOTE_FORM_KEYS.SIZE_THREE_DESCRIPTION]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_THREE_MOQ_ONE_TOTAL_COST_PER_UNIT]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_THREE_MOQ_TWO_TOTAL_COST_PER_UNIT]: '',
  [PRICE_QUOTE_FORM_KEYS.SIZE_THREE_MOQ_THREE_TOTAL_COST_PER_UNIT]: '',
  [PRICE_QUOTE_FORM_KEYS.PRICING_NOTES]: '',
  [PRICE_QUOTE_FORM_KEYS.MATERIALS_NOTE]: '',
  [PRICE_QUOTE_FORM_KEYS.RAW_MATERIAL_RESPONSIBILITY]:
    MATERIAL_RESPONSIBILITY_OPTIONS.customer,
  [PRICE_QUOTE_FORM_KEYS.CONTAINERS_RESPONSIBILITY]:
    MATERIAL_RESPONSIBILITY_OPTIONS.customer,
  [PRICE_QUOTE_FORM_KEYS.DECORATION_RESPONSIBILITY]:
    MATERIAL_RESPONSIBILITY_OPTIONS.customer,
  [PRICE_QUOTE_FORM_KEYS.CLOSURE_RESPONSIBILITY]:
    MATERIAL_RESPONSIBILITY_OPTIONS.customer,
  [PRICE_QUOTE_FORM_KEYS.MASTER_RESPONSIBILITY]:
    MATERIAL_RESPONSIBILITY_OPTIONS.customer,
  [PRICE_QUOTE_FORM_KEYS.FORMULA_UUID]: '',
};

export const PRICE_QUOTE_TASK_STATUSES = {
  pending: 'Pending',
  done: 'Done',
  waived: 'Waived',
};

export const PRICE_QUOTE_STATUS = {
  draft: 'draft',
  inReview: 'inReview',
  rejected: 'rejected',
  approved: 'approved',
};
