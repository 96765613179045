import React from 'react';
import { motion, AnimatePresence } from 'motion/react';
// Components
import { SectionHeader } from '../../section-header.component';
import { FormulaPriceSummaryTable } from './formula-price-summary-table.component';
import { IngredientsTable } from './ingredients-table.component';
// Utils
import { SectionProps } from '../types';

export const FormulaSection: React.FC<SectionProps> = ({
  approval,
  costs,
  handleApprovalClick,
  isActive,
  isApprover,
  selectedSizeInOz,
}) => {
  return (
    <AnimatePresence mode="wait">
      {isActive && (
        <motion.div
          layout
          initial={{ height: 0, overflow: 'hidden' }}
          animate={{ height: 'auto', overflow: 'initial' }}
          exit={{ height: 0, overflow: 'hidden' }}
          transition={{ duration: 0.3, ease: 'linear' }}
          className={`${''} flex flex-col`}
        >
          <SectionHeader
            approval={approval}
            isApprover={isApprover}
            handleApprovalClick={handleApprovalClick}
            title="Formula"
            checkboxLabel="Purchasing Review Complete"
          />
          <div className="px-6 py-9 bg-white">
            <IngredientsTable />
            <FormulaPriceSummaryTable
              formulaCosts={costs}
              selectedSizeInOz={selectedSizeInOz}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
