// Libraries
import React, { useEffect, useState } from 'react';
// Components
import { LocalSearch } from './local-search.component';
// Utils
import { useApi } from 'api';
import { useFormikContext } from 'formik';
import { PriceQuoteFormValues } from '../../form.config';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from 'features/price-quote/price-quote.constants';

interface SearchFormulaProps {
  projectId?: string;
  editable: Maybe<boolean>;
}

export const SearchFormula: React.FC<SearchFormulaProps> = ({
  projectId,
  editable,
}) => {
  const { getProjectFormulas } = useApi();
  const { errors, setFieldValue, values } = useFormikContext<
    PriceQuoteFormValues
  >();
  // Local state
  const [formulaOptions, setFormulaOptions] = useState([]);

  useEffect(() => {
    if (!projectId) return;

    getProjectFormulas({
      urlParams: `${projectId}/formulas`,
      handleSuccess: (response) => {
        setFormulaOptions(
          response.data.map((option: any) => {
            return {
              label: `${option.attributes.externalId} (${option.attributes.name})`,
              value: option.id,
            };
          })
        );
      },
    });
  }, [projectId, getProjectFormulas]);

  return (
    <div>
      <LocalSearch
        clearField={() => {
          setFieldValue(PRICE_QUOTE_FORM_KEYS.FORMULA_UUID, '');
        }}
        label="Formula Number"
        options={formulaOptions}
        onChange={(option: Option) => {
          setFieldValue(PRICE_QUOTE_FORM_KEYS.FORMULA_UUID, option.value);
        }}
        error={errors.formulaUuid}
        value={values.formulaUuid}
        helperText={errors.formulaUuid}
        disabled
      />
    </div>
  );
};
