// Libraries
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// Components
import { Button, Page, Select, TextField } from 'design-system';
import { RtlFormulaCatalogueTopNav } from '../rtl-formula-catalogue-top-nav';
import { ConfirmClearCartModal } from '../confirm-clear-cart-modal.component';
// Utils
import { RtlShoppingCartContext } from 'context';
import { useRenderCartItems } from '../render-cart-items.hook';
import { useApi } from 'api';
// Constants
import { STATES } from 'features/brief/internal-brief/sample-recipients.component';
import { ROUTES } from 'features/navigation';

export const CosmoprofRtlFormulaCatalogueShippingDetails = () => {
  const navigate = useNavigate();
  const { postCosmoprofOrder } = useApi();
  const { clearCart, numberOfItemsInCart, rtlCart } = useContext(
    RtlShoppingCartContext
  );
  const cartItems = useRenderCartItems();
  const [isClearCartModalOpen, setIsClearCartModalOpen] = React.useState(false);

  type ShippingFormValues = {
    company: string;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    email: string;
  };

  const formik = useFormik<ShippingFormValues>({
    initialValues: {
      company: '',
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      email: '',
    },
    validationSchema: Yup.object({
      company: Yup.string().required('Please enter a company name'),
      firstName: Yup.string().required('Please enter a first name'),
      lastName: Yup.string().required('Please enter a last name'),
      addressLine1: Yup.string().required('Please enter a valid address'),
      city: Yup.string().required('Please enter a city'),
      state: Yup.string().required('Please select a state'),
      zip: Yup.string().required('Please enter a valid zip code'),
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter an email address'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      postCosmoprofOrder({
        data: {
          cosmoprofOrder: {
            company: values.company,
            firstName: values.firstName,
            lastName: values.lastName,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            state: values.state,
            zip: values.zip,
            email: values.email,
            rtlCatalogueEntries: rtlCart.map((cartItem) => ({
              uuid: cartItem.id,
              quantity: cartItem.quantity,
            })),
          },
        },
        handleSuccess: () => {
          const tempCart = [...rtlCart];

          clearCart();

          navigate(ROUTES.RTL_FORMULA_CATALOGUE_ORDER_CONFIRMATION.route, {
            state: {
              cart: tempCart,
              shippingInfo: {
                company: values.company,
                firstName: values.firstName,
                lastName: values.lastName,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                city: values.city,
                usState: values.state,
                zip: values.zip,
              },
            },
          });
        },
      });
    },
  });

  const { values, getFieldProps, setFieldValue } = formik;

  return (
    <Page>
      <RtlFormulaCatalogueTopNav hideShoppingCart />
      {isClearCartModalOpen && (
        <ConfirmClearCartModal
          handleClose={() => setIsClearCartModalOpen(false)}
        />
      )}

      <div className="col-span-12 flex flex-col sm:flex-row mt-24 gap-6 font-inter text-grey-20 max-w-[1264px] mx-auto">
        <div className="flex flex-col gap-3 sm:w-[58%]">
          <span>Sample Recipients</span>
          <div className="flex flex-col bg-white p-6 gap-6 border border-grey-90 rounded">
            <div>
              <TextField
                error={formik.errors.company}
                label="Company"
                width="w-full"
                {...getFieldProps('company')}
              />
            </div>
            <div className="flex gap-9">
              <TextField
                label="First Name"
                error={formik.errors.firstName}
                {...getFieldProps('firstName')}
              />
              <TextField
                error={formik.errors.lastName}
                label="Last Name"
                {...getFieldProps('lastName')}
              />
            </div>
            <div>
              <TextField
                error={formik.errors.addressLine1}
                label="Address Line 1"
                width="w-full"
                {...getFieldProps('addressLine1')}
              />
            </div>
            <div>
              <TextField
                label="Address Line 2"
                width="w-full"
                {...getFieldProps('addressLine2')}
              />
            </div>
            <div className="flex flex-col sm:flex-row gap-9">
              <div className="w-auto">
                <TextField
                  error={formik.errors.city}
                  label="City"
                  {...getFieldProps('city')}
                />
              </div>
              <div>
                <Select
                  error={formik.errors.state}
                  label="State"
                  options={STATES}
                  onChange={(option: Option) => {
                    setFieldValue('state', option.value);
                  }}
                  value={values.state}
                />
              </div>
              <div className="shrink">
                <TextField
                  error={formik.errors.zip}
                  label="Zip"
                  {...getFieldProps('zip')}
                />
              </div>
            </div>
            <TextField
              error={formik.errors.email}
              label="Email"
              type="email"
              {...getFieldProps('email')}
            />
          </div>
          <Button
            text="Submit"
            type="primary"
            width="w-full"
            onClick={formik.handleSubmit}
          />
          <span className="text-xs text-grey-50">
            When you click “Submit,” a request will be sent to R&D in Monday to
            assemble and ship your chosen samples to the above recipient. The
            customer will receive an email notification when the samples have
            shipped.
          </span>
        </div>
        <div className="flex flex-col gap-3 sm:w-[42%] pb-10">
          <span>Samples Cart</span>
          <div className="bg-grey-95 p-3 flex flex-col gap-3">{cartItems}</div>
          <div className="flex justify-between items-center font-mono text-xs uppercase">
            <span className="">{numberOfItemsInCart} items total</span>
            <span
              onClick={() => setIsClearCartModalOpen(true)}
              className="text-grey-50 underline cursor-pointer"
            >
              Clear cart
            </span>
          </div>
        </div>
      </div>
    </Page>
  );
};
