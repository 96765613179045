// Libraries
import React, { useEffect, useState } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusCircleIcon,
} from '@heroicons/react/16/solid';
import { useSearchParams } from 'react-router-dom';
// Components
import { Button } from 'design-system';
import { NewQuoteModal } from './new-quote-modal.component';
import { QuoteCard } from './quote-card.component';
import { WorksheetModal } from '../worksheet';
import { FinalQuoteDue } from './final-quote-due.component';
import { PriceQuoteModal } from '../price-quote-modal';
// Utils
import {
  FeedbackAttributes,
  FeedbackStatus,
  IApiData,
  PriceQuoteAttributes,
  PriceQuoteType,
  ProjectAttributes,
  useApi,
  WorksheetApiResponse,
} from 'api';
// Constants
import { PRICE_QUOTE_STATUS } from 'features/price-quote/price-quote.constants';

const WORKSHEET_PARAM = 'worksheet';

interface QuoteListProps {
  project: Maybe<IApiData<ProjectAttributes>>;
  setProject: (project: any) => void;
}

enum DialogType {
  NewQuoteModal = 'newQuoteModal',
  Worksheet = 'worksheet',
  Quote = 'quote',
}

export const QuoteList: React.FC<QuoteListProps> = ({
  project,
  setProject,
}) => {
  const { getProjectWorksheets, patchWorksheetFeedback } = useApi();
  const [worksheets, setWorksheets] = useState<WorksheetApiResponse[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [showApprovalAlert, setShowApprovalAlert] = useState(false);
  const [dialog, setDialog] = useState<Maybe<DialogType>>(undefined);
  const [searchParams] = useSearchParams();
  // This param is used to detect if we should open a specific worksheet
  const worksheetIdParam = searchParams.get(WORKSHEET_PARAM);

  useEffect(() => {
    if (worksheetIdParam) {
      setDialog(DialogType.Worksheet);
    }
  }, [worksheetIdParam]);

  const [worksheetId, setWorksheetId] = useState<Maybe<string>>(undefined);

  // Called after a worksheet approval/feedback network response in order to update the worksheet state
  // with the appropriate approval/feedback status
  const updateWorksheetWithApproval = (
    worksheetId: string,
    newApproval: IApiData<FeedbackAttributes>
  ) => {
    setWorksheets((worksheets) =>
      worksheets.map((worksheet) =>
        worksheet.id === worksheetId
          ? {
              ...worksheet,
              parsedIncluded: {
                ...worksheet.parsedIncluded,
                feedbacks: [
                  ...(worksheet?.parsedIncluded?.feedbacks?.map((feedback) =>
                    feedback.id === newApproval.id ? newApproval : feedback
                  ) || []),
                  ...(worksheet?.parsedIncluded?.feedbacks?.some(
                    (feedback) => feedback.id === newApproval.id
                  )
                    ? []
                    : [newApproval]),
                ],
              } as any,
            }
          : worksheet
      )
    );
  };

  const handleApprovalClick = (approval: IApiData<FeedbackAttributes>) => {
    const worksheetId = approval.relationships.feedbackable.data.id;
    patchWorksheetFeedback({
      urlParams: `${approval.relationships.feedbackable.data.id}/feedbacks/${approval.id}`,
      data: {
        feedback: {
          status: FeedbackStatus.approved,
        },
      },
      handleSuccess: (data) => {
        updateWorksheetWithApproval(worksheetId, data);
        setShowApprovalAlert(true);
      },
    });
  };

  useEffect(() => {
    getProjectWorksheets({
      urlParams: `${project?.id}/worksheets`,
      handleSuccess: ({ data }) => setWorksheets(data),
    });
  }, [getProjectWorksheets, project?.id]);

  const renderQuotes = (worksheets: WorksheetApiResponse[]) => {
    if (worksheets.length < 1) return;

    const sortedWorksheets = worksheets.sort((a, b) => {
      return (
        new Date(b.attributes.createdAt).getTime() -
        new Date(a.attributes.createdAt).getTime()
      );
    });

    return sortedWorksheets.map((worksheet: WorksheetApiResponse) => {
      return (
        <React.Fragment key={worksheet.id}>
          <QuoteCard
            updateWorksheetWithApproval={updateWorksheetWithApproval}
            setWorksheets={setWorksheets}
            worksheet={worksheet}
            project={project!}
            setWorksheetId={setWorksheetId}
            openPriceQuoteModal={() => setDialog(DialogType.Quote)}
            openWorksheetModal={() => setDialog(DialogType.Worksheet)}
          />
        </React.Fragment>
      );
    });
  };

  const worksheet = worksheets.find(
    (w) => w.id === worksheetId || w.id === worksheetIdParam
  )!;
  const priceQuote = worksheet?.parsedIncluded?.priceQuote;

  const isPriceQuoteEditable =
    !priceQuote ||
    priceQuote?.attributes.status === PRICE_QUOTE_STATUS.draft ||
    priceQuote?.attributes.status === PRICE_QUOTE_STATUS.rejected;

  return (
    <>
      <PriceQuoteModal
        isOpen={dialog === DialogType.Quote}
        isPriceQuoteEditable={isPriceQuoteEditable}
        projectId={project!.id}
        quoteType={priceQuote?.attributes.quoteType as PriceQuoteType}
        company={(project as any)?.company}
        closeQuoteModal={() => setDialog(undefined)}
        priceQuote={priceQuote}
        setWorksheet={(pq: IApiData<PriceQuoteAttributes>) => {
          setWorksheets((worksheets) =>
            worksheets.map((worksheet) =>
              worksheet.id === pq.relationships.worksheet.data.id
                ? ({
                    ...worksheet,
                    parsedIncluded: {
                      ...worksheet.parsedIncluded,
                      priceQuote: pq,
                    },
                  } as any)
                : worksheet
            )
          );
        }}
      />
      <WorksheetModal
        showApprovalAlert={showApprovalAlert}
        setShowApprovalAlert={setShowApprovalAlert}
        handleApprovalClick={handleApprovalClick}
        isPriceQuoteEditable={isPriceQuoteEditable}
        worksheet={worksheet}
        updateWorksheet={(worksheetData) => {
          setWorksheets((worksheets) =>
            worksheets.map((worksheet) =>
              worksheet.id === worksheetData.id
                ? ({
                    ...worksheet,
                    attributes: {
                      ...worksheet.attributes,
                      ...worksheetData.attributes,
                    },
                    parsedIncluded: {
                      ...worksheet.parsedIncluded,
                      formula: worksheetData.formula,
                      worksheetIngredients: worksheetData.worksheetIngredients,
                    },
                  } as any)
                : worksheet
            )
          );
        }}
        isOpen={dialog === DialogType.Worksheet}
        closeWorksheet={() => setDialog(undefined)}
        project={project}
        formula={worksheet?.parsedIncluded?.formula!}
      />
      <NewQuoteModal
        setWorksheets={setWorksheets}
        projectId={project!.id}
        isOpen={dialog === DialogType.NewQuoteModal}
        handleClose={() => setDialog(undefined)}
      />
      <div className="bg-grey-95 p-6 flex flex-col gap-9 font-inter">
        <div className="flex flex-row justify-between">
          <FinalQuoteDue
            projectId={project!.id}
            finalQuoteDue={project?.attributes?.finalQuoteDue}
            setProject={setProject}
          />

          <div className="flex sm:justify-end">
            <Button
              action={() => setDialog(DialogType.NewQuoteModal)}
              leadingIcon={<PlusCircleIcon />}
              size="large"
              text="New Quote"
            />
          </div>
        </div>
        {worksheets.length > 0 ? (
          <>
            {renderQuotes(
              worksheets.filter((w) => !w.attributes.quoteArchived)
            )}
            <div
              onClick={() => setShowArchived(!showArchived)}
              className="flex gap-1 items-center text-grey-50 cursor-pointer"
            >
              <span className="text-sm">
                {showArchived ? 'Hide' : 'Show'} archived
              </span>
              {showArchived ? (
                <>
                  <ChevronUpIcon className="h-4 w-4" />
                </>
              ) : (
                <ChevronDownIcon className="h-4 w-4" />
              )}
            </div>
            {showArchived &&
              renderQuotes(
                worksheets.filter((w) => w.attributes.quoteArchived)
              )}
          </>
        ) : (
          <div className="flex justify-center mt-[155px] font-inter">
            <div>
              This project has no quotes yet.{' '}
              <span
                className="underline cursor-pointer"
                onClick={() => setDialog(DialogType.NewQuoteModal)}
              >
                Create a new one
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
