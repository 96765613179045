// Libraries
import React, { useContext } from 'react';
import { TrashIcon } from '@heroicons/react/16/solid';
import { useLocation } from 'react-router-dom';
// Components
import { IncrementerDecrementer } from 'design-system';
// Utils
import { RtlShoppingCartContext } from 'context';
import { useAlert } from 'context/alert-context/alert-context';
import { RtlShoppingCartItem } from 'context/rtl-shopping-cart-context/rtl-shopping-cart.context';
// import { USDollar } from 'features/utils';
// Constants
import { RTL_CATALOGUE_IMAGES } from './constants';

export const useRenderCartItems = (isConfirmation?: boolean) => {
  const { rtlCart, addItem, removeItem } = useContext(RtlShoppingCartContext);
  const { showAlert, hideAlert } = useAlert();

  // If we're on the confirmation page, we want to use the copy of cart data that was
  // pushed into state, rather than the cart data from localStorage, which has
  // cleared at this point.
  const { state } = useLocation();
  const cartItems = isConfirmation && state?.cart ? state.cart : rtlCart;

  return cartItems.map((cartItem: RtlShoppingCartItem, index: number) => {
    return (
      <div
        key={`${cartItem.displayName}-${index}`}
        className="flex flex-col xl:flex-row w-full gap-6 p-3 rounded bg-white border border-grey-90"
      >
        <img
          alt="sample"
          className="w-full h-auto xl:max-w-[177px] max-h-[146px] rounded object-cover"
          src={
            RTL_CATALOGUE_IMAGES.find((image) => image.id === cartItem.imageId)
              ?.src
          }
        />
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-1 text-sm text-grey-50">
            <span className="text-sm font-semibold text-grey-20">
              {cartItem.displayName}
            </span>
            <span>{cartItem.structure}</span>
            <span>{cartItem.primaryContainer}</span>
          </div>
          <div className="flex items-center gap-4">
            {!isConfirmation ? (
              <IncrementerDecrementer
                value={cartItem.quantity}
                setValue={(functionToExecute: (value: number) => number) => {
                  const updatedCartItem = {
                    ...cartItem,
                    quantity: functionToExecute(cartItem.quantity),
                  };
                  addItem(updatedCartItem);
                }}
              />
            ) : (
              <div className="border border-grey-90 p-4 w-[30px] h-6 flex items-center justify-center">
                {cartItem.quantity}
              </div>
            )}
            {/* We will show dollar amounts to customer eventually */}
            {/* <span className="text-grey-50 text-sm">
              {USDollar.format(cartItem.price * cartItem.quantity)}
            </span> */}
          </div>
        </div>
        {!isConfirmation && (
          <div
            onClick={() => {
              showAlert({
                content: 'Formula removed from sample cart.',
                severity: 'info',
              });
              setTimeout(() => {
                hideAlert();
              }, 3000);
              removeItem(cartItem.id);
            }}
            className="flex w-full xl:justify-end p-1 cursor-pointer"
          >
            <TrashIcon className="w-4 h-4 text-grey-20" />
          </div>
        )}
      </div>
    );
  });
};
