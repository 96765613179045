// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { SectionHeader } from '../section-header.component';
import { TextField } from 'design-system';
// Utils
import { SectionProps } from './types';
import { WorksheetFormValues } from '../worksheet.types';
// Constants
import { laborCostInputs } from '../constants';
import { highlightInput } from '../utils';

export const LaborSection: React.FC<SectionProps> = ({
  approval,
  handleApprovalClick,
  isActive,
  isApprover,
  isPriceQuoteEditable,
}) => {
  const { getFieldProps } = useFormikContext<WorksheetFormValues>();

  return (
    <div
      className={`${isActive ? 'h-auto' : 'h-0 overflow-hidden'} flex flex-col`}
    >
      <SectionHeader
        approval={approval}
        isApprover={isApprover}
        handleApprovalClick={handleApprovalClick}
        title="Labor"
        checkboxLabel="Operations Review Complete"
      />
      <div className="px-3 bg-white">
        {laborCostInputs.map((laborCost: any, index: number) => {
          return (
            <div
              key={laborCost.title + index}
              className={`grid grid-cols-10 items-center min-h-[44px]
                ${index === 0 ? 'mt-4' : 'py-[6px]'}`}
            >
              <span className="col-span-2 text-grey-50">{laborCost.title}</span>
              {laborCost.formikKeys &&
                laborCost.formikKeys.map((formikKey: string, index: number) => {
                  const isSingleField = laborCost.formikKeys.length === 1;

                  return (
                    <div
                      key={formikKey + index}
                      className={
                        (isSingleField ? 'col-span-6' : 'col-span-2') + ' mr-6'
                      }
                    >
                      <TextField
                        type="number"
                        {...getFieldProps(formikKey)}
                        disabled={!isPriceQuoteEditable}
                        onFocus={highlightInput}
                        height="h-11"
                        leadingText={laborCost?.leadingText}
                        trailingText={laborCost?.trailingText}
                      />
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
