// Libraries
import React from 'react';
// Components
import { Divider } from 'design-system';
import { Benefits, ClinicalDataClaims } from '../form-modules';
// Utils

export const Claims: React.FC = () => {
  return (
    <>
      <Benefits />
      <Divider />
      <ClinicalDataClaims />
    </>
  );
};
