// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { RadioButton } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { PACKAGING_OPTION_TYPES } from '../brief.constants';

export const MASTER_BOX_OPTIONS = [
  { label: '6 Or Less', value: 'less_than_six' },
  { label: 'Greater than 6', value: 'six_or_greater' },
];

export const MasterBox: React.FC = () => {
  const { getFieldProps, setFieldValue, values } = useFormikContext<
    InternalBriefFormValues
  >();

  const renderOptions = () => {
    const optionName = PACKAGING_OPTION_TYPES.MASTER_BOX.name;

    return MASTER_BOX_OPTIONS.map((option: Option) => (
      <div key={option.value}>
        <RadioButton
          key={option.value}
          primaryLabel={option.label}
          {...getFieldProps(optionName)}
          onChange={() => setFieldValue(optionName, option.value)}
          checked={values[optionName] === option.value}
        />
      </div>
    ));
  };

  return (
    <div className="flex flex-col gap-3">
      <p className="font-inter text-base font-bold text-grey-20">Master Box</p>
      {renderOptions()}
    </div>
  );
};
