import React, { useState, useEffect } from 'react';
import { RtlShoppingCartContext } from './rtl-shopping-cart.context';
import { RtlShoppingCartItem } from './rtl-shopping-cart.context';
import { USDollar } from 'features/utils';

interface RtlShoppingCartContextProviderProps {
  children: React.ReactNode[] | React.ReactNode;
}

export const RtlShoppingCartContextProvider: React.FC<RtlShoppingCartContextProviderProps> = ({
  children,
}) => {
  const [rtlCart, setRtlCart] = useState(() => {
    // Initialize the cart from local storage
    const savedCart = localStorage.getItem('rtlCart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [isCartOpen, setIsCartOpen] = useState(false);

  // Sync the cart with local storage
  useEffect(() => {
    localStorage.setItem('rtlCart', JSON.stringify(rtlCart));
  }, [rtlCart]);

  // Cart constants
  const numberOfItemsInCart = rtlCart
    .map((cartItem: RtlShoppingCartItem) => cartItem!.quantity)
    .reduce((a: number, b: number) => a + b, 0);

  const totalPriceOfItemsInCart = USDollar.format(
    rtlCart
      .map(
        (cartItem: RtlShoppingCartItem) => cartItem!.price * cartItem!.quantity
      )
      .reduce((a: number, b: number) => a + b, 0)
  );

  // Cart functions
  const addItem = (item: RtlShoppingCartItem) => {
    if (
      rtlCart.some((cartItem: RtlShoppingCartItem) => cartItem.id === item.id)
    ) {
      setRtlCart((prevCart: RtlShoppingCartItem[]) =>
        prevCart.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: item.quantity + cartItem.quantity }
            : cartItem
        )
      );
      return;
    }
    setRtlCart((prevCart: RtlShoppingCartItem[]) => [...prevCart, item]);
  };

  const removeItem = (id: string) => {
    setRtlCart((prevCart: RtlShoppingCartItem[]) =>
      prevCart.filter((cartItem) => cartItem.id !== id)
    );
  };

  const clearCart = () => {
    setRtlCart([]);
  };

  return (
    <RtlShoppingCartContext.Provider
      value={{
        rtlCart,
        addItem,
        numberOfItemsInCart,
        totalPriceOfItemsInCart,
        removeItem,
        clearCart,
        isCartOpen,
        setIsCartOpen,
      }}
    >
      {children}
    </RtlShoppingCartContext.Provider>
  );
};
