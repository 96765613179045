// Libraries
import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';

interface CollapsibleSectionProps {
  initializeClosed?: boolean;
  title: string;
  children: React.ReactNode;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  initializeClosed,
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(initializeClosed ? false : true);

  return (
    <div
      className={`flex flex-col ${!isOpen && 'border-b border-grey-90'} pb-2`}
    >
      <div
        className="flex justify-between items-center cursor-pointer gap-1"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold font-inter text-base text-grey-20">
          {title}
        </span>
        {isOpen ? (
          <ChevronDownIcon className="w-5 h-5 text-royal-50" />
        ) : (
          <ChevronUpIcon className="w-5 h-5 text-royal-50" />
        )}
      </div>
      {isOpen && <div className="mt-2">{children}</div>}
    </div>
  );
};
