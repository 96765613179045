// Libraries
import React, { useEffect } from 'react';
import { Button, createStyles, makeStyles } from '@material-ui/core';
// Utils
import {
  FeedbackStatus,
  IApiData,
  ProjectAttributes,
  TaskAttributes,
  useApi,
} from 'api';
import { useSnackbar } from 'hooks';
import { FeedbackType, TaskStatus } from 'features/home/customer-portal/types';
// Constants
import {
  MILESTONE_TITLES,
  MILESTONES,
  TASK_TITLES,
} from 'features/home/customer-portal/constants';
import { ITheme } from 'styles/mui/themeV2';
import * as amplitude from '@amplitude/analytics-browser';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    btn: {
      backgroundColor: theme.palette.gray.dark,
    },
  })
);

const rAndDMilestone = MILESTONES.find(
  (milestone) => milestone.title === MILESTONE_TITLES.R_AND_D_FORMULATION
);

const giveSampleFeedbackTask = rAndDMilestone?.tasks.find(
  (task) => task.title === TASK_TITLES.GIVE_FEEDBACK
);

interface ApproveSampleProps {
  isApproved: boolean;
  setIsApproved: (isApproved: boolean) => void;
  project: IApiData<ProjectAttributes>;
  setProject: (project: any) => void;
  taskStatus: TaskStatus;
}

export const ApproveSample: React.FC<ApproveSampleProps> = ({
  isApproved,
  setIsApproved,
  project,
  setProject,
  taskStatus,
}) => {
  const { postProjectFeedback } = useApi();
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (taskStatus === TaskStatus.done) {
      setIsApproved(true);
    }
  }, [taskStatus, setIsApproved]);

  const handleApproveSample = () => {
    amplitude.track('RTL Sample Approval - Button - Approve Sample');
    setIsApproved(true);
    postProjectFeedback({
      urlParams: `${project.id}/feedbacks`,
      data: {
        feedback: {
          feedbackType: FeedbackType.sample,
          status: FeedbackStatus.approved,
        },
      },
      handleSuccess: () => {
        setProject({
          ...project,
          parsedIncluded: {
            ...project.parsedIncluded,
            tasks: project.parsedIncluded.tasks.map(
              (task: IApiData<TaskAttributes>) =>
                task.attributes.mondayColumnId ===
                giveSampleFeedbackTask?.columnId
                  ? {
                      ...task,
                      attributes: {
                        ...task.attributes,
                        status: TaskStatus.done,
                      },
                    }
                  : task
            ),
          },
        });
        showSnackbar('Approval submitted successfully', 'success');
      },
    });
  };

  return (
    <>
      <Button
        onClick={handleApproveSample}
        className={`w-1/2 sm:w-auto ${classes.btn}`}
        style={{ marginRight: '0.5rem' }}
        variant="text"
        disabled={isApproved}
      >
        {isApproved ? 'Approved' : 'Approve Sample'}
      </Button>
      {!isApproved && (
        <Button
          onClick={() =>
            amplitude.track(
              'RTL Sample Approval - Button - Not quite right? Contact us.'
            )
          }
          variant="outlined"
          color="secondary"
          target="_top"
          rel="noopener noreferrer"
          href={`mailto:help@primematterlabs.com`}
        >
          Not quite right? Contact us.
        </Button>
      )}
    </>
  );
};
