// Libraries
import React from 'react';
// Utils
import { USDollar } from 'features/utils';

interface FormulaPriceSummaryTableProps {
  formulaCosts: any;
  selectedSizeInOz?: number;
}

export const FormulaPriceSummaryTable: React.FC<FormulaPriceSummaryTableProps> = ({
  formulaCosts,
  selectedSizeInOz,
}) => {
  const {
    totalCostPerSizeAndUnit,
    totalCostPerPound,
    totalCostPerOunce,
  } = formulaCosts;
  return (
    <>
      <div className="mt-10 grid grid-cols-3 max-w-[500px] bg-grey-95 font-mono text-xs uppercase">
        <div className="px-3 py-[18px] border border-grey-90 "></div>
        <div className="px-3 py-[18px] border border-grey-90">Rounded Up</div>
        <div className="px-3 py-[18px] border border-grey-90">W / Decimals</div>
      </div>
      <div className="grid grid-cols-3 max-w-[500px] bg-white font-inter">
        <div className="px-3 py-[18px] border border-grey-90 ">Total / lb</div>
        <div className="px-3 py-[18px] border border-grey-90">
          {USDollar.format(totalCostPerPound)}
        </div>
        <div className="px-3 py-[18px] border border-grey-90">
          ${totalCostPerPound.toFixed(5)}
        </div>
      </div>
      <div className="grid grid-cols-3 max-w-[500px] bg-white font-inter">
        <div className="px-3 py-[18px] border border-grey-90 ">Total / oz</div>
        <div className="px-3 py-[18px] border border-grey-90">
          {USDollar.format(totalCostPerOunce)}
        </div>
        <div className="px-3 py-[18px] border border-grey-90">
          ${totalCostPerOunce.toFixed(5)}
        </div>
      </div>
      <div className="grid grid-cols-3 max-w-[500px] bg-white font-inter">
        <div className="px-3 py-[18px] border border-grey-90 ">
          Total / unit ({selectedSizeInOz || 0} oz)
        </div>
        <div className="px-3 py-[18px] border border-grey-90">
          {USDollar.format(totalCostPerSizeAndUnit)}
        </div>
        <div className="px-3 py-[18px] border border-grey-90">
          ${totalCostPerSizeAndUnit.toFixed(5)}
        </div>
      </div>
    </>
  );
};
