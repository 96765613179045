// Libraries
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShoppingCartIcon } from '@heroicons/react/16/solid';
// Utils
import { MobileContext, RtlShoppingCartContext } from 'context';
// Assets
import ReadyToLaunchFullLogo from 'img/ready-to-launch-full-logo.svg';
// Constants
import { ROUTES } from 'features/navigation';

interface RtlFormulaCatalogueTopNavProps {
  hideShoppingCart?: boolean;
}

export const RtlFormulaCatalogueTopNav: React.FC<RtlFormulaCatalogueTopNavProps> = ({
  hideShoppingCart,
}) => {
  const navigate = useNavigate();
  const { numberOfItemsInCart, setIsCartOpen } = useContext(
    RtlShoppingCartContext
  );
  const { isMobile } = useContext(MobileContext);

  return (
    <div className="sticky top-0 z-10 col-span-12 w-full max-w-[1440px] py-6 mx-auto bg-white text-grey-20 flex justify-between items-center pl-6 sm:pl-0">
      <div
        onClick={() => navigate(ROUTES.RTL_FORMULA_CATALOGUE.route)}
        className="flex gap-2 items-center cursor-pointer"
      >
        {!isMobile && (
          <img
            width="20"
            height="20"
            src={ReadyToLaunchFullLogo}
            alt="Ready to Launch logo"
          />
        )}
        <span className="sm:uppercase font-agipo">
          {isMobile ? 'RTL Library' : 'Ready to launch'}
        </span>
      </div>
      {!hideShoppingCart && (
        <div
          onClick={() => setIsCartOpen(true)}
          className="flex items-center gap-2 cursor-pointer"
        >
          <ShoppingCartIcon className="h-4 w-4" />
          <div className="font-mono uppercase text-xs">
            <span>Samples cart</span>
            <span className={`${numberOfItemsInCart > 0 && 'text-royal-50'}`}>
              {' '}
              {`(${numberOfItemsInCart})`}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
