// Libraries
import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
// Utils
import { IApiData, useApi, WorksheetAttributes } from 'api';
import {
  WorksheetFormValues,
  WorksheetUpdateResponse,
} from './worksheet.types';
import { useSnackbar } from 'hooks';
import { calculateAndSetCustomerCPUsAcrossAllSizes } from './utils';
// Constants
import { WORKSHEET_FORM_KEYS } from './constants';

const {
  ADDITIONAL_COMPONENT_ONE_A,
  ADDITIONAL_COMPONENT_ONE_B,
  ADDITIONAL_COMPONENT_ONE_C,
  ADDITIONAL_COMPONENT_THREE_A,
  ADDITIONAL_COMPONENT_THREE_B,
  ADDITIONAL_COMPONENT_THREE_C,
  ADDITIONAL_COMPONENT_TWO_A,
  ADDITIONAL_COMPONENT_TWO_B,
  ADDITIONAL_COMPONENT_TWO_C,
  BATCHING_HOURS_ONE,
  BATCHING_HOURS_THREE,
  BATCHING_HOURS_TWO,
  COMPOUNDING_HEAD_COUNT_ONE,
  COMPOUNDING_HEAD_COUNT_THREE,
  COMPOUNDING_HEAD_COUNT_TWO,
  COMPOUNDING_PAY_RATE_ONE,
  COMPOUNDING_PAY_RATE_THREE,
  COMPOUNDING_PAY_RATE_TWO,
  EFFICIENCY_PERCENTAGE_ONE,
  EFFICIENCY_PERCENTAGE_THREE,
  EFFICIENCY_PERCENTAGE_TWO,
  FILLING_HEAD_COUNT_ONE,
  FILLING_HEAD_COUNT_THREE,
  FILLING_HEAD_COUNT_TWO,
  FILLING_PAY_RATE_ONE,
  FILLING_PAY_RATE_THREE,
  FILLING_PAY_RATE_TWO,
  INGREDIENTS,
  MASTER_BOX_ONE,
  MASTER_BOX_THREE,
  MASTER_BOX_TWO,
  NUMBER_OF_BATCHES_ONE,
  NUMBER_OF_BATCHES_THREE,
  NUMBER_OF_BATCHES_TWO,
  ORDER_QUANTITY_ONE,
  ORDER_QUANTITY_THREE,
  ORDER_QUANTITY_TWO,
  PROFIT_ONE,
  PROFIT_THREE,
  PROFIT_TWO,
  RUN_RATE_ONE,
  RUN_RATE_THREE,
  RUN_RATE_TWO,
  SIZE_ONE_QUANTITY,
  SIZE_ONE_UNIT,
  SIZE_THREE_QUANTITY,
  SIZE_THREE_UNIT,
  SIZE_TWO_QUANTITY,
  SIZE_TWO_UNIT,
  TANK_SIZE,
  NOTES,
} = WORKSHEET_FORM_KEYS;

interface WorksheetFormProps {
  children: (initialValues: any) => React.ReactNode | React.ReactNode[];
  worksheet: IApiData<WorksheetAttributes>;
  updateWorksheet: (worksheetData: WorksheetUpdateResponse) => void;
}

export const WorksheetForm: React.FC<WorksheetFormProps> = ({
  children,
  worksheet,
  updateWorksheet,
}) => {
  const { showSnackbar } = useSnackbar();
  const { patchProjectWorksheet } = useApi();

  const initialValues = {
    [ADDITIONAL_COMPONENT_ONE_A]:
      worksheet.attributes.additionalComponentOneA || 0,
    [ADDITIONAL_COMPONENT_ONE_B]:
      worksheet.attributes.additionalComponentOneB || 0,
    [ADDITIONAL_COMPONENT_ONE_C]:
      worksheet.attributes.additionalComponentOneC || 0,
    [ADDITIONAL_COMPONENT_THREE_A]:
      worksheet.attributes.additionalComponentThreeA || 0,
    [ADDITIONAL_COMPONENT_THREE_B]:
      worksheet.attributes.additionalComponentThreeB || 0,
    [ADDITIONAL_COMPONENT_THREE_C]:
      worksheet.attributes.additionalComponentThreeC || 0,
    [ADDITIONAL_COMPONENT_TWO_A]:
      worksheet.attributes.additionalComponentTwoA || 0,
    [ADDITIONAL_COMPONENT_TWO_B]:
      worksheet.attributes.additionalComponentTwoB || 0,
    [ADDITIONAL_COMPONENT_TWO_C]:
      worksheet.attributes.additionalComponentTwoC || 0,
    [BATCHING_HOURS_ONE]: worksheet.attributes.batchingHoursOne || 0,
    [BATCHING_HOURS_THREE]: worksheet.attributes.batchingHoursThree || 0,
    [BATCHING_HOURS_TWO]: worksheet.attributes.batchingHoursTwo || 0,
    [COMPOUNDING_HEAD_COUNT_ONE]:
      worksheet.attributes.compoundingHeadCountOne || 0,
    [COMPOUNDING_HEAD_COUNT_THREE]:
      worksheet.attributes.compoundingHeadCountThree || 0,
    [COMPOUNDING_HEAD_COUNT_TWO]:
      worksheet.attributes.compoundingHeadCountTwo || 0,
    [COMPOUNDING_PAY_RATE_ONE]: worksheet.attributes.compoundingPayRateOne || 0,
    [COMPOUNDING_PAY_RATE_THREE]:
      worksheet.attributes.compoundingPayRateThree || 0,
    [COMPOUNDING_PAY_RATE_TWO]: worksheet.attributes.compoundingPayRateTwo || 0,
    [EFFICIENCY_PERCENTAGE_ONE]:
      worksheet.attributes.efficiencyPercentageOne || 0,
    [EFFICIENCY_PERCENTAGE_THREE]:
      worksheet.attributes.efficiencyPercentageThree || 0,
    [EFFICIENCY_PERCENTAGE_TWO]:
      worksheet.attributes.efficiencyPercentageTwo || 0,
    [FILLING_HEAD_COUNT_ONE]: worksheet.attributes.fillingHeadCountOne || 0,
    [FILLING_HEAD_COUNT_THREE]: worksheet.attributes.fillingHeadCountThree || 0,
    [FILLING_HEAD_COUNT_TWO]: worksheet.attributes.fillingHeadCountTwo || 0,
    [FILLING_PAY_RATE_ONE]: worksheet.attributes.fillingPayRateOne || 0,
    [FILLING_PAY_RATE_THREE]: worksheet.attributes.fillingPayRateThree || 0,
    [FILLING_PAY_RATE_TWO]: worksheet.attributes.fillingPayRateTwo || 0,
    [INGREDIENTS]: worksheet.parsedIncluded.worksheetIngredients || [],
    [MASTER_BOX_ONE]: worksheet.attributes.masterBoxOne || 0,
    [MASTER_BOX_THREE]: worksheet.attributes.masterBoxThree || 0,
    [MASTER_BOX_TWO]: worksheet.attributes.masterBoxTwo || 0,
    [NUMBER_OF_BATCHES_ONE]: worksheet.attributes.numberOfBatchesOne || 0,
    [NUMBER_OF_BATCHES_THREE]: worksheet.attributes.numberOfBatchesThree || 0,
    [NUMBER_OF_BATCHES_TWO]: worksheet.attributes.numberOfBatchesTwo || 0,
    [ORDER_QUANTITY_ONE]: worksheet.attributes.orderQuantityOne || 0,
    [ORDER_QUANTITY_THREE]: worksheet.attributes.orderQuantityThree || 0,
    [ORDER_QUANTITY_TWO]: worksheet.attributes.orderQuantityTwo || 0,
    [PROFIT_ONE]: worksheet.attributes.profitOne || 0,
    [PROFIT_THREE]: worksheet.attributes.profitThree || 0,
    [PROFIT_TWO]: worksheet.attributes.profitTwo || 0,
    [RUN_RATE_ONE]: worksheet.attributes.runRateOne || 0,
    [RUN_RATE_THREE]: worksheet.attributes.runRateThree || 0,
    [RUN_RATE_TWO]: worksheet.attributes.runRateTwo || 0,
    [SIZE_ONE_UNIT]: worksheet.attributes.sizeOneUnit || '',
    [SIZE_ONE_QUANTITY]: worksheet.attributes.sizeOneQuantity || 0,
    [SIZE_TWO_UNIT]: worksheet.attributes.sizeTwoUnit || '',
    [SIZE_TWO_QUANTITY]: worksheet.attributes.sizeTwoQuantity || 0,
    [SIZE_THREE_UNIT]: worksheet.attributes.sizeThreeUnit || '',
    [SIZE_THREE_QUANTITY]: worksheet.attributes.sizeThreeQuantity || 0,
    [TANK_SIZE]: worksheet.attributes.tankSize || 0,
    [NOTES]: worksheet.attributes.notes || '',
  };

  const handleSubmit = (values: WorksheetFormValues) => {
    const worksheetData = _.omit(values, [INGREDIENTS]);
    const [
      sizeOneCustomerCPUs,
      sizeTwoCustomerCPUs,
      sizeThreeCustomerCPUs,
    ] = calculateAndSetCustomerCPUsAcrossAllSizes(values);
    const { ingredients } = values;
    const worksheetIngredients = ingredients.map(
      (ingredient) => ingredient.attributes
    );

    patchProjectWorksheet({
      urlParams: `/${worksheet.relationships.project.data.id}/worksheets/${worksheet.id}`,
      data: {
        worksheet: {
          ...worksheetData,
          ...sizeOneCustomerCPUs,
          ...sizeTwoCustomerCPUs,
          ...sizeThreeCustomerCPUs,
          worksheetIngredients,
        },
      },
      handleSuccess: (data: WorksheetUpdateResponse) => {
        showSnackbar('Worksheet updated successfully', 'success');
        updateWorksheet(data);
      },
    });
  };

  return (
    <Formik
      initialValues={(initialValues as unknown) as WorksheetFormValues}
      onSubmit={handleSubmit}
    >
      {children(initialValues)}
    </Formik>
  );
};
