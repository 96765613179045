// Libraries
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
import { AnimatePresence, motion } from 'motion/react';
// Components
import { Button, Typography } from 'design-system';
//Utils
import { USDollar } from 'features/utils';
// Constants
import { WorksheetFormValues } from './worksheet.types';

interface CostSummaryProps {
  closeWorksheet: () => void;
  contributionsPerRun: number[];
  totalExcessRaws: number[];
  laborCosts: any;
  costs: any[];
  onSubmit: () => void;
  profitTwo: Maybe<number>;
  profitThree: Maybe<number>;
}

export const CostSummary: React.FC<CostSummaryProps> = ({
  closeWorksheet,
  contributionsPerRun,
  totalExcessRaws,
  laborCosts,
  costs,
  onSubmit,
  profitThree,
  profitTwo,
}) => {
  const { values } = useFormikContext<WorksheetFormValues>();
  const [isExpanded, setIsExpanded] = useState(false);
  const { profitOne } = values;

  const cpuOne = costs[0].totalCost + profitOne || 0;
  const cpuTwo = costs[1].totalCost + profitTwo || 0;
  const cpuThree = costs[2].totalCost + profitThree || 0;

  return (
    <motion.div
      layoutRoot
      initial={{ bottom: '-100vh' }}
      animate={{ bottom: 0 }}
      exit={{ bottom: '-100vh' }}
      transition={{ duration: 0.3, ease: 'linear' }}
      className="fixed bottom-0 w-full"
    >
      <div className="bg-grey-20 ">
        <div className="w-full max-w-[1440px] mx-auto">
          <div className="grid grid-cols-12 flex-col font-inter ml-16 mr-16 gap-x-6">
            <div className="col-start-2 col-span-10">
              <div className="grid grid-cols-10 items-center px-3 py-4 text-grey-90 border-b border-grey-70">
                <div className="flex items-center col-span-2">
                  <span className="text-grey-70">
                    {isExpanded ? 'Customer Cost per Unit' : 'Customer CPU'}
                  </span>
                </div>
                <span className="col-span-2 font-medium">
                  {USDollar.format(cpuOne)}
                </span>
                <span className="col-span-2 font-medium">
                  {USDollar.format(cpuTwo)}
                </span>
                <span className="col-span-2 font-medium">
                  {USDollar.format(cpuThree)}
                </span>
                <div
                  className="flex flex-row items-center col-span-2 cursor-pointer"
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  <Typography
                    variant="h4"
                    className="text-grey-70"
                    font="inter"
                  >
                    {isExpanded ? "Hide Add'l Details" : "Show Add'l Details"}
                  </Typography>
                  {isExpanded ? (
                    <ChevronUpIcon className="w-5 h-5 ml-2 text-royal-70" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5 ml-2 text-royal-70" />
                  )}
                </div>
              </div>
              <AnimatePresence mode="wait">
                {isExpanded && (
                  <motion.div
                    layout
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    transition={{ duration: 0.2, ease: 'linear' }}
                  >
                    <div className="grid grid-cols-10 items-center px-3 py-4 text-grey-90 border-b border-grey-70">
                      <span className="col-span-2 text-grey-70">
                        Unit Output
                      </span>
                      {laborCosts.map(
                        ({ unitOutput }: { unitOutput: number }) => {
                          return (
                            <span className="col-span-2">
                              {unitOutput.toLocaleString()}
                            </span>
                          );
                        }
                      )}
                    </div>
                    <div className="grid grid-cols-10 items-center px-3 py-4 text-grey-90 border-b border-grey-70">
                      <span className="col-span-2 text-grey-70">
                        Contribution per Run
                      </span>
                      {contributionsPerRun.map((contributionPerRun) => {
                        return (
                          <span className="col-span-2">
                            {USDollar.format(contributionPerRun)}
                          </span>
                        );
                      })}
                    </div>
                    <div className="grid grid-cols-10 items-center px-3 py-4 text-grey-90">
                      <span className="col-span-2 text-grey-70">
                        Total Excess Raws (Est.)
                      </span>
                      {totalExcessRaws.map((totalExcessRawMaterials) => {
                        return (
                          <span className="col-span-2">
                            {USDollar.format(totalExcessRawMaterials)}
                          </span>
                        );
                      })}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-10 p-2 pb-10 flex justify-end bg-white gap-3 font-inter">
        <Button type="secondary" text="Cancel" onClick={closeWorksheet} />
        <Button
          type="primary"
          text="Save"
          onClick={onSubmit}
          additionalStyles="border-0"
        />
      </div>
    </motion.div>
  );
};
