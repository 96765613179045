// Libraries
import React from 'react';
// Utils
import { Task, TaskStatus } from 'features/home/customer-portal/types';
// Constants
import * as Constants from '../../../../constants';

interface ApprovePrelimPriceQuoteActionsProps {
  priceQuoteType: string;
  task: Task;
  handleViewApprovePriceQuote: (value: string) => void;
}

export const ApprovePriceQuoteActions: React.FC<ApprovePrelimPriceQuoteActionsProps> = ({
  priceQuoteType,
  task,
  handleViewApprovePriceQuote,
}) => {
  if (priceQuoteType === Constants.PRICE_QUOTE_TYPES.preliminary) {
    if (task.status === TaskStatus.pending || task.status === TaskStatus.done) {
      return (
        <button
          onClick={() =>
            handleViewApprovePriceQuote(Constants.PRICE_QUOTE_TYPES.preliminary)
          }
          className="w-full sm:w-auto font-inter text-sm text-darkGray mr-2 border py-2 px-3 rounded-[4px]"
        >
          View Price Quote
        </button>
      );
    }
  }

  if (priceQuoteType === Constants.PRICE_QUOTE_TYPES.final) {
    if (task.status) {
      return (
        <button
          onClick={() =>
            handleViewApprovePriceQuote(Constants.PRICE_QUOTE_TYPES.final)
          }
          className="w-full sm:w-auto font-inter text-sm text-darkGray mr-2 border py-2 px-3 rounded-[4px]"
        >
          View Price Quote
        </button>
      );
    }
  }

  return null;
};
