// Libraries
import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/16/solid';
import {
  Alert,
  BottomBar,
  Button,
  Checkbox,
  Divider,
  RadioButton,
  Select,
  TextArea,
  Typography,
} from 'design-system';
import { LockClosedIcon, CheckCircleIcon } from '@heroicons/react/16/solid';
// Assets
import { ReactComponent as PmLogo } from '../../../../../../img/pm-logo-black.svg';
// Utils
import {
  FormulaAttributes,
  IApiData,
  PriceQuoteAttributes,
  PriceQuoteStatus,
  PriceQuoteType,
  ProjectAttributes,
  TaskAttributes,
  useApi,
} from 'api';
import { MATERIAL_RESPONSIBILITY_OPTIONS } from 'features/price-quote/price-quote.constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'hooks';
// Constants
import * as Constants from '../../../constants';
import { TaskStatus } from 'features/home/customer-portal/types';

const FORM_KEYS = {
  STATUS: 'status',
  FEEDBACK_NOTE: 'feedbackNote',
};

interface ApprovePriceQuoteModalProps {
  project: IApiData<ProjectAttributes>;
  priceQuotes: Maybe<IApiData<PriceQuoteAttributes>[]>;
  quoteType: string;
  companyName: string;
  handleClose: () => void;
  setProject: React.Dispatch<React.SetStateAction<IApiData<ProjectAttributes>>>;
}

const {
  PRELIM_QUOTE_APPROVED,
  FINAL_QUOTE_APPROVED,
} = Constants.TASK_COLUMN_IDS;

export const ApprovePriceQuoteModal: React.FC<ApprovePriceQuoteModalProps> = ({
  project,
  priceQuotes,
  quoteType,
  companyName,
  handleClose,
  setProject,
}) => {
  const projectId = project.id;

  // Get the latest price quote that is in_review
  const [priceQuote, setPriceQuote] = useState<
    Maybe<IApiData<PriceQuoteAttributes>>
  >(
    // Find the first price quote that is approved
    priceQuotes?.find(
      (quote) => quote.attributes.status === PriceQuoteStatus.approved
    ) ||
      // Or find the first price quote that is in_review
      priceQuotes?.find(
        (quote) => quote.attributes.status === PriceQuoteStatus.in_review
      ) ||
      // Or find the first price quote
      priceQuotes?.[0]
  );

  const handleSelectPriceQuote = (selectedQuote: Option) => {
    setPriceQuote(
      priceQuotes?.find((quote) => quote.id === selectedQuote.value)
    );
  };

  const hasApprovedQuote = priceQuotes?.some(
    (quote) => quote.attributes.status === PriceQuoteStatus.approved
  );

  const { showSnackbar } = useSnackbar();

  const {
    getFieldProps,
    handleSubmit,
    setFieldValue,
    errors,
    setErrors,
    setSubmitting,
    isSubmitting,
    values,
  } = useFormik({
    initialValues: {
      [FORM_KEYS.STATUS]: priceQuote?.attributes.status || '',
      [FORM_KEYS.FEEDBACK_NOTE]: priceQuote?.attributes.feedbackNote || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => handleFeedbackSubmit(values),
    validationSchema: Yup.object().shape({
      [FORM_KEYS.STATUS]: Yup.string().required(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  const { patchProjectWorksheetPriceQuote } = useApi();

  const priceQuoteHeader =
    quoteType.charAt(0).toUpperCase() + quoteType?.slice(1);

  const handleFeedbackSubmit = (values: any) => {
    setSubmitting(true);
    patchProjectWorksheetPriceQuote({
      urlParams: `${projectId}/worksheets/${priceQuote?.relationships.worksheet.data.id}/price_quotes/${priceQuote?.id}/update_status`,
      data: {
        priceQuote: values,
      },
      handleSuccess: () => {
        setProject((prev: any) => ({
          ...prev,
          priceQuotes: prev.priceQuotes?.map(
            (quote: IApiData<PriceQuoteAttributes>) =>
              quote.id === priceQuote?.id
                ? {
                    ...quote,
                    attributes: {
                      ...quote.attributes,
                      status: values.status,
                      feedbackNote: values.feedbackNote,
                    },
                  }
                : quote
          ),
          parsedIncluded: {
            ...prev.parsedIncluded,
            tasks: prev.parsedIncluded.tasks.map(
              (task: IApiData<TaskAttributes>) =>
                task.attributes.mondayColumnId ===
                (quoteType === PriceQuoteType.preliminary
                  ? PRELIM_QUOTE_APPROVED
                  : FINAL_QUOTE_APPROVED)
                  ? {
                      ...task,
                      attributes: {
                        ...task.attributes,
                        status: TaskStatus.done,
                      },
                    }
                  : task
            ),
          },
        }));
        showSnackbar('Feedback submitted successfully', 'success');
        handleClose();
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  const hasBeenReviewed =
    priceQuote?.attributes.status === PriceQuoteStatus.approved ||
    priceQuote?.attributes.status === PriceQuoteStatus.rejected;

  const formula: Maybe<IApiData<
    FormulaAttributes
  >> = (project as any).formulas.find(
    (formula: IApiData<FormulaAttributes>) =>
      formula.id === priceQuote?.relationships.formula.data.id
  );

  const getIconFromStatus = (status: Maybe<PriceQuoteStatus>) => {
    switch (status) {
      case PriceQuoteStatus.approved:
        return (
          <CheckCircleIcon
            width={16}
            height={16}
            className="mr-2"
            color="#46B958"
          />
        );
      case PriceQuoteStatus.rejected:
        return (
          <LockClosedIcon
            width={16}
            height={16}
            className="mr-2"
            color="#6C7E93"
          />
        );
      default:
        // when one of the quotes is approved all formulas should have a lock icon,
        // except the one that is approved
        return hasApprovedQuote ? (
          <LockClosedIcon
            width={16}
            height={16}
            className="mr-2"
            color="#6C7E93"
          />
        ) : null;
    }
  };

  return (
    <div
      className={`overscroll-none overflow-auto flex flex-col fixed z-[1300] w-full h-full top-0 left-0 bg-white pt-15`}
    >
      {/* Header section */}
      <div>
        <XMarkIcon
          className="h-6 w-6 cursor-pointer absolute top-6 right-6"
          onClick={handleClose}
        />
        <div className="max-w-[1110px] mx-12 mb-14">
          <div className="flex flex-row items-center">
            <div className="mr-6">
              <PmLogo />
            </div>
            <div>
              <Typography variant="h2" font="inter" color="primary">
                {priceQuoteHeader} Price Quote
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mx-40 mb-14">
        {/* Alert banners */}
        {hasBeenReviewed && (
          <Alert
            content={
              priceQuote.attributes.status === PriceQuoteStatus.approved
                ? `You approved this quote on ${new Date(
                    priceQuote.attributes.updatedAt
                  ).toLocaleDateString('en-US')}.`
                : `Prime Matter Labs is currently reviewing the feedback you submitted for this quote on ${new Date(
                    priceQuote.attributes.updatedAt
                  ).toLocaleDateString('en-US')}.`
            }
            severity={
              priceQuote.attributes.status === PriceQuoteStatus.approved
                ? 'success'
                : 'warning'
            }
            isBanner
          />
        )}
        {errors.status && (
          <Alert
            content="Please select whether or not you approve this quote before pressing submit."
            severity="error"
            onClose={() => setErrors({})}
          />
        )}

        {/* Intro section */}
        <div className="flex flex-col py-3 mb-3">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-y-3">
              <Typography variant="h4" font="inter">
                {priceQuote?.attributes.personalNote}
              </Typography>
            </div>
            <div className="flex flex-col gap-y-3">
              <div>
                <Typography
                  variant="h6"
                  font="inter"
                  color="secondary"
                  weight="semibold"
                >
                  Quote Version Number
                </Typography>
              </div>
              <div className="flex flex-col">
                <Select
                  leadingIcon={getIconFromStatus(
                    priceQuote?.attributes.status as any
                  )}
                  options={
                    priceQuotes?.map((quote) => ({
                      leadingIcon: getIconFromStatus(
                        quote.attributes.status as any
                      ),
                      label: quote.attributes.quoteVersionNumber,
                      value: quote.id,
                    })) || []
                  }
                  value={priceQuote?.id}
                  onChange={handleSelectPriceQuote}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded border-grey-90 mb-3">
          <div className="bg-grey-20 px-12 py-4 rounded-tl rounded-tr">
            <Typography variant="h4" color="onDark" font="mono">
              QUOTE
            </Typography>
          </div>

          <div className="flex flex-col gap-y-12 px-12 pt-12">
            {/* Basic info section */}
            <div className="flex flex-row justify-between">
              {/* Left side */}
              <div className="flex flex-col gap-y-6">
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      font="inter"
                      color="secondary"
                      weight="semibold"
                    >
                      Customer Name{' '}
                    </Typography>
                  </div>
                  <div className="py-2">
                    <Typography variant="h4" font="inter" color="primary">
                      {companyName}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      font="inter"
                      color="secondary"
                      weight="semibold"
                    >
                      Quote Date
                    </Typography>
                  </div>
                  <div className="py-2">
                    <Typography variant="h4" font="inter" color="primary">
                      {priceQuote?.attributes.quoteDate
                        ? new Date(
                            priceQuote.attributes.quoteDate!
                          ).toLocaleDateString('en-US')
                        : ''}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      font="inter"
                      color="secondary"
                      weight="semibold"
                    >
                      Valid for
                    </Typography>
                  </div>
                  <div className="py-2">
                    <Typography variant="h4" font="inter" color="primary">
                      {priceQuote?.attributes.validFor} days
                    </Typography>
                  </div>
                </div>
              </div>
              {/* Right side */}
              <div className="flex flex-col gap-y-6 mr-40">
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      font="inter"
                      color="secondary"
                      weight="semibold"
                    >
                      Payment Terms
                    </Typography>
                  </div>
                  <div className="py-2">
                    <Typography variant="h4" font="inter" color="primary">
                      {priceQuote?.attributes.paymentTerms}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      font="inter"
                      color="secondary"
                      weight="semibold"
                    >
                      Incoterms
                    </Typography>
                  </div>
                  <div className="py-2">
                    <Typography variant="h4" font="inter" color="primary">
                      {priceQuote?.attributes.incoterms}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      font="inter"
                      color="secondary"
                      weight="semibold"
                    >
                      Delivery Terms
                    </Typography>
                  </div>
                  <div className="py-2">
                    <Typography variant="h4" font="inter" color="primary">
                      {priceQuote?.attributes.deliveryTerms}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            {/* Price section */}
            <div className="flex flex-col gap-y-6">
              <Typography variant="h3" font="inter" weight="semibold">
                Price
              </Typography>
              {/* Formula section */}
              <div className="flex flex-row gap-x-12">
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      font="inter"
                      color="secondary"
                      weight="semibold"
                    >
                      Formula Number
                    </Typography>
                  </div>
                  <div className="py-2">
                    <Typography variant="h4" font="inter" color="primary">
                      {formula?.attributes.externalId}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      font="inter"
                      color="secondary"
                      weight="semibold"
                    >
                      Description
                    </Typography>
                  </div>
                  <div className="py-2">
                    <Typography variant="h4" font="inter" color="primary">
                      {priceQuote?.attributes.formulaDescription}
                    </Typography>
                  </div>
                </div>
              </div>

              {/* Size table */}
              <div className="border rounded border-grey-90">
                {/* Size header */}
                <div className="grid grid-cols-4 rounded">
                  <div className="col-span-1 bg-grey-20 flex items-center pl-6 border border-t-0 border-b-0 border-l-0 border-r-grey-90 rounded-tl">
                    <Typography
                      variant="h4"
                      font="inter"
                      weight="semibold"
                      color="onDark"
                    >
                      Size
                    </Typography>
                  </div>
                  <div className="col-span-1 bg-grey-20 px-16 py-3 border border-t-0 border-b-0 border-l-0 border-r-grey-90 flex flex-col items-center">
                    <Typography
                      variant="h4"
                      font="inter"
                      weight="semibold"
                      color="onDark"
                      additionalStyles="whitespace-nowrap"
                    >
                      {priceQuote?.attributes.moqOne} units (MOQ)
                    </Typography>
                  </div>
                  <div className="col-span-1 bg-grey-20 px-16 py-3 border border-t-0 border-b-0 border-l-0 border-r-grey-90 flex flex-col items-center">
                    <Typography
                      variant="h4"
                      font="inter"
                      weight="semibold"
                      color="onDark"
                      additionalStyles="whitespace-nowrap"
                    >
                      {priceQuote?.attributes.moqTwo} units
                    </Typography>
                  </div>
                  <div className="col-span-1 bg-grey-20 px-16 py-3 flex flex-col items-center rounded-tr">
                    <Typography
                      variant="h4"
                      font="inter"
                      weight="semibold"
                      color="onDark"
                      additionalStyles="whitespace-nowrap"
                    >
                      {priceQuote?.attributes.moqThree} units
                    </Typography>
                  </div>
                </div>

                {/* Size one row */}
                <div className="grid grid-cols-4">
                  <div className="col-span-1 flex flex-col p-6 border border-t-0 border-l-0 border-r-grey-90 border-b-grey-90">
                    <div className="flex flex-col gap-y-2">
                      <Typography variant="h4" font="inter" weight="semibold">
                        {priceQuote?.attributes.sizeOneQuantity}{' '}
                        {priceQuote?.attributes.sizeOneUnit}
                      </Typography>
                      <Typography variant="h4" font="inter" color="secondary">
                        {priceQuote?.attributes.sizeOneDescription}
                      </Typography>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-row justify-between items-center py-9 px-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
                    <div className="flex">
                      <Typography
                        variant="h4"
                        font="inter"
                        weight="normal"
                        additionalStyles="mr-1"
                      >
                        $
                      </Typography>
                      <Typography variant="h4" font="inter" weight="semibold">
                        {priceQuote?.attributes.sizeOneMoqOneTotalCostPerUnit}
                      </Typography>
                    </div>
                    <Typography variant="h4" font="inter" color="secondary">
                      per unit
                    </Typography>
                  </div>
                  <div className="col-span-1 flex flex-row justify-between items-center py-9 px-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
                    <div className="flex">
                      <Typography
                        variant="h4"
                        font="inter"
                        weight="normal"
                        additionalStyles="mr-1"
                      >
                        $
                      </Typography>
                      <Typography variant="h4" font="inter" weight="semibold">
                        {priceQuote?.attributes.sizeOneMoqTwoTotalCostPerUnit}
                      </Typography>
                    </div>
                    <Typography variant="h4" font="inter" color="secondary">
                      per unit
                    </Typography>
                  </div>
                  <div className="col-span-1 flex flex-row justify-between items-center py-9 px-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
                    <div className="flex">
                      <Typography
                        variant="h4"
                        font="inter"
                        weight="normal"
                        additionalStyles="mr-1"
                      >
                        $
                      </Typography>
                      <Typography variant="h4" font="inter" weight="semibold">
                        {priceQuote?.attributes.sizeOneMoqThreeTotalCostPerUnit}
                      </Typography>
                    </div>
                    <Typography variant="h4" font="inter" color="secondary">
                      per unit
                    </Typography>
                  </div>
                </div>

                {/* Size two row */}
                {Boolean(priceQuote?.attributes.sizeTwoQuantity) ? (
                  <div className="grid grid-cols-4">
                    <div className="col-span-1 flex flex-col p-6 border border-t-0 border-l-0 border-r-grey-90 border-b-grey-90">
                      <div className="flex flex-col gap-y-2">
                        <Typography variant="h4" font="inter" weight="semibold">
                          {priceQuote?.attributes.sizeTwoQuantity}{' '}
                          {priceQuote?.attributes.sizeTwoUnit}
                        </Typography>
                        <Typography variant="h4" font="inter" color="secondary">
                          {priceQuote?.attributes.sizeTwoDescription}
                        </Typography>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-row justify-between items-center py-9 px-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
                      <div className="flex">
                        <Typography
                          variant="h4"
                          font="inter"
                          weight="normal"
                          additionalStyles="mr-1"
                        >
                          $
                        </Typography>
                        <Typography variant="h4" font="inter" weight="semibold">
                          {priceQuote?.attributes.sizeTwoMoqOneTotalCostPerUnit}
                        </Typography>
                      </div>
                      <Typography variant="h4" font="inter" color="secondary">
                        per unit
                      </Typography>
                    </div>
                    <div className="col-span-1 flex flex-row justify-between items-center py-9 px-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
                      <div className="flex">
                        <Typography
                          variant="h4"
                          font="inter"
                          weight="normal"
                          additionalStyles="mr-1"
                        >
                          $
                        </Typography>
                        <Typography variant="h4" font="inter" weight="semibold">
                          {priceQuote?.attributes.sizeTwoMoqTwoTotalCostPerUnit}
                        </Typography>
                      </div>
                      <Typography variant="h4" font="inter" color="secondary">
                        per unit
                      </Typography>
                    </div>
                    <div className="col-span-1 flex flex-row justify-between items-center py-9 px-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
                      <div className="flex">
                        <Typography
                          variant="h4"
                          font="inter"
                          weight="normal"
                          additionalStyles="mr-1"
                        >
                          $
                        </Typography>
                        <Typography variant="h4" font="inter" weight="semibold">
                          {
                            priceQuote?.attributes
                              .sizeTwoMoqThreeTotalCostPerUnit
                          }
                        </Typography>
                      </div>
                      <Typography variant="h4" font="inter" color="secondary">
                        per unit
                      </Typography>
                    </div>
                  </div>
                ) : null}

                {/* Size three row */}
                {Boolean(priceQuote?.attributes.sizeThreeQuantity) ? (
                  <div className="grid grid-cols-4">
                    <div className="col-span-1 flex flex-col p-6 border border-t-0 border-l-0 border-r-grey-90 border-b-grey-90">
                      <div className="flex flex-col gap-y-2">
                        <Typography variant="h4" font="inter" weight="semibold">
                          {priceQuote?.attributes.sizeThreeQuantity}{' '}
                          {priceQuote?.attributes.sizeThreeUnit}
                        </Typography>
                        <Typography variant="h4" font="inter" color="secondary">
                          {priceQuote?.attributes.sizeThreeDescription}
                        </Typography>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-row justify-between items-center py-9 px-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
                      <div className="flex">
                        <Typography
                          variant="h4"
                          font="inter"
                          weight="normal"
                          additionalStyles="mr-1"
                        >
                          $
                        </Typography>
                        <Typography variant="h4" font="inter" weight="semibold">
                          {
                            priceQuote?.attributes
                              .sizeThreeMoqOneTotalCostPerUnit
                          }
                        </Typography>
                      </div>
                      <Typography variant="h4" font="inter" color="secondary">
                        per unit
                      </Typography>
                    </div>
                    <div className="col-span-1 flex flex-row justify-between items-center py-9 px-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
                      <div className="flex">
                        <Typography
                          variant="h4"
                          font="inter"
                          weight="normal"
                          additionalStyles="mr-1"
                        >
                          $
                        </Typography>
                        <Typography variant="h4" font="inter" weight="semibold">
                          {
                            priceQuote?.attributes
                              .sizeThreeMoqTwoTotalCostPerUnit
                          }
                        </Typography>
                      </div>
                      <Typography variant="h4" font="inter" color="secondary">
                        per unit
                      </Typography>
                    </div>
                    <div className="col-span-1 flex flex-row justify-between items-center py-9 px-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
                      <div className="flex">
                        <Typography
                          variant="h4"
                          font="inter"
                          weight="normal"
                          additionalStyles="mr-1"
                        >
                          $
                        </Typography>
                        <Typography variant="h4" font="inter" weight="semibold">
                          {
                            priceQuote?.attributes
                              .sizeThreeMoqThreeTotalCostPerUnit
                          }
                        </Typography>
                      </div>
                      <Typography variant="h4" font="inter" color="secondary">
                        per unit
                      </Typography>
                    </div>
                  </div>
                ) : null}
              </div>
              {/* Pricing notes */}
              <Typography variant="h5" color="secondary" font="inter">
                {priceQuote?.attributes.pricingNotes}
              </Typography>
            </div>

            <Divider />

            {/* Material responsibilities section */}
            <div className="flex flex-col gap-y-6">
              <Typography variant="h3" font="inter" weight="semibold">
                Material Responsibilities
              </Typography>
            </div>

            {/* MR table */}
            <div className="border rounded border-grey-90">
              <div className="grid grid-cols-3 rounded">
                <div className="col-span-1 bg-grey-20 p-6 rounded-tl border border-t-0 border-b-0 border-l-0 border-r-grey-90" />

                <div className="col-span-1 bg-grey-20 flex justify-center items-center border border-t-0 border-b-0 border-l-0 border-r-grey-90">
                  <Typography
                    variant="p"
                    font="inter"
                    weight="medium"
                    color="onDark"
                  >
                    Customer
                  </Typography>
                </div>

                <div className="col-span-1 bg-grey-20 flex justify-center items-center rounded-tr border border-t-0 border-b-0 border-l-0 border-r-grey-90">
                  <Typography
                    variant="p"
                    font="inter"
                    weight="medium"
                    color="onDark"
                    additionalStyles="whitespace-nowrap"
                  >
                    Prime Matter Labs
                  </Typography>
                </div>
              </div>

              <div className="grid grid-cols-3">
                <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  <Typography font="inter" variant="p" color="primary">
                    Raw Materials
                  </Typography>
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.rawMaterialResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.customer && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.rawMaterialResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-3">
                <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  <Typography font="inter" variant="p" color="primary">
                    Containers
                  </Typography>
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.containersResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.customer && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.containersResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-3">
                <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  <Typography font="inter" variant="p" color="primary">
                    Decoration
                  </Typography>
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.decorationResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.customer && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.decorationResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-3">
                <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  <Typography font="inter" variant="p" color="primary">
                    Closure
                  </Typography>
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.closureResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.customer && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.closureResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-3">
                <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  <Typography font="inter" variant="p" color="primary">
                    Master
                  </Typography>
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.masterResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.customer && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
                <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
                  {priceQuote?.attributes.masterResponsibility ===
                    MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs && (
                    <CheckIcon className="h-6 w-6 text-royal-50" />
                  )}
                </div>
              </div>
            </div>

            {/* MR notes */}
            <div className="mb-12">
              <Typography variant="h5" font="inter" color="secondary">
                {priceQuote?.attributes.materialsNote}
              </Typography>
            </div>
          </div>
        </div>
        {/* Approval form */}
        {
          <div className="p-6 bg-yellow-90 mb-24">
            {/* Prelim form */}
            {quoteType === Constants.PRICE_QUOTE_TYPES.preliminary && (
              <div className="flex flex-col gap-y-3">
                <RadioButton
                  primaryLabel="YES, I approve this preliminary price quote."
                  {...getFieldProps(FORM_KEYS.STATUS)}
                  value={PriceQuoteStatus.approved}
                  checked={values.status === PriceQuoteStatus.approved}
                  disabled={hasBeenReviewed}
                />
                <RadioButton
                  primaryLabel="NO, I do not approve this preliminary price quote."
                  {...getFieldProps(FORM_KEYS.STATUS)}
                  value={PriceQuoteStatus.rejected}
                  checked={values.status === PriceQuoteStatus.rejected}
                  disabled={hasBeenReviewed}
                />
                <TextArea
                  placeholder="Please describe why you do not approve this price quote. We will use your notes to present a new quote."
                  {...getFieldProps(FORM_KEYS.FEEDBACK_NOTE)}
                  value={values.feedbackNote}
                  isDisabled={hasBeenReviewed}
                />
              </div>
            )}
            {/* Final form */}
            {quoteType === Constants.PRICE_QUOTE_TYPES.final && (
              <Checkbox
                primaryLabel="I approve the pricing outlined in the above quote."
                onChange={(e: any) =>
                  setFieldValue(
                    FORM_KEYS.STATUS,
                    e.target.checked ? PriceQuoteStatus.approved : ''
                  )
                }
              />
            )}
          </div>
        }
      </div>

      <BottomBar
        buttons={[
          <Button
            size="large"
            text={hasBeenReviewed ? 'Close' : 'Cancel'}
            type="secondary"
            action={handleClose}
            disabled={isSubmitting}
          />,
          hasBeenReviewed ? undefined : (
            <Button
              size="large"
              text="Submit"
              type="primary"
              action={handleSubmit}
              disabled={isSubmitting}
            />
          ),
        ]}
        additionalStyles="left-0"
      />
    </div>
  );
};
