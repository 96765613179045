// Libraries
import React from 'react';
// Components
import { RAndDDocuments } from '../form-modules';

export const Testing: React.FC = () => (
  <>
    <RAndDDocuments />
  </>
);

export default Testing;
