// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChevronDownIcon,
  PlusCircleIcon,
  PlusIcon,
} from '@heroicons/react/16/solid';
// Components
import { Button, Page, Spinner } from 'design-system';
import { CosmoprofShoppingCart } from './cosmoprof-shopping-cart.component';
// Utils
import { USDollar } from 'features/utils';
import { MobileContext, RtlShoppingCartContext } from 'context';
import { useAlert } from 'context/alert-context/alert-context';
import { useApi } from 'api';
import { useIsTouchDevice } from 'hooks';
// Assets
import { RtlFormulaCatalogueTopNav } from '../rtl-formula-catalogue-top-nav';
// Constants
import { RTL_CATALOGUE_IMAGES, RLT_CATALOGUE_STATUSES } from '../constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

const formulaTableHeaders = [
  'MOQ',
  'Format',
  'Component',
  'Sample Size',
  'CPU (per oz)',
];

export const CosmoprofRtlFormulaCatalogue = () => {
  const [rtlCatalogueEntries, setRtlCatalogueEntries] = useState([]);
  const [searchQuery] = useState<string>('');
  const [viewMode] = useState<'grid' | 'list'>('grid');

  const navigate = useNavigate();
  const { isMobile } = useContext(MobileContext);
  const isTouchDevice = useIsTouchDevice();
  const { addItem } = useContext(RtlShoppingCartContext);
  const { showAlert, hideAlert } = useAlert();
  const { searchRtlCatalogueEntries } = useApi();

  useEffect(() => {
    searchRtlCatalogueEntries({
      query: encodeURIComponent(searchQuery),
      urlParams: `&page=1&perPage=15`,
      handleSuccess: (data) => {
        setRtlCatalogueEntries(data.results);
      },
    });
  }, [searchQuery, searchRtlCatalogueEntries]);

  // Function that handles the rendering of the catalogue entry image in the grid view
  const renderGridViewCatalogueEntryImage = (entry: any) => {
    if (!entry.image_id) {
      return (
        <div className="flex items-center justify-center h-[191px] w-full sm:w-[233px] bg-grey-70 rounded">
          <span>No Image Selected</span>
        </div>
      );
    }
    return (
      <div className="flex items-center justify-center relative cursor-pointer">
        {entry.status !== 'draft' && !isTouchDevice && (
          <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100">
            <Button
              leadingIcon={<PlusCircleIcon className="w-6 h-6" />}
              onClick={(e: Event) => {
                e.stopPropagation();

                showAlert({
                  content: <span>Item added to cart</span>,
                  severity: 'success',
                });

                const cartItem = {
                  id: entry.uuid,
                  formulaUuid: entry.formula.uuid,
                  imageId: entry.image_id,
                  quantity: 1,
                  price: entry.price,
                  displayName: entry.display_name,
                  structure: entry.product.structure,
                  primaryContainer: entry.product.primaryComponent,
                };
                addItem(cartItem);

                setTimeout(() => hideAlert(), 3000);
              }}
              text="Add to Cart"
              variant="primary"
            />
          </div>
        )}
        <img
          src={
            RTL_CATALOGUE_IMAGES.find((image) => image.id === entry.image_id)
              ?.src || 'http://www.google.com'
          }
          alt="entry"
          className="h-[191px] w-full object-cover sm:w-[233px] rounded"
        />
      </div>
    );
  };

  // Function that handles the rendering of the catalogue entry image in the list view
  const renderListViewCatalogueEntryImage = (entry: any) => {
    if (!entry.image_id) {
      return (
        <div className="flex items-center justify-center h-6 w-6 bg-grey-70 rounded" />
      );
    }
    return (
      <img
        src={
          RTL_CATALOGUE_IMAGES.find((image) => image.id === entry.image_id)
            ?.src || 'http://www.google.com'
        }
        alt="entry"
        className="h-6 w-6 object-cover sm:w-[191px] rounded"
      />
    );
  };

  // Function that handles the rendering of table headers in the list view
  const renderTableHeaders = () => {
    return formulaTableHeaders.map((headerName) => (
      <div className="flex items-center gap-1 cursor-pointer">
        <span>{headerName}</span>
        <ChevronDownIcon className="h-4 w-4 text-royal-50" />
      </div>
    ));
  };

  // Function that handles the rendering of catalogue entry table rows in the list view
  const renderCatalogueEntryRows = () => {
    if (rtlCatalogueEntries.length === 0) return;

    return rtlCatalogueEntries.map((entry: any, index: number) => {
      return isMobile ? (
        <div
          key={`${entry._source.uuid}-${index}`}
          className="flex flex-col py-3 gap-6"
        >
          <div className="flex items-center gap-4">
            {renderListViewCatalogueEntryImage(entry._source)}
            <span className="text-black font-semibold w-full whitespace-nowrap overflow-hidden text-ellipsis">
              {entry._source.display_name}
            </span>
          </div>
          <div className="flex flex-col gap-2 text-grey-50">
            <span>
              {new Intl.NumberFormat('en-US').format(entry?._source.moq)}
            </span>
            <span>{entry?._source.format}</span>
            <span>{entry?._source.component}</span>
            <span>{entry?._source.sampleSize}</span>
            <span className="text-black">
              {USDollar.format(entry?._source.price || 0)}
            </span>
          </div>
          <Button
            text="Add to Cart"
            type="primary"
            size="small"
            leadingIcon={<PlusIcon className="h-4 w-4" />}
          />
        </div>
      ) : (
        <div
          onClick={() =>
            navigate(
              ROUTES.RTL_FORMULA_CATALOGUE_ENTRY.route.replace(
                UUID_SHOW_ROUTE_STRING,
                entry._source.uuid
              )
            )
          }
          className="cursor-pointer grid items-center grid-cols-rtlFormulaCatalogueListViewTableHeader py-3 gap-x-[56px] border-b border-grey-90 text-grey-50"
        >
          {renderListViewCatalogueEntryImage(entry._source)}
          <span className="-ml-10 text-black font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
            {entry?._source.display_name || 'TBD'}
          </span>
          <span>
            {new Intl.NumberFormat('en-US').format(entry?._source.moq)}
          </span>
          <span>{entry?._source.format}</span>
          <span>{entry?._source.component}</span>
          <span>{entry?._source.sampleSize}</span>
          <span>{USDollar.format(entry?._source.price || 0)}</span>
          <div>
            <Button
              text="Add to Cart"
              type="primary"
              size="small"
              leadingIcon={<PlusIcon className="h-4 w-4" />}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <Page>
      <RtlFormulaCatalogueTopNav />
      <CosmoprofShoppingCart />
      <div className="max-w-[1440px] sm:col-span-12 col-span-2 mx-auto text-grey-20">
        <div className="flex flex-col sm:flex-row sm:justify-between pt-52">
          <div className="sm:w-1/2 w-full flex flex-col">
            <span className="font-agipo text-[48px] font-bold">
              RTL Formula Library
            </span>
            <span className="font-inter text-grey-50">
              This is PML’s library of off-the-shelf formulas. These formulas
              have a faster production time and lower costs to our clients
              because they’ve been pre-tested. Clients can make minor
              adjustments to fragrance and ingredients. Browse formulas, then
              add them to your cart to send samples to clients.
            </span>
          </div>
        </div>
        {viewMode === 'grid' ? (
          <div className="mt-16 flex justify-center sm:justify-start col-span-2 sm:col-span-12 flex-wrap gap-x-6 gap-y-9 mb-6">
            {rtlCatalogueEntries?.length ? (
              rtlCatalogueEntries.map((entry: any, index) => {
                if (entry._source.status === RLT_CATALOGUE_STATUSES.DRAFT)
                  return null;
                return (
                  <div
                    onClick={() =>
                      navigate(
                        ROUTES.RTL_FORMULA_CATALOGUE_ENTRY.route.replace(
                          UUID_SHOW_ROUTE_STRING,
                          entry._source.uuid
                        )
                      )
                    }
                    key={index}
                    className="flex w-full sm:w-auto flex-col font-inter text-sm cursor-pointer rounded hover:bg-blue-95 p-[6px]"
                  >
                    {renderGridViewCatalogueEntryImage(entry._source)}
                    <div className="flex justify-between items-center my-[6px] sm:max-w-[233px]">
                      <div className="flex w-2/3">
                        <span className="font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis">
                          {entry._source.display_name || 'TBD'}
                        </span>
                      </div>
                    </div>
                    <span className="text-grey-50">
                      {entry._source.formula.external_id}
                    </span>
                  </div>
                );
              })
            ) : (
              <div className="mx-auto">
                <Spinner />
              </div>
            )}
          </div>
        ) : (
          <div className="max-w-[1440px] col-span-12 font-inter text-sm">
            <div className="hidden sm:grid grid-cols-rtlFormulaCatalogueListViewTableHeader gap-x-[56px] py-3 border-b border-grey-90">
              <div id="imageColumnPlaceholder" className="py-3"></div>
              <div className="flex items-center gap-1 cursor-pointer -ml-10">
                <span>Formula Name</span>
                <ChevronDownIcon className="h-4 w-4 text-royal-50" />
              </div>
              {renderTableHeaders()}
            </div>
            <div id="addToCartButtonPlaceholder"></div>
            {renderCatalogueEntryRows()}
          </div>
        )}
      </div>
    </Page>
  );
};
