import { createContext, useContext } from 'react';
import { AlertProps } from 'design-system/alert.component';

interface AlertContextProps {
  alert: AlertProps | null;
  showAlert: (alert: AlertProps) => void;
  hideAlert: () => void;
}

export const AlertContext = createContext<AlertContextProps | undefined>(
  undefined
);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};
