// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// Components
import {
  Button,
  Chip,
  CollapsibleSection,
  IncrementerDecrementer,
  Page,
  Spinner,
} from 'design-system';
import { CosmoprofShoppingCart } from './cosmoprof-shopping-cart.component';
import { RtlFormulaCatalogueTopNav } from '../rtl-formula-catalogue-top-nav';
// Utils
import {
  FormulaAttributes,
  IApiData,
  ProductAttributes,
  RtlCatalogueEntryAttributes,
  useApi,
} from 'api';
import { RtlShoppingCartContext } from 'context';
import { useAlert } from 'context/alert-context/alert-context';
// Constants
import { RTL_CATALOGUE_IMAGES } from '../constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { PRODUCT_CATEGORIES } from 'features/brief/brief.constants';

export const CosmoprofRtlFormulaCatalogueEntry = () => {
  const { getRtlCatalogueEntry } = useApi();
  const { addItem } = useContext(RtlShoppingCartContext);
  const { showAlert, hideAlert } = useAlert();

  const { uuid } = useParams<{
    uuid: string;
  }>();

  type RtlCatalogueEntryWithProductAndFormula = IApiData<
    RtlCatalogueEntryAttributes
  > & {
    formula: IApiData<FormulaAttributes>;
    product: IApiData<ProductAttributes>;
  };

  const [numOfSamples, setNumOfSamples] = useState(1);
  const [rtlCatalogueEntry, setRtlCatalogueEntry] = useState<
    RtlCatalogueEntryWithProductAndFormula | undefined
  >(undefined);

  const formula = rtlCatalogueEntry?.formula;
  const product = rtlCatalogueEntry?.product;
  const isDraft = rtlCatalogueEntry?.attributes.status === 'draft';

  useEffect(() => {
    if (uuid) {
      getRtlCatalogueEntry({
        urlParams: uuid,
        handleSuccess: (data) => {
          setRtlCatalogueEntry(data);
        },
      });
    }
  }, [getRtlCatalogueEntry, uuid]);

  const handleAddToCart = () => {
    showAlert({
      content: <span>Item added to cart</span>,
      severity: 'success',
    });
    const cartItem = {
      id: uuid!,
      imageId: rtlCatalogueEntry!.attributes.imageId,
      quantity: numOfSamples,
      formulaUuid: formula!.id,
      price: rtlCatalogueEntry!.attributes.price,
      displayName: rtlCatalogueEntry!.attributes.displayName,
      structure: product?.attributes.structure,
      primaryContainer: product?.attributes.primaryComponent,
    };
    addItem(cartItem);
    setNumOfSamples(1);
    setTimeout(() => hideAlert(), 3000);
  };

  const renderFormSection = (section: any) => {
    return (
      <CollapsibleSection key={section.title} title={section.title}>
        <div className="flex flex-col px-4 border border-grey-90 rounded">
          {section.items.map((item: any, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </CollapsibleSection>
    );
  };

  const renderInfoRow = (label: string, info: any, isLast?: boolean) => {
    return (
      <div
        className={`flex justify-between py-4 ${
          isLast ? '' : 'border-b border-grey-90'
        }`}
      >
        <span className="text-grey-50">{label}</span>
        {info || 'N/A'}
      </div>
    );
  };

  const renderYesNoChip = (value: boolean) => {
    return (
      <Chip
        bgColor={value ? 'bg-green-95' : 'bg-red-95'}
        borderColor={value ? 'border-green-80' : 'border-red-80'}
        label={value ? 'Yes' : 'No'}
        textColor={value ? 'text-green-40' : 'text-red-40'}
        size="small"
        value={value ? 'yes' : 'no'}
      />
    );
  };

  if (!rtlCatalogueEntry || !product) {
    return <Spinner />;
  }

  const formItems = {
    formulaDetails: {
      title: 'Formula Details',
      items: [
        renderInfoRow(
          'Formula Number',
          formula ? (
            <Link
              to={ROUTES.SHOW_FORMULA.route.replace(
                UUID_SHOW_ROUTE_STRING,
                formula!.id
              )}
              target="_blank"
              rel="noopener noreferrer"
              className="text-royal-50 cursor-pointer"
            >
              {formula?.attributes.externalId}
            </Link>
          ) : (
            'N/A'
          )
        ),
        renderInfoRow(
          'MOQ',
          rtlCatalogueEntry?.attributes?.minimumOrderQuantity?.toLocaleString()
        ),
        renderInfoRow('Product Category', product.attributes.productCategory),
        renderInfoRow('Product Type', product.attributes.productType),
        renderInfoRow('Form/Structure', product.attributes.structure, true),
      ],
    },
    sunCareSpecifics: {
      title: 'Sun Care Specifics',
      items: [
        renderInfoRow('SPF Type', rtlCatalogueEntry.product.attributes.spf),
        renderInfoRow(
          'Tinted',
          renderYesNoChip(rtlCatalogueEntry.product.attributes.tinted),
          true
        ),
      ],
    },
    skinCareSpecifics: {
      title: 'Skin Care Specifics',
      items: [
        renderInfoRow(
          'Rinse Off / Leave On',
          product.attributes.rinseOffLeaveOn
        ),
        renderInfoRow('Type of System', product.attributes.typeOfSystem, true),
      ],
    },
  };

  return (
    <Page>
      <RtlFormulaCatalogueTopNav />
      <CosmoprofShoppingCart />
      <div className="sm:col-span-12 col-span-2 flex flex-col sm:flex-row lg:gap-6 mt-16 mb-[200px] max-w-[1264px] font-inter text-grey-20 mx-auto w-full">
        {rtlCatalogueEntry && (
          <>
            <div className="flex flex-col gap-6 min-w-[360px] lg:min-w-[406px]">
              <img
                src={
                  RTL_CATALOGUE_IMAGES.find(
                    (image) => image.id === rtlCatalogueEntry.attributes.imageId
                  )?.src || RTL_CATALOGUE_IMAGES[0].src
                }
                alt="selected formula"
                className="h-[406px] w-[406px] rounded object-cover lg:object-fill"
              />
              <div className="flex flex-col sm:flex-row gap-6">
                <IncrementerDecrementer
                  value={numOfSamples}
                  setValue={setNumOfSamples}
                />
                <Button
                  disabled={isDraft}
                  onClick={handleAddToCart}
                  size="large"
                  type="primary"
                  text={isDraft ? 'Publish to Add to Cart' : 'Add to Cart'}
                  width="w-full"
                />
              </div>
            </div>
            <div className="flex flex-col w-full gap-9 sm:px-6 mt-12 sm:mt-0">
              <div className="flex flex-col gap-9 sm:gap-0 sm:flex-row justify-between sm:items-center">
                <span className="text-xl text-grey-20 font-semibold">
                  {rtlCatalogueEntry.attributes.displayName}
                </span>
              </div>
              {renderFormSection(formItems.formulaDetails)}
              {product.attributes.productCategory ===
                PRODUCT_CATEGORIES.SUN_CARE &&
                renderFormSection(formItems.sunCareSpecifics)}
              {product.attributes.productCategory ===
                PRODUCT_CATEGORIES.SKIN_CARE &&
                renderFormSection(formItems.skinCareSpecifics)}
            </div>
          </>
        )}
      </div>
    </Page>
  );
};
